import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BasicAuthInterceptor } from './core/guards/basic-auth.interceptor';
import { ErrorInterceptor } from './core/guards/error.interceptor';
import { SharedModule } from './shared/shared.module';
import { WeekDay } from '@angular/common';
import { MaintenanceScreenPage } from './maintenance-screen/maintenance-screen.page';
import { UpgradeScreenPage } from './upgrade-screen/upgrade-screen.page';
import { PlatformService } from './core/services/programmare/platform/platform.service';
import { AccessRestrictionPage } from './access-restriction/access-restriction.page';

export const WEEK_START_DAY = WeekDay.Monday;
export const YEAR_START_DAY = 0;
@NgModule({
  declarations: [AppComponent, MaintenanceScreenPage, UpgradeScreenPage, AccessRestrictionPage],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    PlatformService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
