export const checkIfEmpty = ( e: any ) => {
  switch (e) {
    case '':
    case 0:
    case '0':
    case null:
    case false:
    case typeof e === 'undefined':
    case 'undefined':
    case undefined:
      return true;
    default:
      return false;
  }
};
