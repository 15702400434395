import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/ro';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgCalendarModule } from 'ionic2-calendar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SwiperModule } from 'swiper/angular';
import { CoreModule } from '../core/core.module';
import { AddServiceSelectionComponent } from './components/add-service-selection/add-service-selection.component';
import { CalendarHeaderComponent } from './components/calendar-header/calendar-header.component';
import { CalendarListComponent } from './components/calendar-list/calendar-list.component';
import { CalendarMonthComponent } from './components/calendar-month/calendar-month.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ComparativComponent } from './components/comparativ/comparativ.component';
import { DayViewSchedulerComponent } from './components/comparativ/day-view-scheduler.component';
import { HeaderComponent } from './components/header/header.component';
import { IconsComponent } from './components/icons/icons.component';
import { LoaderComponent } from './components/loader/loader/loader.component';
import { CalModalComponent } from './components/modal/cal-modal/cal-modal.component';
import { ModalModule } from './components/modal/modal.module';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import {
  BizCalendarTooltipDirective,
  BizCalendarTooltipWindowComponent,
} from './directives/biz-calendar-tooltip.directive';
import { BizInputsModule } from './modules/biz-inputs/biz-inputs.module';
import { PopoverComponent } from './modules/popover/popover.component';
import { ComparativeBgPipe } from './pipes/comparative-bg.pipe';
import { CautaModalComponent } from './components/cauta-modal/cauta-modal.component';
import { GroupService } from './services/group.service';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    CalendarComponent,
    SideMenuComponent,
    CalendarListComponent,
    CalendarHeaderComponent,
    IconsComponent,
    CalendarMonthComponent,
    ComparativComponent,
    DayViewSchedulerComponent,
    AddServiceSelectionComponent,
    CalModalComponent,
    HeaderComponent,
    PopoverComponent,
    ComparativeBgPipe,
    BizCalendarTooltipDirective,
    BizCalendarTooltipWindowComponent,
    LoaderComponent,
    CautaModalComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    InfiniteScrollModule,
    RouterModule,
    ReactiveFormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgCalendarModule,
    BizInputsModule,
    ModalModule,
    SwiperModule,
    CoreModule,
  ],
  exports: [
    CalendarComponent,
    SideMenuComponent,
    CalendarListComponent,
    CalendarHeaderComponent,
    IconsComponent,
    ReactiveFormsModule,
    BizInputsModule,
    CalendarMonthComponent,
    ComparativComponent,
    DayViewSchedulerComponent,
    AddServiceSelectionComponent,
    CalModalComponent,
    HeaderComponent,
    PopoverComponent,
    BizCalendarTooltipDirective,
    BizCalendarTooltipWindowComponent,
    LoaderComponent,
    CautaModalComponent
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'ro' }, GroupService],
})
export class SharedModule {}
