import { Component, OnInit, Input } from '@angular/core';
import { getAge } from '@app/core/helpers/date.helper';
import { Person } from '@app/core/models/person.model';
import { GroupService } from '@app/shared/services/group.service';
import { ModalController } from '@ionic/angular';
import { PacientViewModalComponent } from '../pacient-view-modal/pacient-view-modal.component';
import { ToastService } from '@app/core/services/toast/toast.service';
import { GrupNouModalComponent } from 'src/app/shared/components/modal/grup-nou-modal/grup-nou-modal.component';

@Component({
  selector: 'app-group-members-modal',
  templateUrl: './group-members-modal.component.html',
  styleUrls: ['./group-members-modal.component.scss']
})
export class GroupMembersModalComponent implements OnInit {
  @Input() members: any[] = [];
  @Input() groupInfo: any = null;
  @Input() menu = false;
  isLoading = false;
  constructor(private modalController: ModalController,
    private groupService: GroupService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  getAge(birthDate: string) {
    return getAge(new Date(birthDate))?.ageYear;
  }

  async viewPacient(item: Person) {
    const modal = await this.modalController.create({
      component: PacientViewModalComponent,
      cssClass: 'biz-modal-class',
      componentProps: {
        data: item,
        menu: this.menu,
      },
    });
    await modal.present();
  }

  deleteGroup() {
    this.groupService.deleteGroup(this.groupInfo.personsGroupUID)
      .subscribe(
        () => {
          this.closeModal();
          this.groupService.reloadPaceient$.next(true);
        },
        error => {
          this.toastService.presentToastWithDurationDismiss('Error al eliminar el grupo', 'error', 3000);
        }
      );
  }

  async editGroup() {
    const modal = await this.modalController.create({
      component: GrupNouModalComponent,
      cssClass: 'biz-modal-class',
      componentProps: {
        editData: { groupInfo: this.groupInfo, members: this.members },
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data?.groupCreated && data?.dismissed) {
      this.groupService.reloadPaceient$.next(true);
      setTimeout(() => {
      this.closeModal();
      }, 500);
    }
  }
}
