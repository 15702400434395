/* eslint-disable no-underscore-dangle */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MenuController } from '@ionic/angular';
import { differenceInHours, format, startOfDay } from 'date-fns';
import { ro } from 'date-fns/locale';
import { CalendarMode, Step } from 'ionic2-calendar/calendar';
import { Subscription } from 'rxjs';
import { WEEK_START_DAY } from './../../../../app.module';
import { CalendarService } from './../../../../core/services/calendar/calendar.service';
import { debounceTime, distinctUntilChanged, first, tap } from 'rxjs/operators';
import { CalendarDataService } from '@app/core/services/calendar/calendar-data.service';
@Component({
  selector: 'app-cal-modal',
  templateUrl: './cal-modal.component.html',
  styleUrls: ['./cal-modal.component.scss'],
})
export class CalModalComponent implements OnInit, OnDestroy {
  @Input() page = 'luna';
  @Input() set isTablet(isT) {
    this.pIsTablet = isT;
    this.updateTabletOptions();
  }
  get isTablet() {
    return this.pIsTablet;
  }
  @Output() closeModal = new EventEmitter();
  eventSource;
  viewTitle;
  isComparativ;
  isToday: boolean;
  currentDate;
  calendar = {
    weekStart: Number(WEEK_START_DAY),
    mode: 'month' as CalendarMode,
    step: 30 as Step,
    currentDate: new Date(),
    dateFormatter: {
      formatMonthViewDay: (date: Date) => date.getDate().toString(),
      formatMonthViewDayHeader: (date: Date) => 'Mon',
      formatMonthViewTitle: (date: Date) => 'testMT',
      formatWeekViewDayHeader: (date: Date) => 'MonWH',
      formatWeekViewTitle: (date: Date) => 'testWT',
      formatWeekViewHourColumn: (date: Date) => 'testWH',
      formatDayViewHourColumn: (date: Date) => 'testDH',
      formatDayViewTitle: (date: Date) => 'testDT',
    },
  };
  event = {
    title: '',
    desc: '',
    startTime: null,
    endTime: '',
    allDay: true,
  };
  sliderOptions = { slidesPerView: '1', spaceBetween: 32, threshold: 50 };
  modalReady = false;
  month = format(new Date(), 'MMMM', { locale: ro });
  showSub$: Subscription;
  private pIsTablet = false;
  constructor(private menu: MenuController, private calS: CalendarService,
    public calDataS: CalendarDataService,
  ) { }

  ngOnInit() {
    const page = this.page;
    if (page === 'aparate' || page === 'cabinet' || page === 'utilizatori') {
      this.isComparativ = true;
    }
    this.calS.selectedDate.pipe(
      distinctUntilChanged((previous, current) => new Date(previous).getTime() === new Date(current).getTime()),
      tap()).subscribe((v) => {
        const val = v ? new Date(v) : new Date();
        const diff = differenceInHours(
          startOfDay(val),
          startOfDay(new Date(this.currentDate))
        );
        if (diff !== 0) {
          this.calendar.currentDate = new Date(v);
          this.calendar = { ...this.calendar };
        }
    });
  }

  updateTabletOptions() {
    // this.sliderOptions.slidesPerView = this.isTablet ? '3' : '1';
    this.sliderOptions = { ...this.sliderOptions };
  }
  onViewTitleChanged(title) {
    this.calS.selectedMonth.next(title);
    this.viewTitle = title;
  }

  onEventSelected(event) {}

  changeMode(mode) {
    this.calendar.mode = mode;
  }

  today() {
    this.calendar.currentDate = new Date();
  }

  onTimeSelected(ev, clicked = false) {

    // const d1 = startOfDay(new Date(ev.selectedTime)).getTime();
    // const d2 = startOfDay(new Date(this.currentDate)).getTime();
    // if (d1 !== d2) {
    //   this.calS.selectedDate.next(ev.selectedTime);
    //   this.closeModal.emit(true);
    //   this.currentDate = ev.selectedTime;
    // }
    // this.calDataS.viewDate$.next(ev.selectedTime);
  }

  expressionClicked(event) {
    this.calDataS.modalClickedDate$.next({ date: new Date(event._currentDate), clearState: 'before' });
    this.calS.showPicker.next(false);
  }

  onCurrentDateChanged(event) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    event.setHours(0, 0, 0, 0);
    this.isToday = today.getTime() === event.getTime();
    const diff = differenceInHours(
      startOfDay(new Date(event)),
      this.currentDate
        ? startOfDay(new Date(this.currentDate))
        : startOfDay(new Date(this.calS.selectedDate.value))
    );
    if (diff !== 0) {
      this.calS.selectedDate.next(event);
    }
    this.currentDate = event;
  }

  onRangeChanged(event) {}

  toggleMenu() {
    this.menu.toggle();
  }
  ngOnDestroy() {}
}
