import { Injectable } from '@angular/core';
import {
  PermissionRoleListAccess,
  PermissionRoles,
} from '@app/core/models/permission.model';
import { URole, UserRightsRespose } from '@app/core/models/userRights.model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  rolesPermission: PermissionRoles[] = [
    {
      id: 0,
      label: 'Default - null',
      permissions: {
        note: {
          view: false,
          add: false,
          modify: false,
          delete: false,
        },
        appointment: {
          view: false,
          add: false,
          modify: false,
          delete: false,
        },
        patient: {
          view: false,
          add: false,
          modify: false,
          delete: false,
        },
      },
    },
    {
      id: 1,
      label: 'Editor programari, note, pacienti noi',
      permissions: {
        note: {
          view: true,
          add: true,
          modify: true,
          delete: true,
        },
        appointment: {
          view: true,
          add: true,
          modify: true,
          delete: true,
        },
        patient: {
          view: true,
          add: true,
          modify: true,
          delete: true,
        },
      },
    },
    {
      id: 2,
      label: 'viewer',
      permissions: {
        note: {
          view: true,
          add: false,
          modify: false,
          delete: false,
        },
        appointment: {
          view: true,
          add: false,
          modify: false,
          delete: false,
        },
        patient: {
          view: false,
          add: false,
          modify: false,
          delete: false,
        },
      },
    },
    {
      id: 3,
      label: 'Editor programari, note',
      permissions: {
        note: {
          view: true,
          add: true,
          modify: true,
          delete: true,
        },
        appointment: {
          view: true,
          add: true,
          modify: true,
          delete: true,
        },
        patient: {
          view: false,
          add: false,
          modify: false,
          delete: false,
        },
      },
    },
    {
      id: 4,
      label: 'Editor programari, pacienti noi',
      permissions: {
        note: {
          view: true,
          add: false,
          modify: false,
          delete: false,
        },
        appointment: {
          view: true,
          add: true,
          modify: true,
          delete: true,
        },
        patient: {
          view: true,
          add: true,
          modify: true,
          delete: true,
        },
      },
    },
    {
      id: 5,
      label: 'Editor programari',
      permissions: {
        note: {
          view: true,
          add: false,
          modify: false,
          delete: false,
        },
        appointment: {
          view: true,
          add: true,
          modify: true,
          delete: true,
        },
        patient: {
          view: false,
          add: false,
          modify: false,
          delete: false,
        },
      },
    },
    {
      id: 6,
      label: 'Editor note',
      permissions: {
        note: {
          view: true,
          add: true,
          modify: true,
          delete: true,
        },
        appointment: {
          view: true,
          add: false,
          modify: false,
          delete: false,
        },
        patient: {
          view: false,
          add: false,
          modify: false,
          delete: false,
        },
      },
    },
  ];

  constructor(private authS: AuthService) {}
  getUserRoleStateData(): Observable<any> {
    return this.authS
      .getUserRightsState()
      .pipe(switchMap((v: UserRightsRespose) => of(v.role)));
  }
  getUserRightsRoleData(): URole {
    return this.authS.getUserRightsBehaviorSubjectValueData()?.role || {
      id: 0,
      roleName: 'Default - null',
      roleDescription: 'n'
    };
  }
  isAdmin(): Observable<boolean> {
    return this.getUserRoleStateData().pipe(
      switchMap((v: URole) => of(v.id === 1))
    );
  }
  rolesHelper(id: any): PermissionRoles {
    return (
      this.rolesPermission.find((v: PermissionRoles) => v.id === id) ||
      this.rolesPermission[1]
    );
  }
  getAppointmentRolesById(id: number): PermissionRoleListAccess {
    return this.rolesHelper(id)?.permissions?.appointment;
  }
  getNoteRolesById(id: number): PermissionRoleListAccess {
    return this.rolesHelper(id)?.permissions?.note;
  }
  getPatientRolesById(id: number): PermissionRoleListAccess {
    return this.rolesHelper(id)?.permissions?.patient;
  }
  userRolesObservable(cb: (id: number) => boolean): Observable<any> {
    return this.getUserRoleStateData().pipe(
      switchMap((v: URole) => of(cb(v.id)))
    );
  }
  getLoggingAppointmentRights$(): Observable<PermissionRoleListAccess> {
    return this.userRolesObservable(this.getAppointmentRolesById.bind(this));
  }
  getLoggingNoteRights$(): Observable<PermissionRoleListAccess> {
    return this.userRolesObservable(this.getNoteRolesById.bind(this));
  }
  getLoggingPatientRights$(): Observable<PermissionRoleListAccess> {
    return this.userRolesObservable(this.getPatientRolesById.bind(this));
  }
  canAddAppointment(id: number): boolean {
    return this.getAppointmentRolesById(id)?.add;
  }
  canModifyAppointment(id: number): boolean {
    return this.getAppointmentRolesById(id)?.modify;
  }
  canDeleteAppointment(id: number): boolean {
    return this.getAppointmentRolesById(id)?.delete;
  }
  get canAddAppointment$(): Observable<boolean> {
    return this.userRolesObservable(this.canAddAppointment.bind(this));
  }
  get canModifyAppointment$(): Observable<boolean> {
    return this.userRolesObservable(this.canModifyAppointment.bind(this));
  }
  get canDeleteAppointment$(): Observable<boolean> {
    return this.userRolesObservable(this.canDeleteAppointment.bind(this));
  }
  canAddNote(id: number): boolean {
    return this.getNoteRolesById(id)?.add;
  }
  canModifyNote(id: number): boolean {
    return this.getNoteRolesById(id)?.modify;
  }
  canDeleteNote(id: number): boolean {
    return this.getNoteRolesById(id)?.delete;
  }
  get canAddNote$(): Observable<boolean> {
    return this.userRolesObservable(this.canAddNote.bind(this));
  }
  get canModifyNote$(): Observable<boolean> {
    return this.userRolesObservable(this.canModifyNote.bind(this));
  }
  get canDeleteNote$(): Observable<boolean> {
    return this.userRolesObservable(this.canDeleteNote.bind(this));
  }
  get canViewPatients(): boolean {
    return this.getPatientRolesById(this.getUserRightsRoleData().id)?.view;
  }
  get canAddPatients(): boolean {
    return this.getPatientRolesById(this.getUserRightsRoleData().id)?.add;
  }
  get canModifyPatients(): boolean {
    return this.getPatientRolesById(this.getUserRightsRoleData().id)?.modify;
  }
  get canDeletePatients(): boolean {
    return this.getPatientRolesById(this.getUserRightsRoleData().id)?.delete;
  }
  get canViewAppointments(): boolean {
    return this.getAppointmentRolesById(this.getUserRightsRoleData().id)?.view;
  }
  get canViewNotes(): boolean {
    return this.getNoteRolesById(this.getUserRightsRoleData().id)?.view;
  }
  get hasMultiplePhysicians(): boolean {
    return this.authS.getUserRightsBehaviorSubjectValueData()
      ?.hasMultiplePhysicians;
  }
}
