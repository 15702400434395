import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProgrammareService } from '@app/core/services/programmare/programmare.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ModalController } from '@ionic/angular';
import { addMinutes, format, formatISO, formatRFC3339 } from 'date-fns';
import { of, Subscription, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {
  dateDifference,
  dayInAWeekWithDate,
  getDateInYearMonthDay,
} from 'src/app/core/helpers/date.helper';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import { PlatformService } from '@app/core/services/programmare/platform/platform.service';
import { ConflictProgramariComponent } from 'src/app/shared/components/modal/conflict-programari/conflict-programari.component';
import { inputConfigHelper } from 'src/app/shared/data/input-config-helper';
import { IonRadioInputOption } from 'src/app/shared/models/components/ion-radio-input-option';
import { IonRadiosConfig } from 'src/app/shared/models/components/ion-radios-config';
import { inputStyleGuideConfigurations } from 'src/app/style-guide/input-config-data/input-config-data';
import { RecurentaDataModel } from './models/recurenta.model';
import { generateUUID } from '@app/core/helpers/uuid.helper';
import { LoadingControllerService } from '@app/core/services/loadingController/loading-controller.service';
import {
  CheckRecurrenceAvailabilityPayload,
  CheckRecurrenceAvailabilityResponse,
  MappedAlternativeTime,
  RecurrenceConflictInterface,
  RequestedDatesInterface,
} from '@app/core/models/checkRecurrenceAvailability.interface';
import { truncate } from 'lodash';

@Component({
  selector: 'app-recurenta',
  templateUrl: './recurenta.component.html',
  styleUrls: ['./recurenta.component.scss'],
})
export class RecurentaComponent implements OnInit, OnDestroy {
  @Input() startDate: Date;
  @Input() duration: number;
  @Input() setRecurentaData: RecurentaDataModel;
  @Input() specialityCode: string;
  @Input() physicianUID: string;
  @Input() cabinetUID: string;
  @Input() locationUID: string;
  @Input() paymentTypeID: number;
  @Input() medicalEquipmentsUIDs: string[];
  inputStyleGuide = inputStyleGuideConfigurations;
  seRepetaLaFiecareInputConfig = inputConfigHelper({
    label: '',
    type: 'number',
    placeholder: '',
    custom: {
      spinnerConfig: null,
      hidAssistiveText: true,
      removeLIconeftAndRightPadding: false
    },
  });
  seRepetaLaFiecareOption: any = [
    {
      label: 'zile',
      value: 'days',
      id: 1,
    },
    {
      label: 'săptămâni',
      value: 'weekly',
      id: 2,
    },
    {
      label: 'luni',
      value: 'monthly',
      id: 3,
    },
    // {
    //   label: 'ani?',
    //   value: 'yearly',
    //   id: 4,
    // },
  ];
  dataInputConfig = inputConfigHelper({
    label: 'Începe',
    type: 'date',
    placeholder: '08.03.2021',
    custom: {
      mode: 'ios',
      useIcon: {
        name: 'default',
      },
      hidAssistiveText: true,
    },
  });
  timeInputConfig = inputConfigHelper({
    label: '',
    type: 'time',
    placeholder: '09:00',
    custom: {
      mode: 'ios',
    },
  });
  timeBInputConfig = inputConfigHelper({
    label: '',
    type: 'time',
    placeholder: '09:00',
    custom: {
      mode: 'ios',
      useIcon: {
        name: 'clock',
      },
      hidAssistiveText: true,
    },
  });
  dupaInputConfig = inputConfigHelper({
    label: '',
    type: 'number',
    placeholder: '',
    custom: {
      mode: 'ios',
      spinnerConfig: null,
      hidAssistiveText: true,
    },
  });
  paInputConfig = inputConfigHelper({
    label: '',
    type: 'date',
    placeholder: '08.03.2021',
    custom: {
      mode: 'ios',
      useIcon: {
        name: 'default',
      },
      hidAssistiveText: true,
    },
  });
  radioGroupOptions = {
    dupa: 'dupa',
    pa: 'pa',
  };
  isWed = false;
  dropDownStatus = false;
  recurendtaFormGroup: FormGroup = this.fb.group({
    seRepetaLaFiecareNumber: [1, [Validators.required]],
    seRepetaLaFiecareTimeChoose: ['', [Validators.required]],
    seTermina: [this.radioGroupOptions.dupa],
    dupa: [0, [Validators.required]], // how many times to fix it on calendar
    pa: ['', [Validators.required]], // end date
    lunaRadio: '',
  });
  recurendtaFormGroup$: Subscription;
  dupaInputChanges$: Subscription;
  paInputChamges$: Subscription;
  daysOfWeekSelectionList: Array<{
    label: string;
    selected: boolean;
    value: number;
    fullLabel: string;
  }> = [
    {
      label: 'L', // Monday =>  luni
      selected: false,
      value: 1,
      fullLabel: 'Luni',
    },
    {
      label: 'M', // tuesday => marţi
      selected: false,
      value: 2,
      fullLabel: 'Marţi',
    },
    {
      label: 'M', // wednesday => miercuri
      selected: false,
      value: 3,
      fullLabel: 'Miercuri',
    },
    {
      label: 'J', // thursday => joi
      selected: false,
      value: 4,
      fullLabel: 'Joi',
    },
    {
      label: 'V', // friday => vineri
      selected: false,
      value: 5,
      fullLabel: 'Vineri',
    },
    {
      label: 'S', // saturday => sâmbătă
      selected: false,
      value: 6,
      fullLabel: 'Sâmbătă',
    },
    {
      label: 'D', // sunday => duminică
      selected: false,
      value: 0,
      fullLabel: 'Duminică',
    },
  ];
  lunaRadioConfig: IonRadiosConfig = {
    mode: 'item',
    inputLabel: {
      text: 'Se repetă lunar',
    },
    itemClasses: 'mr-12',
  };
  seRepetaLaFiecareNumber$: Subscription;
  showConflictNotify = false;
  lunaRadioOption: Array<IonRadioInputOption> = [
    { label: 'în aceeași zi din lună', id: 1 },
    { label: 'în aceeași zi din saptămână', id: 2 },
  ];
  checkAppointmentAvailability$: Subscription;
  checkAppointmentAvailabilityLoadingState = false;
  occurenceDates: Date[] = [];
  conflictData: RecurrenceConflictInterface[];
  lunaRadioControl$: Subscription;
  desktopWidth$: Subscription;
  isConflictDataEdited = false;
  constructor(
    private pS: PlatformService,
    private fb: FormBuilder,
    private modalController: ModalController,
    private programmareS$: ProgrammareService,
    private toastService: ToastService,
    private loadingContollerS: LoadingControllerService
  ) {}

  ngOnInit() {
    this.setRecurentaIfDataExists();

    this.desktopWidth$ = this.pS.isDesktopWidth$.subscribe(
      (v) => (this.isWed = v)
    );

    this.dupaInputChanges$ = this.subscribeToValueChanges(this.dupaFormControl);
    this.paInputChamges$ = this.subscribeToValueChanges(this.paFormControl);

    this.seRepetaLaFiecareNumber$ = this.subscribeToValueChanges(
      this.recurendtaFormGroup.get('seRepetaLaFiecareNumber')
    );
    this.lunaRadioControl$ = this.subscribeToValueChanges(
      this.lunaRadioControl
    );
  }

  setRecurentaIfDataExists() {
    if (this.setRecurentaData) {
      const {
        repeatTimes,
        appearances,
        endDate,
        daysSelection,
        lunaRadio,
        appRecurrenceTypeID,
      } = this.setRecurentaData;

      this.recurendtaFormGroup.patchValue({
        seRepetaLaFiecareNumber: repeatTimes,
        seRepetaLaFiecareTimeChoose:
          this.getUserTimeChooseById(Number(appRecurrenceTypeID))?.label ||
          'zile',
        pa: endDate ? format(new Date(endDate), 'yyyy-MM-dd') : '',
        dupa: appearances,
        lunaRadio,
      });
      if (daysSelection) {
        for (const [k, v] of Object.entries(daysSelection)) {
          const indexValue = Number(k.replace('day', ''));
          this.daysOfWeekSelectionList[indexValue].selected = v;
        }
      }
    } else {
      // default time type first value
      this.setZileValue(this.seRepetaLaFiecareOption[0].label);
    }
    // comute
    this.compute();
  }

  subscribeToValueChanges(formControl: AbstractControl) {
    return formControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe((_d: any) => {
        this.compute();
      });
  }
  toggleDropDown() {
    this.dropDownStatus = !this.dropDownStatus;
  }
  get isDropDown() {
    return this.dropDownStatus;
  }
  get timeChooseFormControl() {
    return this.recurendtaFormGroup.get('seRepetaLaFiecareTimeChoose');
  }
  get zileFormControlValue() {
    return this.timeChooseFormControl.value;
  }
  setZileValue(type: string) {
    this.timeChooseFormControl.setValue(type);
  }
  setZileValueAndToggle(data: any) {
    this.setZileValue(data.label);
    // toggle
    this.toggleDropDown();
    // comute
    this.compute();
    // reset
    this.resetWeekDataIfNotUserChoice();
    this.resetMonthDataIfNotUserChoice();
  }
  resetWeekDataIfNotUserChoice() {
    if (this.timeChooseFormControlIdValue !== 2) {
      this.daysOfWeekSelectionList.forEach((item) => (item.selected = false));
    }
  }
  resetMonthDataIfNotUserChoice() {
    if (this.timeChooseFormControlIdValue !== 3) {
      this.recurendtaFormGroup.patchValue({
        lunaRadio: '',
      });
    }
  }
  compute() {
    const {
      seRepetaLaFiecareNumber, // repeat times
      seRepetaLaFiecareTimeChoose, // repetition type:: dayly, weekly, monthly, yearly
      seTermina, // end type option
      dupa, // how many times to fix it on calendar
      pa, // end date
    } = this.recurendtaFormGroup.value;

    const occurance = Number(seRepetaLaFiecareNumber);

    // incepe => start date
    const userStartDateInput = new Date(this.incepe);

    switch (seTermina) {
      case this.radioGroupOptions.dupa:
        this.isDupa(
          seRepetaLaFiecareTimeChoose,
          userStartDateInput,
          Number(dupa),
          occurance
        );
        break;
      case this.radioGroupOptions.pa:
        this.isPa(
          seRepetaLaFiecareTimeChoose,
          userStartDateInput,
          pa,
          occurance
        );
        break;
      default:
        break;
    }
  }
  isPa(
    seRepetaLaFiecareTimeChoose: string,
    userDateInput: Date,
    pa: Date,
    occurance: number
  ) {
    const [days, weekly, monthly, yearly] = this.seRepetaLaFiecareOption;
    // let temp: any = userDateInput;
    let timeType = 'days';
    switch (seRepetaLaFiecareTimeChoose) {
      case days.label:
        timeType = 'days';
        break;
      case weekly.label:
        timeType = 'weeks';
        break;
      case monthly.label:
        timeType = 'months';
        break;
      case yearly.label:
        timeType = 'years';
        break;
      default:
        break;
    }
    const diff = dateDifference(userDateInput, pa, timeType);
    this.setDupa(Math.floor(diff / occurance));
  }
  isDupa(
    seRepetaLaFiecareTimeChoose: string,
    userDateInput: Date,
    appearance: number,
    occurance: number
  ) {
    const [days, weekly, monthly, yearly] = this.seRepetaLaFiecareOption;
    const temp: any = new Date(userDateInput);
    // clear occurence dates
    this.occurenceDates = [];
    // this.occurenceDates.push(userDateInput);

    switch (seRepetaLaFiecareTimeChoose) {
      case days.label:
        for (let index = 0; index < appearance; index++) {
          const calOccur = temp.setDate(temp.getDate() + occurance);
          this.occurenceDates.push(new Date(calOccur));
        }
        break;
      case weekly.label:
        for (let index = 0; index < appearance; index++) {
          const calOccur = temp.setDate(temp.getDate() + occurance * 7);
          const weekDays = dayInAWeekWithDate(new Date(calOccur));
          for (const d of this.daysOfWeekSelectionList) {
            if (d.selected) {
              this.occurenceDates.push(new Date(weekDays[d.value]));
            }
          }
        }
        break;
      case monthly.label:
        for (let index = 0; index < appearance; index++) {
          // const calOccur = temp.setMonth(temp.getMonth() + occurance);
          if (this.lunaRadioValue && this.lunaRadioValue === 1) {
            const cOccur = temp.setMonth(temp.getMonth() + occurance);
            this.occurenceDates.push(new Date(cOccur));
          } else {
            const caOccur = temp.setDate(temp.getDate() + occurance * 7 * 4);
            const weekDays = dayInAWeekWithDate(new Date(caOccur));
            this.occurenceDates.push(
              new Date(weekDays[new Date(userDateInput).getDay()])
            );
          }
        }
        break;
      case yearly.label:
        for (let index = 0; index < appearance; index++) {
          const calOccur = temp.setFullYear(temp.getFullYear() + occurance);
          this.occurenceDates.push(new Date(calOccur));
        }
        break;
      default:
        break;
    }
    this.setPa(getDateInYearMonthDay(temp));
  }
  get dupaFormControl() {
    return this.recurendtaFormGroup.get('dupa') as FormControl;
  }
  setDupa(data: number) {
    const d = data > 0 ? data : 0;
    this.dupaFormControl.patchValue(d, { emitEvent: false, onlySelf: false });
  }
  get paFormControl() {
    return this.recurendtaFormGroup.get('pa') as FormControl;
  }
  setPa(data: any) {
    this.paFormControl.patchValue(data, { emitEvent: false, onlySelf: false });
  }
  get incepe(): Date {
    return this.startDate; // startDate
  }
  get lunaRadioControl() {
    return this.recurendtaFormGroup.get('lunaRadio') as FormControl;
  }
  get lunaRadioValue() {
    return this.lunaRadioControl.value
      ? Number(this.lunaRadioControl.value)
      : 0;
  }
  getUserTimeChooseById(id: number) {
    return this.seRepetaLaFiecareOption.find((t: any) => t.id === id);
  }
  get userTimeChoose(): { id: number; label: string; value: string } {
    return this.seRepetaLaFiecareOption.find(
      (t: any) => t.label === this.timeChooseFormControl.value
    );
  }
  get timeChooseFormControlIdValue() {
    return this.userTimeChoose?.id;
  }
  dismiss(checkAvailability: boolean = false) {
    let recurentaData: any = null;
    if (this.occurenceDates.length > 0 && checkAvailability) {
      const {
        seRepetaLaFiecareNumber, // repeat times
        seRepetaLaFiecareTimeChoose, // repetition type:: dayly, weekly, monthly, yearly
        dupa, // how many times to fix it on calendar
        pa, // end date
        lunaRadio,
      } = this.recurendtaFormGroup.value;
      recurentaData = {
        // incepe => start date
        startDate: this.incepe
          ? formatRFC3339(new Date(this.incepe), { fractionDigits: 3 })
          : '',
        endDate: pa ? formatRFC3339(new Date(pa), { fractionDigits: 3 }) : '',
        appearances: Number(dupa) || 0,
        repeatTimes: Number(seRepetaLaFiecareNumber) || 0,
        occurenceType: seRepetaLaFiecareTimeChoose,
        appRecurrenceTypeID: this.timeChooseFormControlIdValue,
        daysSelection: this.selectedUserDays(),
        lunaRadio,
        firstDayOfMonth: this.lunaRadioValue === 1,
        firstDayOfWeek: this.lunaRadioValue === 2,
        text: this.recurentaText || '',
        dates:
          this.occurenceDates.map((v: Date) => ({
            startTime: formatISO(v),
            endTime: formatISO(addMinutes(v, this.duration)),
          })) || [],
      };
    }
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss(
      {
        dismissed: true,
        recurentaData,
      },
      undefined,
      'RecurentaComponent'
    );
  }
  backAction(): void {
    this.dismiss();
  }
  computeAndNavigate() {
    this.compute();
    this.backAction();
  }
  toggleRadio(item: any) {
    const d = this.daysOfWeekSelectionList.find((x) => x.value === item.value);
    if (typeof d !== 'undefined') {
      d.selected = !d.selected;
    }
    // comu
    this.compute();
  }
  async presentConflictProgramariModal() {
    const modal = await this.modalController.create({
      component: ConflictProgramariComponent,
      id: 'ConflictProgramariComponent',
      cssClass: 'biz-modal-class',
      backdropDismiss: true,
      componentProps: {
        conflictData: this.conflictData,
        recurrenceType: this.userTimeChoose?.label,
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (
      data &&
      data?.dismissed &&
      data?.adauga &&
      data?.conflictData &&
      data?.conflictDataFix
    ) {
      this.isConflictDataEdited = true;
      this.occurenceDates = data?.conflictData.map((x: any) =>
        x?.changedStartTime
          ? new Date(x?.changedStartTime)
          : new Date(x?.startTime)
      );
      this.checkRecurrenceAvailability();
    }
  }
  get displayStartDate(): string {
    return format(new Date(this.startDate), 'd.MMM.yyyy');
  }
  get displayStartTime() {
    return format(new Date(this.startDate), 'HH:mm');
  }
  get displayEndTime() {
    return format(addMinutes(this.startDate, this.duration), 'HH:mm');
  }
  selectedUserDays(reset: boolean = false) {
    const selectedDays = {};
    for (const v of this.daysOfWeekSelectionList) {
      if (!selectedDays[`day${v.value}`]) {
        selectedDays[`day${v.value}`] = reset ? false : v.selected;
      }
    }
    return selectedDays;
  }
  get recurentaText(): string {
    const {
      seRepetaLaFiecareNumber, // repeat times
      dupa,
    } = this.recurendtaFormGroup.value;
    return `Se repetă o dată la ${seRepetaLaFiecareNumber} ${this.timeChooseFormControl.value}, de ${dupa} ori`;
  }
  async checkRecurrenceAvailability() {
    // this.showConflictNotify = false;
    this.checkAppointmentAvailabilityLoadingState = true;
    await this.loadingContollerS.presentLoadingWithNoDuration(
      'Vă rugăm să așteptați...'
    );
    const payload = this.constructRecurencePayload();
    this.checkAppointmentAvailability$ = this.programmareS$
      .checkRecurrenceAvailability(payload)
      .pipe(
        switchMap((v: CheckRecurrenceAvailabilityResponse) => {
          if (v && v?.requestedDates && v?.requestedDates.length > 0) {
            return of(v);
          } else {
            return throwError('error');
          }
        })
      )
      .subscribe(
        (res: CheckRecurrenceAvailabilityResponse) => {
          this.loadingContollerS.dismiss();
          this.isConflictDataEdited = false;
          this.checkAppointmentAvailabilityLoadingState = false;
          const thereIsConflict =
            res?.requestedDates.filter(
              (r: RequestedDatesInterface) => !r?.isConfirmed
            ).length > 0;
          if (thereIsConflict) {
            this.conflictData = res?.requestedDates.map((reqD: any) => {
              // TODO: get alternativeTimeSlots
              const alternativeTimeSlots: MappedAlternativeTime = {};
              if (reqD?.alternativeSlots) {
                reqD?.alternativeSlots.forEach(
                  (x: { startDate: string; endDate: string }) => {
                    // check if not in object already
                    if (
                      !alternativeTimeSlots[
                        format(new Date(x?.startDate), 'yyyy-MM-dd')
                      ]
                    ) {
                      alternativeTimeSlots[
                        format(new Date(x?.startDate), 'yyyy-MM-dd')
                      ] = [];
                    }
                    alternativeTimeSlots[
                      format(new Date(x?.startDate), 'yyyy-MM-dd')
                    ].push({
                      date: format(new Date(x?.startDate), 'yyyy-MM-dd'),
                      startTime: format(new Date(x?.startDate), 'HH:mm'),
                      endTime: format(new Date(x?.endDate), 'HH:mm'),
                      checked: false,
                    });
                  }
                );
              }
              return {
                ...reqD,
                isConflict:
                  !reqD?.isConfirmed ||
                  Object.keys(alternativeTimeSlots).length > 0,
                uuid: generateUUID(),
                alternativeTimeSlots:
                  Object.keys(alternativeTimeSlots).length > 0
                    ? alternativeTimeSlots
                    : null,
                changedStartTime: null,
                conflictDateChanged: false,
                specialityCode: this.specialityCode,
                physicianUID: this.physicianUID,
                locationUID: this.locationUID,
                recurrenceTypeLabel:
                  this.userTimeChoose.id === 2
                    ? this.daysOfWeekSelectionListArrayLabel[
                        new Date(reqD.startTime).getDay()
                      ]
                    : this.userTimeChoose?.label,
              };
            });
            this.presentConflictProgramariModal();
            this.toastService.presentToastWithDurationDismiss(
              'Se pare ca exista un conflict cu unele date',
              'error'
            );
          } else {
            // no conflict dismiss
            this.dismiss(true);
          }
        },
        (_err: any) => {
          this.loadingContollerS.dismiss();
          this.checkAppointmentAvailabilityLoadingState = false;
          this.toastService.presentToastWithDurationDismiss(
            'apare o eroare în timp ce încercați să verificați disponibilitatea datei',
            'error'
          );
        }
      );
  }
  constructRecurencePayload(): CheckRecurrenceAvailabilityPayload {
    const dates = [];
    for (const d of this.occurenceDates) {
      dates.push({
        startTime: this.programmareS$.customFormatDate(d),
        endTime: this.programmareS$.customFormatDate(
          addMinutes(d, this.duration)
        ),
      });
    }
    return {
      specialityCode: this.specialityCode,
      physicianUID: this.physicianUID,
      cabinetUID: this.cabinetUID,
      locationUID: this.locationUID,
      paymentTypeID: this.paymentTypeID,
      medicalEquipmentsUIDs: this.medicalEquipmentsUIDs,
      recurrenceTypeID: this.timeChooseFormControlIdValue,
      duration: this.duration,
      dates,
    };
  }
  customFormatDate(date: Date | string) {
    return format(new Date(date), `yyyy-MM-dd'T'HH:mm`);
  }
  get daysOfWeekSelectionListArrayLabel(): string[] {
    return this.daysOfWeekSelectionList
      .sort((a: any, b: any) => a?.value - b?.value)
      .map((v: any) => v?.fullLabel);
  }
  ngOnDestroy() {
    unsubscriberHelper(this.recurendtaFormGroup$);
    unsubscriberHelper(this.dupaInputChanges$);
    unsubscriberHelper(this.paInputChamges$);
    unsubscriberHelper(this.seRepetaLaFiecareNumber$);
    unsubscriberHelper(this.checkAppointmentAvailability$);
    unsubscriberHelper(this.desktopWidth$);
    unsubscriberHelper(this.lunaRadioControl$);
    unsubscriberHelper(this.checkAppointmentAvailability$);
  }
}
