import {
  GetPhysicianResponse,
  GetPhysicianResponsePhysician,
  Physician,
} from './../../models/Physician.model';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Login } from './../../models/login.interface';
import { CustomStorageService } from './../custom-storage/custom-storage.service';
import { RequestService } from './../request/request.service';
import { Injectable } from '@angular/core';
import {
  appointmentEndpoints,
  authEndpoints,
  physicians,
} from '../../configs/endpoints';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { User } from '../../models';
import { ParameterState } from '../../models/parameter.model';
import { MenuController, NavController } from '@ionic/angular';
import { DictionaryService } from '../dictionary/dictionary.service';
import { UserRightsRespose } from '@app/core/models/userRights.model';
import { unsubscriberHelper } from '@app/core/helpers/unsubscriber.helper';
import { Version } from '@app/core/models/version.interface';
import { compare } from 'compare-versions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  tokenStore: BehaviorSubject<any> = new BehaviorSubject(null);
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = '';
  timer$: Subscription;
  public loggedInPhysicianSubject: BehaviorSubject<Physician> =
    new BehaviorSubject(null);
  public hasMultiplePhysiciansSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public user: Observable<User>;
  public userPhysicians$: BehaviorSubject<{
    init: boolean;
    physicians: Physician[];
  }> = new BehaviorSubject({
    init: false,
    physicians: null,
  });
  public appVersion$: BehaviorSubject<Version> = new BehaviorSubject(null);
  private userSubject: BehaviorSubject<User>;
  private parameters$: BehaviorSubject<ParameterState> =
    new BehaviorSubject<ParameterState>({
      init: false,
      parameters: null,
    });
  private getPhysicians$: BehaviorSubject<{
    init: boolean;
    data: GetPhysicianResponsePhysician[];
  }> = new BehaviorSubject({
    init: false,
    data: null,
  });
  private userRights$: BehaviorSubject<{
    init: boolean;
    data: UserRightsRespose;
  }> = new BehaviorSubject({
    init: false,
    data: null,
  });
  constructor(
    private reqS: RequestService,
    private customS: CustomStorageService,
    private routerS: Router,
    private menu: MenuController,
    private dictionaryS: DictionaryService,
    private navCrtl: NavController
  ) {
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('user'))
    );
    this.user = this.userSubject.asObservable();
    const hasMultiplePh = JSON.parse(
      localStorage.getItem('hasMultiplePhysicians')
    );
    const parameters = JSON.parse(localStorage.getItem('parameters'));
    const userPhysicians = JSON.parse(localStorage.getItem('userPhysicians'));
    const loggedInPhysician = JSON.parse(
      localStorage.getItem('loggedInPhysician')
    );
    const userRights = JSON.parse(localStorage.getItem('userRights'));

    if (parameters && userPhysicians && loggedInPhysician && userRights) {
      this.parameters$.next({
        init: true,
        parameters,
      });
      this.userPhysicians$.next({
        init: true,
        physicians: userPhysicians || [],
      });
      this.loggedInPhysicianSubject.next(loggedInPhysician);
      this.hasMultiplePhysiciansSubject.next(hasMultiplePh);
      this.userRights$.next({
        init: true,
        data: userRights,
      });
      // get params every 30sec
      this.getParameterEveryThirtyMinutes();
    } else {
      // user needs to login again
      this.logout();
    }
  }
  public get userValue(): User {
    return this.userSubject.value;
  }

  public get loggedInPhysician(): Physician {
    return this.loggedInPhysicianSubject.value;
  }

  public set loggedInPhysician(newLoggedIn: Physician) {
    const getPhysicianData =
      this.userPhysicians$.value.physicians.find(
        (p: Physician) => p.physicianUID === newLoggedIn.physicianUID
      ) || newLoggedIn;
    this.loggedInPhysicianSubject.next(getPhysicianData);
    localStorage.setItem(
      'loggedInPhysician',
      JSON.stringify(this.loggedInPhysicianSubject.value)
    );
  }

  public get hasMultiplePhysicians(): boolean {
    return this.hasMultiplePhysiciansSubject.value;
  }

  getParametersFromStorage() {
    return JSON.parse(localStorage.getItem('parameters'));
  }

  setParametersInStorage(parameters) {
    localStorage.setItem('parameters', JSON.stringify(parameters));
    this.parameters$.next({ init: true, parameters });
  }

  login(loginData: {
    username: string;
    password: any;
    aRoute: string | ActivatedRoute;
  }) {
    const credentials: Login = {
      username: loginData.username,
      password: loginData.password,
    };
    return this.reqS.post(authEndpoints.auth, credentials).pipe(
      map((user: User) => {
        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        user.authdata = window.btoa(
          loginData.username + ':' + loginData.password
        );
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      }),
      switchMap((v: any) => this.getPhysicians().pipe(map(() => v))),
      switchMap((user: User) =>
        this.getUserPhysicians().pipe(
          map((userPhysicians) => ({
            user,
            userPhysicians: userPhysicians?.physicians || [],
          }))
        )
      ),
      switchMap((v: any) => this.getUsersRights().pipe(map(() => v))),
      switchMap((data) =>
        this.dictionaryS.setDictionary().pipe(map(() => data))
      ),
      tap(() => this.getParameterEveryThirtyMinutes())
    );
  }
  saveToken(token) {
    this.tokenStore.next(token);
    return this.customS.setItem('token', token);
  }

  redirectUrlTree(snapshot: ActivatedRouteSnapshot): UrlTree {
    if (snapshot) {
      const qP = snapshot.queryParams;
      const rUk = 'returnUrl';
      if (qP.hasOwnProperty(rUk) && qP[rUk]) {
        return this.routerS.createUrlTree([qP[rUk]]);
      }
    }
    return this.routerS.createUrlTree(['/']);
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('parameters');
    localStorage.removeItem('workHours');
    localStorage.removeItem('userPhysicians');
    localStorage.removeItem('loggedInPhysician');
    localStorage.removeItem('hasMultiplePhysicians');
    localStorage.removeItem('variant');
    localStorage.removeItem('dictionary');
    localStorage.removeItem('userRights');
    this.userSubject.next(null);
    this.appVersion$.next(null);
    // TODO: unsubscribe timer
    unsubscriberHelper(this.timer$);
    // disable menu => route to login page
    this.menu.enable(false);
    // replaceUrl => true to remove previous history and trigger onDestroy on all components when loggin out
    this.navCrtl.navigateRoot(['/login'], { replaceUrl: true });
  }
  getParameters() {
    return this.reqS.get(authEndpoints.getParameters).pipe(
      tap((v: any) => {
        // save parameters
        localStorage.setItem('parameters', JSON.stringify(v.parameters));
        // -------
        this.parameters$.next({
          init: true,
          parameters: v.parameters,
        });
      })
    );
  }
  getParameterState() {
    return this.parameters$.pipe(
      filter(
        (val: ParameterState) => val && val.hasOwnProperty('init') && val.init
      ),
      distinctUntilChanged()
    );
  }
  getUserPhysicians$(): Observable<any> {
    return this.userPhysicians$.pipe(
      filter((val: any) => val && val.hasOwnProperty('init') && val.init),
      distinctUntilChanged()
    );
  }
  getUserPhysicians() {
    return this.reqS.get(appointmentEndpoints.getUserPhysicians).pipe(
      tap((v: any) => {
        let physicianData = v?.physicians;
        if (
          this.getPhysicians$.value.data &&
          this.getPhysicians$.value.data.length > 0
        ) {
          physicianData = v?.physicians.map((p: Physician) => ({
            specialityData: this.getPhysicians$.value.data.filter(
              (ph: GetPhysicianResponsePhysician) => ph.uid === p.physicianUID
            ),
            ...p,
          }));
        }
        // select first physician from the list as default
        localStorage.setItem(
          'loggedInPhysician',
          JSON.stringify(physicianData[0])
        );
        localStorage.setItem(
          'userPhysicians',
          JSON.stringify(physicianData || [])
        );
        this.loggedInPhysicianSubject.next(physicianData[0]);
        this.userPhysicians$.next({
          init: true,
          physicians: physicianData || [],
        });
      })
    );
  }
  getPhysicians(): Observable<GetPhysicianResponse> {
    return this.reqS.post(physicians.getPhysicians, {}).pipe(
      tap((v: GetPhysicianResponse) => {
        this.getPhysicians$.next({
          init: true,
          data: v?.physicians || [],
        });
      })
    );
  }
  getUsersRights(): Observable<UserRightsRespose> {
    return this.reqS.get(authEndpoints.getUserRights).pipe(
      tap((res: UserRightsRespose) => {
        if (res) {
          this.hasMultiplePhysiciansSubject.next(res.hasMultiplePhysicians);
          localStorage.setItem(
            'hasMultiplePhysicians',
            JSON.stringify(res.hasMultiplePhysicians)
          );
          // store users right
          localStorage.setItem('userRights', JSON.stringify(res));
          this.userRights$.next({
            init: true,
            data: res,
          });
        }
      })
    );
  }
  getUserRightsState(): Observable<UserRightsRespose> {
    return this.userRights$.pipe(
      filter((val: any) => val && val.hasOwnProperty('init') && val.init),
      distinctUntilChanged(),
      switchMap((v: { init: boolean; data: UserRightsRespose }) => of(v.data))
    );
  }
  getUserRightsBehaviorSubjectValueData(): UserRightsRespose {
    return this.userRights$.getValue().data || null;
  }
  getParameterEveryThirtyMinutes(): void {
    /*
     *   TODO: every 30minutes get parameter
     */
    unsubscriberHelper(this.timer$);
    this.timer$ = timer(1800000, 1800000)
      .pipe(switchMap(() => this.getParameters()))
      .subscribe();
  }

  getVersion(): Observable<Version> {
    return this.appVersion$.pipe(
      take(1),
      switchMap((v: Version) => {
        if (v && v.hasOwnProperty('init') && v.init && !v.failedRequest) {
          return of(v);
        }
        return this.reqS.get(authEndpoints.getVersion).pipe(
          map((res: any) => {
            this.appVersion$.next({
              init: true,
              data: res,
              failedRequest: false,
            });
            return this.appVersion$.getValue();
          }
          ),
          catchError(() => {
            this.appVersion$.next({
              init: true,
              data: null,
              failedRequest: true,
            });
            return of(this.appVersion$.getValue());
          }
          )
        );
      })
    );
  }

  isInMaintainanceOrBelowMinVersion(): Observable<UrlTree | boolean> {
    return this.getVersion().pipe(
      switchMap((versionData: Version) => this.getAppCurrentVersion().pipe(
        take(1),
        map((appVersion) => ({ versionData, appVersion })
        )
      )),
      map((v) => {
        if (v.versionData.init && v.versionData.failedRequest) {
          return this.routerS.createUrlTree(['/app-maintenance']);
        } else if (v.versionData.data && compare(v.versionData.data.minVersion, v.appVersion, '>')) {
          return this.routerS.createUrlTree(['/app-upgrade']);
        }
        return true;
      })
    );
  }

  getAppCurrentVersion(): Observable<string> {
    return this.reqS.get('/assets/version.json').pipe(
      map((v: any) => v.version
      )
    );
  }

  registerPushToken(token) {
    const data = {
      id: this.loggedInPhysicianSubject.value?.uid,
      username: this.loggedInPhysicianSubject.value?.name,
      token,
      registrationDate: Date.now()
    };
    return this.reqS.post('/', data);
  }
}
