export const dateDifference = (
  date1: string | number | Date,
  date2: string | number | Date,
  interval: any
) => {
  const second = 1000;
  const minute: number = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  date2 = new Date(date2);
  date1 = new Date(date1);
  const timediff: any = date2.getTime() - date1.getTime();
  if (isNaN(timediff)) {
    return NaN;
  }
  switch (interval) {
    case 'years':
      return date2.getFullYear() - date1.getFullYear();
    case 'months':
      return (
        date2.getFullYear() * 12 +
        date2.getMonth() -
        (date1.getFullYear() * 12 + date1.getMonth())
      );
    case 'weeks':
      return Math.floor(timediff / week);
    case 'days':
      return Math.floor(timediff / day);
    case 'hours':
      return Math.floor(timediff / hour);
    case 'minutes':
      return Math.floor(timediff / minute);
    case 'seconds':
      return Math.floor(timediff / second);
    default:
      return undefined;
  }
};

export const getDateInYearMonthDay = (date: Date) => {
  const dateObj = new Date(date);
  /* const month = dateObj.getUTCMonth() + 1; //months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  return `${ year }-${ month }-${ day }`; */
  return dateObj.toISOString().slice(0, 10); // yyyy-mmm-dd
};

export const dayInAWeekWithDate = (current: Date): Array<Date> => {
  const week = new Array();
  // Starting Monday not Sunday use current.getDate() - current.getDay() + 1
  current.setDate(current.getDate() - current.getDay());
  for (let i = 0; i < 7; i++) {
    week.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }
  return week; // dayInAWeekWithDate(new Date('2021-10-18'));
};

/* cpn date helper */

import { differenceInDays, differenceInYears, formatISO } from 'date-fns';
export const dateHelperYear = (date: Date | string) => {
  try {
    const dateV = new Date(date);
    return dateV.getFullYear();
  } catch (err) {
    return '';
  }
};
export const dateHelperDMY = (date: Date | string) => {
  try {
    const dateV = new Date(date);
    return (
      dateV.getDate() +
      '.' +
      dateHelperDigitMonth(dateV.getMonth()) +
      '.' +
      dateV.getFullYear()
    );
  } catch (err) {
    return '';
  }
};
export const dateHelperYMDIso = (date: Date | number) =>
  formatISO(date, { representation: 'date' });
const dateHelperDigitMonth = (singleDigitMonth) =>
  ('0' + (singleDigitMonth + 1)).slice(-2);
export const getTomorrowDate = () => {
  const today = new Date().getTime();
  const tomorrow = new Date(
    today - new Date(today).getTimezoneOffset() * 60000
  );
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};
// simple
export const getUTCdate = () => {
  const date = new Date();
  const nowUtc = date.toISOString();
  return nowUtc;
};
export const getTommorrowUTCdate = () => {
  const getTomorrow = getTomorrowDate();
  const nowUtc = getTomorrow.toISOString();
  return nowUtc;
};
export const getDateAfterDays = (numDays: number) => {
  const nowPlusDays = new Date().setDate(new Date().getDate() + numDays);
  return new Date(nowPlusDays).toISOString();
};
export const calcDateAfterDays = (
  date: Date | string,
  numDays: number
): Date => {
  const currentD = date instanceof Date ? date : Date.parse(date);
  return new Date(new Date().setDate(new Date(currentD).getDate() + numDays));
};
export const getDayDateDiff = (date1: Date, date2: Date) =>
  differenceInDays(date1, date2);
export const getYearDateDiff = (date1: Date, date2: Date) =>
  differenceInYears(date1, date2);
export const getAge = (
  date: string | Date
): {
  fullAgeText: string;
  ageYear: number;
  ageMonth: number;
  ageYearText: string;
  ageMonthText: string;
} => {
  const today = new Date();
  const birthDate = new Date(date);
  let ageYear = today.getFullYear() - birthDate.getFullYear();
  let ageMonth = 0;
  if (today.getMonth() >= birthDate.getMonth()) {
    ageMonth = today.getMonth() - birthDate.getMonth();
  } else {
    ageYear = --ageYear;
    ageMonth = 12 + today.getMonth() - birthDate.getMonth();
  }
  const ageYearText =
    ageYear === 0 ? '' : ageYear > 1 ? `${ageYear} ani` : `${ageYear}an`;
  const ageMonthText =
    ageMonth === 0
      ? ''
      : ageMonth > 1
      ? `${ageMonth} luni`
      : `${ageMonth} lună`;
  return {
    fullAgeText: `${ageYearText} ${ageMonthText}`,
    ageYear,
    ageMonth,
    ageYearText,
    ageMonthText,
  };
};
