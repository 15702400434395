import { NotificationService } from './../../models/notification-service.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { cauta } from '../../configs/endpoints';
import { paymentService, notificationsEndpoint } from '../../configs/endpoints';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root',
})
export class CautaService {
  searchResult$ = new BehaviorSubject<any[]>(null);
  searchKeyword$ = new BehaviorSubject<string>(null);
  sortLabel$ = new BehaviorSubject<string>(null);
  notificationEnum: NotificationService = {
    totalPushNotifications: 0,
    totalUnreadPushNotifications: 0,
    totalNonPushNotification: 0,
    totalUnreadNonPushNotification: 0,
    notifications: [],
    isLoaded: false,
    hasError: false,
  };
  notifications$ = new BehaviorSubject<NotificationService>(this.notificationEnum);
  constructor(private reqS: RequestService) {
  }
  searchCalendar(search: { keyword: string }[]) {
    return this.reqS.post<any>(cauta.SearchCalendar, search);
  }

  getPersonCompanies(personUID: string) {
    return this.reqS.get<any>(`${paymentService.GetPersonCompanies}?personUID=${personUID}`);
  }

  getPersonPromotionPackages(personUID: string, physicianUID: string, serviceUID) {
    return this.reqS.get<any>
      (`${paymentService.GetPersonPromotionPackages}?personUID=${personUID}&physicianUID=${physicianUID}&serviceUID=${serviceUID}`);
  }

  getNotifications(): Observable<any> {
    return this.reqS.get<NotificationService>(`${notificationsEndpoint.getNotifications}`).pipe(map(res => {
      this.notifications$.next({ ...res, isLoaded: true, hasError: false });
      return this.notifications$.value;
    }
    ), catchError(err => {
      this.notifications$.next({ ...this.notificationEnum, isLoaded: true, hasError: true });
      return err;
    }
    ));
  }

  setNotificationAsRead(notificationID) {
    return this.reqS.post<any>(`${notificationsEndpoint.readNotification}?notificationID=${notificationID}`, {
      notificationID
    });
  }
}
