import { Country } from 'ngx-intl-tel-input/lib/model/country.model';
import { allCountries } from './data/countryCode.data';
import { CountryISO } from './enum/country-iso.enum';
import libphonenumber from 'google-libphonenumber';

const phoneUtil: any = libphonenumber.PhoneNumberUtil.getInstance();
/**
 * Clearing the list to avoid duplicates (https://github.com/webcat12345/ngx-intl-tel-input/issues/248)
 */
export const allCountryData = (): Country[] =>
  allCountries.map((c: any) => ({
    name: c[0].toString(),
    iso2: c[1].toString(),
    dialCode: c[2].toString(),
    priority: +c[3] || 0,
    areaCodes: (c[4] as string[]) || undefined,
    htmlId: `iti-0__item-${c[1].toString()}`,
    flagClass: `iti__${c[1].toString().toLocaleLowerCase()}`,
    placeHolder: '',
  }));
export const getCountryIsoCode = (
  countryCode: number,
  num: libphonenumber.PhoneNumber
): string | undefined => {
  // Will use this to match area code from the first numbers
  // eslint-disable-next-line @typescript-eslint/dot-notation
  const rawNumber = num['values_']['2'].toString();
  // List of all countries with countryCode (can be more than one. e.x. US, CA, DO, PR all have +1 countryCode)
  const countries = allCountryData().filter(
    (c: Country) => c.dialCode === countryCode.toString()
  );
  // Main country is the country, which has no areaCodes specified in country-code.ts file.
  const mainCountry = countries.find((c) => c.areaCodes === undefined);
  // Secondary countries are all countries, which have areaCodes specified in country-code.ts file.
  const secondaryCountries = countries.filter(
    (c: Country) => c.areaCodes !== undefined
  );
  let matchedCountry = mainCountry ? mainCountry.iso2 : undefined;

  /*
	Iterate over each secondary country and check if nationalNumber starts with any of areaCodes available.
    If no matches found, fallback to the main country.
*/
  secondaryCountries.forEach((country) => {
    country.areaCodes.forEach((areaCode) => {
      if (rawNumber.startsWith(areaCode)) {
        matchedCountry = country.iso2;
      }
    });
  });
  return matchedCountry;
};
export const isNumberValid = (userNum: string): boolean => {
  // const num = phoneUtil.parseAndKeepRawInput(userNum);
  let valid = false;
  try {
    valid =
      phoneUtil.isValidNumber(phoneUtil.parse(userNum)) &&
      phoneUtil.isPossibleNumber(phoneUtil.parse(userNum));
  } catch (e) {
    valid = false;
  }
  return valid;
};
export const getPhoneDetails = (userNum: string) => {
  /*
    const d = this.phoneUtil.format(
        this.phoneUtil.parse('+17187514850'),
        libphonenumber.PhoneNumberFormat.INTERNATIONAL
      );
     */
  if (!isNumberValid(userNum)) {
    return {
      iso2: null,
      dialCode: null,
      phoneWithoutDailCode: null,
      internationalNumber: null,
      nationalNumber: null,
      e164Number: null,
      countryCode: null,
      isNumberValid: false,
    };
  }
  const num = phoneUtil.parse(userNum);
  const countryCode =
    num && num.getCountryCode()
      ? getCountryIsoCode(num.getCountryCode(), num)
      : CountryISO.Romania; // Default to romania
  const newCountry = allCountryData()
    .sort((a: any, b: any) => a.priority - b.priority)
    .find((c) => c.iso2 === countryCode);
  return {
    iso2: newCountry.iso2,
    dialCode: '+' + newCountry.dialCode,
    phoneWithoutDailCode: removeDialCode(userNum),
    internationalNumber: num
      ? phoneUtil.format(num, libphonenumber.PhoneNumberFormat.INTERNATIONAL)
      : '',
    nationalNumber: num
      ? phoneUtil.format(num, libphonenumber.PhoneNumberFormat.NATIONAL)
      : '',
    e164Number: num
      ? phoneUtil.format(num, libphonenumber.PhoneNumberFormat.E164)
      : '',
    countryCode: countryCode.toUpperCase(),
    isNumberValid: true,
  };
};
export const removeDialCode = (phoneNumber: string): string => {
  const numb = phoneUtil.parse(phoneNumber);
  phoneNumber = phoneUtil.format(
    numb,
    libphonenumber.PhoneNumberFormat.INTERNATIONAL
  );
  if (phoneNumber.startsWith('+')) {
    phoneNumber = phoneNumber.substring(phoneNumber.indexOf(' ') + 1);
  }
  return phoneNumber;
};
export const getSelectedCountryEnum = (iso2: string): any => {
  const k = Object.keys(CountryISO)
    .map((key: any) => ({ key, CountryISO: CountryISO[key] }))
    .filter((value: any) => value?.CountryISO === iso2);
  return CountryISO[k[0]?.key];
};
