import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EE', locale);
  }

  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'MMM y', locale);
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'E', locale);
  }

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    const minutes = new Date(date).getMinutes();
    return minutes === 0 ? formatDate(date, 'HH:mm', locale) : '';
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    const minutes = new Date(date).getMinutes();
    return minutes === 0 ? formatDate(date, 'HH:mm', locale) : '';
  }
}
