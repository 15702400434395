import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getAge } from '@app/core/helpers/date.helper';
import { ModalController } from '@ionic/angular';
import {
  format,
} from 'date-fns';
import { BehaviorSubject, Subscription } from 'rxjs';
import { group, persons } from 'src/app/core/configs/endpoints';
import { getGender } from 'src/app/core/helpers/app-gender-helper';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import { CreateGroup } from 'src/app/core/models/createGroup.model';
import { Person } from 'src/app/core/models/person.model';
import { PersonsResponse } from 'src/app/core/models/person.response.interface';
import { RequestService } from 'src/app/core/services/request/request.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { inputConfigHelper } from 'src/app/shared/data/input-config-helper';
import { BizSelectieServiciiConfig } from 'src/app/shared/models/components/biz-selectie-servicii.config';
import { NewPacientModalComponent } from '../new-pacient-modal/new-pacient-modal.component';
import { PacientViewModalComponent } from '../pacient-view-modal/pacient-view-modal.component';
import { SelectieServiciiModalComponent } from '../selectie-servicii-modal/selectie-servicii-modal.component';
import { GroupService } from '@app/shared/services/group.service';

@Component({
  selector: 'app-grup-nou-modal',
  templateUrl: './grup-nou-modal.component.html',
  styleUrls: ['./grup-nou-modal.component.scss'],
})
export class GrupNouModalComponent implements OnInit, OnDestroy {
  @Input() set editData(value: any) {
    this.isEdit = true;
    this.editGroupInfo = value.groupInfo;
    this.eidtGroupMembers = value.members;
    this.patchFormGroup(value.groupInfo, value.members);
  }
  editGroupInfo = null;
  eidtGroupMembers: any[] = [];
  isEdit = false;
  numeGrupConfig = inputConfigHelper({
    label: 'Nume grup',
    type: 'url',
    placeholder: '',
  });

  componentFormGroup: FormGroup = this.fb.group({
    numeGrup: ['', [Validators.required]],
  });
  createGroup$: Subscription;
  getPersons$: Subscription;
  personsList$: BehaviorSubject<Array<Person>> = new BehaviorSubject<
    Array<Person>
  >([]);
  selectedValue: any[] = [];
  personSelectServiciiOptionConfig: BizSelectieServiciiConfig = {
    firstKey: 'fullName',
    secondKey: 'gender',
    thirdKey: 'age',
    idKey: 'uid',
  };
  disableAddMemberButton = true;
  loading = false;
  personsGroupUID = '';
  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private reqS: RequestService,
    private toastService: ToastService,
    private groupService: GroupService
  ) {}
  toggleLoadingState() {
    this.loading = !this.loading;
  }

  ngOnInit() {
    this.getPersons();
  }

  patchFormGroup(groupInfo: any, members: any[]) {
    this.componentFormGroup.patchValue({
      numeGrup: groupInfo.groupName,
    });
    this.selectedValue = members.map((item) => item.uid);
  }

  async closeModal(groupCreated: boolean = false) {
    await this.modalController.dismiss({
      dismissed: true,
      groupCreated,
      data:
        this.personsGroupUID !== ''
          ? {
              groupName: this.componentFormGroup.value.numeGrup,
              personsGroupUID: this.personsGroupUID,
            }
          : null,
    });
  }
  get formGroupValidity() {
    return this.componentFormGroup.valid && this.selectedValue.length > 0;
  }

  // add members modal
  async presentSelectModal() {
    const modal = await this.modalController.create({
      component: SelectieServiciiModalComponent,
      cssClass: 'biz-modal-class',
      componentProps: {
        checkList: this.personsList$.value || [],
        config: this.personSelectServiciiOptionConfig,
        selectedValue: this.selectedValue,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.selectedValue = data?.checkedValue;
  }

  // open group memeber details
  async presentPacientViewModal(item: Person) {
    const modal = await this.modalController.create({
      component: PacientViewModalComponent,
      cssClass: 'biz-modal-class',
      componentProps: {
        data: item,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.personData) {
      switch (data?.action) {
        case 'edit':
          this.presentNewPacientModal(data?.personData);
          break;
        case 'delete':
          this.unCheckItem(data?.personData?.uid);
          break;
        default:
          break;
      }
    }
  }
  async presentNewPacientModal(personData: any) {
    const {
      firstName,
      lastName,
      birthDate,
      genderID,
      email,
      phone,
      pid,
      cityID,
      uid,
      promotionChannelUID,
    } = personData;
    const d = {
      nume: lastName || '',
      preNume: firstName || '',
      dateNasterii: format(new Date(birthDate), 'yyyy-MM-dd'),
      sex: genderID || 0,
      telephone: phone || '',
      email: email || '',
      cnp: pid || '',
      oras: cityID || '',
      canalDePromovare: promotionChannelUID || '',
    };
    const modal = await this.modalController.create({
      component: NewPacientModalComponent,
      cssClass: 'biz-modal-class',
      backdropDismiss: true,
      componentProps: {
        data: d,
        isEdit: true,
        uid,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.dismissed && data?.isPersonUpdated) {
      this.getPersons();
    }
  }

  // remove member from group function
  unCheckItem(uid: string): void {
    if (typeof uid !== null && uid !== '') {
      const indexOfData = this.selectedValue.findIndex((v: any) => v === uid);
      if (indexOfData > -1) {
        this.selectedValue.splice(indexOfData, 1);
      }
    }
  }

  //create/edit group
  saveGroup() {
    if (this.isEdit) {
      this.saveGroupEdit();
    } else {
      this.createGroup();
    }
  }

  saveGroupEdit() {
    this.groupService.editGroup(this.prepareEditPayload()).subscribe(
      (res) => {
        this.closeModal(true);
      },
      (err) => {
        this.toastService.presentToastWithDurationDismiss('Eroare salvare', 'error', 3000);
      }
    );
  }

  prepareEditPayload() {
    return {
      personsGroupUID: this.editGroupInfo.personsGroupUID,
      groupName: this.componentFormGroup.value.numeGrup,
      isActive: true,
      personsGroupMembers: this.selectedValue.map((memberUID: any) => {
        const member = this.eidtGroupMembers.find((p: any) => p.uid === memberUID);
        const newObj = {
          personGroupUID: this.editGroupInfo.personsGroupUID,
          personUID: memberUID,
          personsGroupMemberUID: member?.personsGroupMemberUID ? member.personsGroupMemberUID : null,
          registrationDate: member?.registrationDate ? member.registrationDate : new Date().toDateString(),
        };
        if (newObj.personsGroupMemberUID === null) {
          delete newObj.personsGroupMemberUID;
        }
        return newObj;
      })
    };
  }

  createGroup() {
    if (this.componentFormGroup.valid) {
      this.toggleLoadingState();
      const payload: CreateGroup = {
        groupName: this.componentFormGroup.value.numeGrup,
      };
      this.createGroup$ = this.reqS
        .post<any>(group.createGroup, payload)
        .subscribe(
          (d: any) => {
            this.personsGroupUID = d.insertedUID;
            this.addMemberToGroup(d.insertedUID);
          },
          (_err) =>
            this.toastService.presentToastWithDurationDismiss(
              'A apărut o eroare. Va rugăm să reîncercați.'
            )
        );
    }
  }

  //add person to group
  addMemberToGroup(personsGroupUID: string) {
    const payload: any = {
      personsGroupUID,
      personsUIDs: this.selectedValue,
    };
    this.reqS.post<any>(group.addMembersToGroup, payload).subscribe(
      (d: any) => {
        this.toggleLoadingState();
        this.closeModal(true);
      },
      (_err) => {
        this.toggleLoadingState();
        this.toastService.presentToastWithDurationDismiss(
          'A apărut o eroare. Va rugăm să reîncercați.'
        );
      }
    );
  }
  getPersons() {
    this.getPersons$ = this.reqS
      .post<any>(persons.getPersons, {
        searchString: '',
        // personUID: '3fa85f64-5717-4562-b3fc-2c963f66afa6'
      })
      .subscribe(
        (d: PersonsResponse) => {
          // enable button
          this.disableAddMemberButton = false;
          /*  */
          this.personsList$.next([
            ...d.persons
              .map((v: any) => ({
                ...v,
                gender: getGender(v?.genderID)
                  ? getGender(v?.genderID).slice(0, 1)
                  : '-',
                fullName: `${v?.firstName} ${v?.lastName}`,
                age: getAge(new Date(v?.birthDate)).ageYear,
              }))
              .sort((a: Person, b: Person) =>
                a?.fullName.localeCompare(b?.fullName)
              ),
          ]);
        },
        (_err) => {
          this.disableAddMemberButton = true;
          this.toastService.presentToastWithDurationDismiss(
            'A apărut o eroare. Va rugăm să reîncercați.'
          );
        }
      );
  }
  get selectedPersons() {
    return this.personsList$.value.length > 0
      ? this.personsList$.value.filter((v: any) =>
          this.selectedValue.includes(
            v[`${this.personSelectServiciiOptionConfig.idKey}`]
          )
        )
      : [];
  }
  ngOnDestroy(): void {
    unsubscriberHelper(this.createGroup$);
    unsubscriberHelper(this.getPersons$);
  }
}
