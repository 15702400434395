import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { CalendarView } from 'angular-calendar';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import { DictionaryService } from 'src/app/core/services/dictionary/dictionary.service';
import { SidemenuService } from 'src/app/core/services/sidemenu/sidemenu.service';
import { EVENTLIST } from 'src/app/home/event';
import { IonSelectConfig } from '../../models/components/ion-select-config';
import { CalendarPages } from '../calendar/calendarPages';
import { distinctCheckObj } from './../../../core/helpers/distinct-check.helper';
import { CalendarService } from './../../../core/services/calendar/calendar.service';
import {
  aparatList,
  cabinetList,
  selectConfig,
  utilizatorList,
} from './../../data/select-data';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state(
        'closed',
        style({ height: '0px', opacity: 0, visibility: 'hidden' })
      ),
      transition(
        'open <=> closed',
        animate('500ms cubic-bezier(.37,1.04,.68,.98)')
      ),
    ]),
  ],
})
export class CalendarHeaderComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() isModal = false;
  @Input() isTablet = false;
  @Input() page;
  isComparativ = false;
  showPicker = false;
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  month = format(new Date(), 'MMMM', { locale: ro });
  currDay = format(new Date(), 'E', { locale: ro });
  currDate = format(new Date(), 'd', { locale: ro });
  CalendarView = CalendarView;
  locationCal: FormControl = new FormControl('');
  activatedPath = '';
  dictionary = this.dictionaryS.getDictionary();
  variant = this.dictionaryS.getVariant();
  config: IonSelectConfig = selectConfig;
  locationConfig: IonSelectConfig = {
    ...selectConfig,
    multiple: false,
  };
  configB: IonSelectConfig = {
    ...selectConfig,
    multiple: false,
  };
  locationOptions = [];
  programOptions = [
    {
      id: '1',
      label: `Program ${this.dictionary.profesii[this.variant].toLowerCase()}`,
    },
    {
      id: '2',
      label: `Program ${this.dictionary.sali[this.variant].toLowerCase()}`,
    },
    {
      id: '3',
      label: `Program ${this.dictionary.aparate[this.variant].toLowerCase()}`,
    },
  ];
  programList = utilizatorList;
  totalAppt = 0;

  opts = {
    freeMode: true,
    slidesPerView: 3,
    slidesOffsetBefore: 30,
    slidesOffsetAfter: 100,
  };
  calendarList: {
    day: string;
    current: boolean;
    events: {
      id: number;
      title: string;
      time: string;
      desc: string;
      location: string;
      class: string;
    }[];
  }[];
  locationForm: FormGroup = this.fb.group({
    location: [''],
    program: [''],
  });
  eventSource = [];
  viewTitle: string;

  calendar = {
    mode: 'month',
    currentDate: new Date(),
  };
  physRef$ = this.calS.selectedPhysician;
  selectedDate$: Subscription;
  formChanges$: Subscription;
  public calendarPages = CalendarPages;
  isSplitPaneVisible = false;
  sideMenuS$: Subscription;
  doubleNavCheck;
  oldFormValues = { location: null, program: null };
  currentProgram;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private calS: CalendarService,
    private fb: FormBuilder,
    private menu: MenuController,
    private sideMenuS: SidemenuService,
    private dictionaryS: DictionaryService
  ) {
    this.activatedRoute.params.subscribe((url) => {
      this.activatedPath = url.id;
    });
  }

  ngOnInit() {
    this.doubleNavCheck = false;
    // check split pane
    this.menuSplitPaneInit();

    const path = this.page;
    if (
      this.page === 'aparate' ||
      this.page === 'cabinet' ||
      this.page === 'utilizatori'
    ) {
      this.isComparativ = true;
    } else {
      this.isComparativ = false;
    }
    if (path === 'utilizatori') {
      this.currentProgram = 'physician';
      this.locationForm.get('program').patchValue(this.programOptions[0].id);
    } else if (path === 'cabinet') {
      this.currentProgram = 'cabinet';
      this.locationForm.get('program').patchValue(this.programOptions[1].id);
    } else if (path === 'aparate') {
      this.currentProgram = 'equipment';
      this.locationForm.get('program').patchValue(this.programOptions[2].id);
    }

    this.selectedDate$ = this.calS.selectedDate.subscribe((e) => {
      if (e) {
        this.currDay = format(new Date(e), 'E', { locale: ro });
        this.currDate = format(new Date(e), 'd', { locale: ro });
      }
    });
    this.calS.eventCounts.subscribe((count) => {
      this.totalAppt = count;
    });
    this.calS.selectedMonth.subscribe((e) => {
      this.month = e.split(' ')[0];
    });

    if (path === 'list') {
      this.calendarList = EVENTLIST;
    }
    if (this.isComparativ) {
      this.handleFormChanges();
      this.handleSettingsChanges();
      this.calS.getLocations().subscribe((locations: any) => {
        const locs = locations ? locations : [];
        const mappedLocations = locs.map((loc) => ({
          id: loc.uid,
          label: loc.locationName,
        }));
        this.locationOptions = mappedLocations;
        const locV = this.calS.compareQuery$.value
          ? this.calS.compareQuery$.value.location
          : this.locationOptions[0].id;
        let p = '1';
        switch (this.currentProgram) {
          case 'equipment':
            p = '3';
            break;
          case 'cabinet':
            p = '2';
            break;
          case 'physician':
            p = '1';
            break;
          default:
            p = '1';
            break;
        }
        this.oldFormValues = {
          location: locV,
          program: p,
        };
        this.locationForm.patchValue({
          location: locV,
          program: p,
        });
      });
    }
    this.watchCalModal();
  }

  watchCalModal() {
    this.calS.showPicker.subscribe((e) => {
      this.showPicker = e;
    }
    );
  }

  handleSettingsChanges() {
    this.calS.compareQuery$
      .pipe(skip(1), distinctUntilChanged(distinctCheckObj))
      .subscribe((v) => {
        // nothing yet.
      });
  }
  handleFormChanges() {
    unsubscriberHelper(this.formChanges$);
    this.formChanges$ = this.locationForm.valueChanges
      .pipe(distinctUntilChanged(distinctCheckObj))
      .subscribe((vals: { location: any; program: any }) => {
        const program = vals.program;
        const locationV = vals.location;
        let route = '/calendar/aparate';
        let p = null;
        if (program === '1') {
          this.calS.filterProgram.next('physician');
          p = 'physician';
          route = '/calendar/utilizatori';
          this.programList = utilizatorList;
        } else if (program === '2') {
          this.calS.filterProgram.next('cabinet');
          p = 'cabinet';
          route = '/calendar/cabinet';
          this.programList = cabinetList;
        } else if (program === '3') {
          this.calS.filterProgram.next('equipment');
          p = 'equipment';
          route = '/calendar/aparate';
          this.programList = aparatList;
        } else {
          p = 'equipment';
          route = '/calendar/aparate';
          this.programList = aparatList;
        }
        if (locationV) {
          const obj = {
            location: locationV,
            program: p,
          };
          this.calS.compareQuery$.next(obj);
        }
        if (this.currentProgram !== p) {
          this.currentProgram = p;
          this.doubleNavCheck = false;
          if (this.oldFormValues.program !== vals.program) {
            // Revert selection if we navigate tot other path.
            // Used for back button navigation.
            this.locationForm
              .get('program')
              .patchValue(this.oldFormValues.program, {
                emitEvent: false,
                onlySelf: true,
              });
          }
          this.oldFormValues = { ...this.locationForm.getRawValue() };
          this.router.navigate([route]);
        } else {
          this.currentProgram = p;
          this.doubleNavCheck = true;
        }
      });
  }
  menuSplitPaneInit() {
    this.sideMenuS$ = this.sideMenuS
      .getIonSplitPaneVisibilityStatus()
      .subscribe((visibility: boolean) => {
        this.isSplitPaneVisible = visibility;
      });
  }

  ngAfterViewInit(): void {
    // enable menu => looks like its disabled
    this.menu.enable(true, 'appMainSideMenu');
  }

  toggleMenu(): void {
    this.menu.toggle('appMainSideMenu');
  }

  today(path: string) {
      this.router.navigate([path]);
      this.calS.selectedPath.next(path.split('/')[2]);
      const date = new Date().toString();
      this.calS.selectedDate.next(date);
  }

  segmentChanged(id) {}

  async openCalModal() {
    this.calS.showPicker.next(!this.showPicker);
  }

  ngOnDestroy(): void {
    this.selectedDate$.unsubscribe();
    unsubscriberHelper(this.sideMenuS$);
  }

  closeModal() {
    this.showPicker = false;
  }
  comparativRouteIsActive(url: string): boolean {
    const subLinks = ['aparate', 'utilizatori', 'cabinet'];
    if (
      subLinks.includes(url.split('/')[url.split('/').length - 1]) &&
      subLinks.includes(this.activatedPath)
    ) {
      return true;
    }
    return false;
  }
}
