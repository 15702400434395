import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMilliseconds, getDay, isEqual, parseISO, subMinutes } from 'date-fns';

@Pipe({
  name: 'transformBgForComparativeView',
  pure: true,
})
export class ComparativeBgPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    const d = value;
    const column = args[0];
    const holidays = args[1];
    return this.setBg(d, column, holidays);
  }

  public setBg(d, column, holidays) {
    const newDate = new Date(d);
    if (holidays?.length) {
      const valid = this.filterHolidays(holidays, d, column?.user);

      if (valid.length) {
        return 'holidays no-border';
      }
    }
    if (column.schedules?.length > 0) {
      const breakTime = column.schedules?.filter((e) => e.isBreakTime)[0];
      const allPrivate: Date[] = [];
      const allCnas: Date[] = [];
      const allBreak = breakTime ? [
        this.addTimeToDay(newDate, breakTime?.start),
        this.subtractMinutes(this.addTimeToDay(newDate, breakTime?.end)),
      ] : [];
      const dow = column.schedules.filter(
        (sc) => sc.dow === getDay(d)
      );
      dow.forEach((e) => {
        if (e.isPrivate && !e.isBreak) {
          allPrivate.push(
            ...[this.addTimeToDay(newDate, e?.start),
            this.subtractMinutes(this.addTimeToDay(newDate, e?.end))]
          );
        } else if (!e.isPrivate && !e.isBreak) {
          allCnas.push(
            ...[this.addTimeToDay(newDate, e?.start),
            this.subtractMinutes(this.addTimeToDay(newDate, e?.end))]
          );
        }
      });
      if (this.findAndCompareDate(allBreak, newDate)) {
        return '';
      } else if (this.findAndCompareDate(allPrivate, newDate)) {
        return 'schedule-private no-border';
      } else if (this.findAndCompareDate(allCnas, newDate)) {
        return 'schedule-normal no-border';
      }
    }
  }

  range(start, end, step = 1) {
    const output = [];
    if (typeof end === 'undefined') {
      end = start;
      start = 0;
    }
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  }

  subtractMinutes(date: Date, minutes: number = 1): Date {
    return subMinutes(date, minutes);
  }

  addTimeToDay(date: Date, time: string): Date {
    const [hours, minutes] = time.split(':');
    let newDate = new Date(date);
    newDate = new Date(newDate.setHours(parseInt(hours, 10)));
    newDate = new Date(newDate.setMinutes(parseInt(minutes, 10)));
    return newDate;
  }

  compareDate(date1: Date, date2: Date) {
    return isEqual(date1, date2);
  }

  findAndCompareDate(dates: Date[], date: Date): boolean {
    // sort dates
    dates.sort((a, b) => a.getTime() - b.getTime());
    // check if date is between dates
    for (let i = 0; i < dates.length; i += 2) {
      if (this.compareDate(date, dates[i]) || this.compareDate(date, dates[i + 1])) {
        return true;
      }
      if (date > dates[i] && date < dates[i + 1]) {
        return true;
      }
    }
    return false;
  }

  private filterHolidays(holidays, date, user) {
    // Holidays are based on physicianUID.
    // Prefiltering will be done based on the type of calendar being viewed.
    return holidays.filter((v) => {
      if (user.id !== v?.physicianUID) {
        return false;
      }
      const parsedS = parseISO(v.startDate);
      const parsedE = parseISO(v.endDate);
      const isSame =
        differenceInMilliseconds(date, parsedS) >= 0 &&
        differenceInMilliseconds(date, parsedE) <= 0;
      return isSame;
    });
  }
}
