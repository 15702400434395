import { NotificationService } from './../../../core/models/notification-service.model';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { User } from 'src/app/core/models';
import { Physician } from 'src/app/core/models/Physician.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PlatformService } from '@app/core/services/programmare/platform/platform.service';
import { SidemenuService } from 'src/app/core/services/sidemenu/sidemenu.service';
import { PhysiciansModalComponent } from '../modal/physicians-modal/physicians-modal.component';
import { RouterLinkActive, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DictionaryModel } from 'src/app/core/models/dictionary.model';
import { DictionaryService } from 'src/app/core/services/dictionary/dictionary.service';
import { PacientComponent } from '../modal/pacient/pacient.component';
import { filter, map, mergeMap } from 'rxjs/operators';
import { PermissionsService } from '@app/core/services/permissions/permissions.service';
import { CautaService } from '@app/core/services/cauta/cauta.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, AfterViewChecked {
  public currentVersion: string = require('../../../../assets/version.json')
    .version;
  isTablet = false;
  hideSideMenu = false;
  hideCoparative = false;
  isAdmin = false;
  loggedInUser: Physician;
  user: User;
  fullName = '-';
  dictionary: DictionaryModel;
  variant: string;

  public appPages = [];
  public comparative = [];
  public bottomNav: Array<{
    title: string;
    url: string;
    modalTitle?: string;
    icon: string;
    chevron: boolean;
    notify: boolean;
    display?: boolean;
    count?: number;
  }> = [];

  private logObservable: any;
  constructor(
    private platformS: PlatformService,
    private authS: AuthService,
    public modalController: ModalController,
    private sideMenuS: SidemenuService,
    private cdRef: ChangeDetectorRef,
    private dictionaryS: DictionaryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public permissionS: PermissionsService,
    private cautaService: CautaService,
    private menu: MenuController
  ) {
    this.getUserData();
    this.cdRef.markForCheck();
    this.isTablet = window.innerWidth >= 768 ? true : false;
    this.platformS.resizeListener$.subscribe((v) => {
      this.isTablet = window.innerWidth >= 768 ? true : false;
    });

    this.router.events.pipe(filter((e) => e instanceof NavigationEnd), map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe((data) => {
      if (data?.noMenu) {
        this.hideSideMenu = true;
      } else {
        this.hideSideMenu = false;
      }
    });
  }

  ngOnInit() {
    this.dictionaryS.variant$.subscribe((val) => {
      if (val) {
        this.variant = val;
      } else {
        this.variant = this.dictionaryS.getVariant();
      }
      this.dictionary = this.dictionaryS.getDictionary();
      this.initializeData();
    });
    this.cautaService.notifications$.subscribe((data: NotificationService) => {
      this.bottomNav.find((item) => item.icon === 'notificare').count = data.totalUnreadNonPushNotification;
    });
    this.getNotifications();
  }


  // this initializes the menu items. Each menu item has: title(which will be visible in the menu),
  // url (where the user will be redirected from that item) and the name of the icon
  initializeData() {
    this.appPages = [
      { title: 'Listă', url: '/calendar/lista', icon: '1-day' },
      { title: 'Zi', url: '/calendar/zi', icon: 'schedule' },
      {
        title: 'Zile lucrătoare',
        url: '/calendar/zile-lucratoare',
        icon: '3-days',
      },
      { title: 'Săptămână', url: '/calendar/saptamana', icon: '5-days' },
      { title: 'Lună', url: '/calendar/luna', icon: 'month' },
    ];

    this.comparative = [
      {
        title: this.dictionary?.profesii[this.variant],
        url: '/calendar/utilizatori',
        icon: 'users',
      },
      {
        title: this.dictionary?.sali[this.variant],
        url: '/calendar/cabinet',
        icon: 'cabinet',
      },
      {
        title: this.dictionary?.aparate[this.variant],
        url: '/calendar/aparate',
        icon: 'equipment',
      },
    ];
    // on some of the items, we added a display condition based on the permissions of the logged in user
    this.bottomNav = [
      { title: 'Caută', url: '/calendar/cauta', icon: 'search-custom', chevron: false, notify: false, display: true },
      {
        title: this.dictionary?.users[this.variant],
        url: '/calendar/pacienti',
        modalTitle: 'pacienti',
        icon: 'users',
        chevron: false,
        notify: false,
        display: this.permissionS.canViewPatients,
      },
      {
        title: 'Date statistice',
        url: '/data-statistice',
        icon: 'bar-chart',
        chevron: true,
        notify: false,
        display: true,
      },
      {
        title: 'Setări',
        url: '/calendar/setari',
        icon: 'settings-custom',
        chevron: false,
        notify: false,
        display: this.permissionS.hasMultiplePhysicians,
      },
      {
        title: 'Notificări',
        url: '/calendar/notificari',
        icon: 'notificare',
        chevron: false,
        notify: true,
        display: true,
        count: 0,
      },
    ];
  }

  ngAfterViewChecked(): void {
    this.getUserData();
    this.cdRef.detectChanges();
  }

  logout() {
    this.authS.logout();
  }

  toggleCoparative() {
    this.hideCoparative = !this.hideCoparative;
  }
  ionSplitPaneVisible(event: any) {
    this.sideMenuS.setSplitPaneVisible(event?.detail?.visible);
  }

  // this is used to fetch the list of physicians availavle to impersonate from 'Schimba' button
  async presentPhysiciansModal() {
    this.menu.close('appMainSideMenu');
    const modal = await this.modalController.create({
      component: PhysiciansModalComponent,
      cssClass: 'biz-modal-class',
      backdropDismiss: false,
      componentProps: {},
    });
    await modal.present();
    const d = await modal.onWillDismiss();
    const { dismissed, medicData, medicName } = d?.data;
    if (dismissed && medicData) {
      this.authS.loggedInPhysician = medicData;
      this.logObservable = this.authS.loggedInPhysicianSubject.asObservable();
      this.logObservable.subscribe((value) => {
        this.loggedInUser = value;
        this.fullName =
          this.loggedInUser.firstName + ' ' + this.loggedInUser.lastName;
      });
      this.cdRef.markForCheck();
    }
    if (dismissed) {
      this.menu.open('appMainSideMenu');
    }
  }

  // this gets info about the logged in user
  getUserData() {
    this.loggedInUser = JSON.parse(localStorage.getItem('loggedInPhysician'));
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.loggedInUser) {
      this.fullName =
        this.loggedInUser.firstName + ' ' + this.loggedInUser.lastName;
    }
    this.authS.hasMultiplePhysiciansSubject.subscribe(
      (val) => (this.isAdmin = val)
    );
  }

  samePageReset(active) {
    if (active instanceof RouterLinkActive && active.isActive) {
      this.sideMenuS.samePageNavigation$.next(true);
    }
  }

  async presentPacient() {
    const modal = await this.modalController.create({
      component: PacientComponent,
      cssClass: 'biz-modal-class',
      backdropDismiss: false,
      componentProps: {
        menu: true,
      },
    });
    await modal.present();
    const d = await modal.onWillDismiss();
  }

  openModal(modalTitle: string = '') {
    if (modalTitle === 'pacienti') {
      this.presentPacient();
    }
  }

  // this will set the count value that is shown near Notificari
  getNotifications() {
    this.cautaService.getNotifications().subscribe((data: NotificationService) => {
      this.bottomNav.find((item) => item.icon === 'notificare').count = data.totalUnreadNonPushNotification;
    });
  }
}
