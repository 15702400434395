import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';
import { dictionary } from '../../configs/endpoints';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DictionaryModel } from '../../models/dictionary.model';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  variant$ = new BehaviorSubject(null);

  constructor(private reqS: RequestService) {}

  public setVariant(variant = 'variant_1'): void {
    localStorage.setItem('variant', JSON.stringify(variant));
    this.variant$.next(variant);
  }

  public getVariant() {
    return JSON.parse(localStorage.getItem('variant'));
  }

  public setDictionary(): Observable<unknown> {
    return this.reqS.get(dictionary.getDictionary).pipe(
      map((data) => {
        localStorage.setItem('dictionary', JSON.stringify(data));
        return;
      })
    );
  }

  public getDictionary(): DictionaryModel {
    return JSON.parse(localStorage.getItem('dictionary'));
  }
  get variant() {
    return this.getVariant();
  }
  get profesie() {
    return this.getDictionary()?.profesie[this.variant];
  }
  get user() {
    return this.getDictionary()?.user[this.variant];
  }
}
