import { addMinutes, subMinutes } from 'date-fns';
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NoteService } from '@app/core/services/notes/note.service';
import { IonContent } from '@ionic/angular';
import {
  CalendarDateFormatter,
  CalendarDayViewBeforeRenderEvent,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent,
} from 'angular-calendar';
import { WeekDay } from 'calendar-utils';
import {
  differenceInMilliseconds,
  endOfDay,
  getDay,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isWithinInterval,
  parseISO,
  setHours,
  setMinutes,
  startOfDay,
} from 'date-fns';
import { get, uniq } from 'lodash';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { WEEK_START_DAY } from './../../../app.module';
import { unsubscriberHelper } from './../../../core/helpers/unsubscriber.helper';
import { Appointment } from './../../../core/models/appointment.interface';
import { CalendarService } from './../../../core/services/calendar/calendar.service';
import { CalendarPages } from './calendarPages';
import { CustomDateFormatter } from './custom-date-formatter.provider';
// import Swiper core and required modules
import SwiperCore, {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { CalendarDataService } from '@app/core/services/calendar/calendar-data.service';
import { PermissionsService } from '@app/core/services/permissions/permissions.service';
import { isEqual } from 'date-fns/esm';

// install Swiper modules
SwiperCore.use([Virtual, Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isTablet = false;
  @Input() calendarList;
  @Input() parentContentRef: IonContent;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Input() display;
  @Output() dayHeaderClicked = new EventEmitter<{
    day: WeekDay;
    sourceEvent: MouseEvent;
  }>();
  @ViewChild('swiperMonth', { static: false }) swiperMonth?: SwiperComponent;
  @ViewChild('swiperWeek', { static: false }) swiperWeek?: SwiperComponent;
  @ViewChild('swiperDay', { static: false }) swiperDay?: SwiperComponent;
  weekStartsOn = WEEK_START_DAY;
  public calendarPages = CalendarPages;
  activatedPath = '';
  activatedPage = '';
  view: CalendarView = CalendarView.Day;
  CalendarView = CalendarView;
  events: CalendarEvent[] = [];
  appointments$: BehaviorSubject<Array<Appointment>> = new BehaviorSubject([]);
  excludeDays: number[] = [0, 6];
  dayCSubs$: Subscription;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  pageS$: Subscription;
  monthsSub$: Subscription;
  daysSub$: Subscription;
  weeksSub$: Subscription;
  modalClickSub$: Subscription;
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  refresh: Subject<any> = new Subject();
  firstLoad = true;
  events$: Observable<CalendarEvent[]>;
  activeDayIsOpen = true;
  eventSource;
  viewTitle;
  isToday: boolean;
  startEndTime: any = {};
  schedules = [];
  holidays = [];
  aSubs$: Subscription;
  months: Date[] = [];
  weeks: Date[] = [];
  days: Date[] = [];
  viewDate: Date = new Date();

  constructor(
    route: ActivatedRoute,
    private router: Router,
    public calS: CalendarService,
    public calDataS: CalendarDataService,
    private vNgZone: NgZone,
    protected cdr: ChangeDetectorRef,
    private noteS: NoteService,
    private permissionsS: PermissionsService
  ) {
    this.activatedPath = '/' + route.snapshot.paramMap.get('id');
    this.activatedPage = route.snapshot.paramMap.get('id');
    this.startEndTime = JSON.parse(localStorage.getItem('workHours'));
    this.getEventLists();
    this.refresh.next();
  }
  handlePageSubs() {
    unsubscriberHelper(this.pageS$);
    unsubscriberHelper(this.monthsSub$);
    unsubscriberHelper(this.daysSub$);
    unsubscriberHelper(this.weeksSub$);
    this.pageS$ = this.calS.selectedPath.subscribe((p) => {
      this.setDisplay(p);
    });
    this.calDataS
      .PrepareDays(this.viewDate)
      .pipe(take(1))
      .subscribe((days) => {
        if (this.view === CalendarView.Day) {
          this.days = days;
          this.cdr.detectChanges();
        }
      });
    this.calDataS
      .prepareWeeks(this.viewDate)
      .pipe(take(1))
      .subscribe((weeks) => {
        if (this.view === CalendarView.Week) {
          this.weeks = weeks;
          this.cdr.detectChanges();
        }
      });
    this.calDataS
      .prepareMonths(this.viewDate)
      .pipe(take(1))
      .subscribe((months) => {
        if (this.view === CalendarView.Month) {
          this.months = months;
          this.cdr.detectChanges();
        }
      });
  }

  ngOnInit() {}

  //set calendar mode based on the calendar type selection from menu
  setDisplay(d) {
    if (d === 'zi') {
      this.setView(this.CalendarView.Day);
    } else if (d === 'zile-lucratoare') {
      this.excludeDays = [0, 6];
      this.setView(this.CalendarView.Week);
    } else if (d === 'saptamana') {
      this.excludeDays = [];
      this.setView(this.CalendarView.Week);
    } else if (d === 'luna') {
      this.setView(this.CalendarView.Month);
    }
  }

  ngAfterViewInit(): void {
    this.getEventLists();
    this.handlePageSubs();
    this.refresh.next();
    this.slideToDate();
    this.firstLoad = false;
    this.watchForCalModalClick();
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  watchForCalModalClick() {
    unsubscriberHelper(this.modalClickSub$);
    this.modalClickSub$ = this.calDataS.modalClickedDate$.subscribe((val) => {
      if (val.date && val.clearState === 'before') {
        this.viewDate = val.date;
        this.slideToDate(val.date);
        this.calDataS.modalClickedDate$.next({
          date: null,
          clearState: 'before',
        });
      } else if (val.date && val.clearState === 'after') {
        this.viewDate = val.date;
        this.slideToDate(val.date);
      }
    });
  }

  ngOnDestroy(): void {
    if (
      this.calDataS.modalClickedDate$.getValue().date &&
      this.calDataS.modalClickedDate$.getValue().clearState === 'after'
    ) {
      this.calDataS.modalClickedDate$.next({ date: null, clearState: 'after' });
    }
    unsubscriberHelper(this.aSubs$);
    unsubscriberHelper(this.dayCSubs$);
    unsubscriberHelper(this.pageS$);
    if (this.calS.intervalSourceSub$) {
      this.calS.intervalSourceSub$.unsubscribe();
    }
  }

  slideToDate(date = this.viewDate) {
    if (this.view === CalendarView.Month) {
      this.swiperMonth?.swiperRef.slideTo(this.getTodayDateSlideIndex(date), 0);
    } else if (this.view === CalendarView.Week) {
      this.swiperWeek?.swiperRef.slideTo(this.getTodayDateSlideIndex(date), 0);
    } else if (this.view === CalendarView.Day) {
      this.swiperDay?.swiperRef.slideTo(this.getTodayDateSlideIndex(date), 0);
    }
  }

  getTodayDateSlideIndex(date) {
    if (this.view === CalendarView.Day) {
      return this.days.findIndex((d) => isSameDay(d, date));
    } else if (this.view === CalendarView.Week) {
      return this.weeks.findIndex((d) => isSameWeek(d, date));
    } else if (this.view === CalendarView.Month) {
      return this.months.findIndex((d) => isSameMonth(d, date));
    }
  }

  onSwiper(swiper) {}

  //get selected date after swipe
  onSlideChange(e) {
    if (
      this.view === 'week' &&
      this.weeks[this.swiperWeek.swiperRef.activeIndex]
    ) {
      this.viewDate = this.weeks[this.swiperWeek.swiperRef.activeIndex];
      return this.calS.selectedDate.next(
        this.weeks[this.swiperWeek.swiperRef.activeIndex].toString()
      );
    }
    if (
      this.view === 'day' &&
      this.days[this.swiperDay.swiperRef.activeIndex]
    ) {
      this.viewDate = this.days[this.swiperDay.swiperRef.activeIndex];
      return this.calS.selectedDate.next(
        this.days[this.swiperDay.swiperRef.activeIndex].toString()
      );
    }
    if (
      this.view === CalendarView.Month &&
      this.months[this.swiperMonth.swiperRef.activeIndex]
    ) {
      this.viewDate = this.months[this.swiperMonth.swiperRef.activeIndex];
      return this.calS.selectedDate.next(
        this.months[this.swiperMonth.swiperRef.activeIndex].toString()
      );
    }
  }

  updateSwiperConfig() {
    this.vNgZone.runOutsideAngular(() => {
      if (this.view === CalendarView.Day) {
        this.swiperDay.swiperRef.update();
      }
      if (this.view === CalendarView.Week) {
        this.swiperWeek.swiperRef.update();
      }
      if (this.view === CalendarView.Month) {
        this.swiperMonth.swiperRef.update();
      }
    });
  }

  //build events list for calendar
  getEventLists() {
    unsubscriberHelper(this.aSubs$);
    this.aSubs$ = this.calS.appointments$
      .pipe(
        switchMap((val) => {
          this.noteS.getNoteTypes().pipe(take(1)).subscribe();
          return of(val);
        })
      )
      .subscribe((e) => {
        this.schedules = e?.schedules ? e?.schedules : [];
        this.holidays = e?.phyFreeDays ? e?.phyFreeDays : [];
        const appS = [];
        this.calS.arrUniqueByKey(e?.appointments || [], appS, 'uid');
        this.appointments$.next(appS);
        this.events = appS.map((d) => {
          // use different fields for observation for appointments and notes
          const obs = d?.isNote
            ? d?.comment
              ? d.comment
              : ''
            : d?.infPacient
            ? d.infPacient
            : '';
          const eqN = d.equipmentNames?.length
            ? [d.equipmentNames.join(' , ')]
            : [];
           // use different fields for services based on services type
          const sN =
            d.paymentTypeID === 1
              ? d.serviceNames?.length
                ? [d.serviceNames.join(' , ')]
                : []
              : d.cnasMedicalServices?.length
              ? [
                  d.cnasMedicalServices
                    .map((v: any) => v?.description || '')
                    .filter((v) => v !== '')
                    .join(' , '),
                ]
              : [];
          const name = d.groupName ? d.groupName : d.personName;
          return {
            start: new Date(d.startTime),
            end: new Date(d.endTime),
            title: uniq(
              [name]
                .concat(sN)
                .concat(eqN)
                .concat([obs])
                .filter((v) => v)
            ).join(d.isNote ? ' ' : ' • '),
            color: {
              primary: '',
              secondary: '',
            },
            actions: this.actions,
            allDay: false,
            isNote: d.isNote,
            uid: d.uid,
            draggable: false,
            meta: {
              cssClass: this.calS.colorCode(d.colorCode, 'full-bg'),
              icons: d?.icons.map((ic) => this.calS.iconCode(ic)),
            },
          };
        });
        if (this.view === CalendarView.Day && this.viewDate) {
          // removed this as it was causing the calendar events count to be off and it's also not neccesary
          // this.processDayCount(this.viewDate);
        }
        this.refresh.next();
      });
  }

  // get note type based on typeID
  constructNoteInfo(noteTypeID) {
    const noteType = this.noteS.noteTypes$
      .getValue()
      .find((e: any) => e.noteTypeID === noteTypeID);
    return noteType ? noteType : null;
  }

  navigate(path) {
    this.router.navigate(['/home' + path]);
  }
  dayHeaderClickedEv(data) {
    this.calDataS.modalClickedDate$.next({
      date: data.day.date,
      clearState: 'after',
    });
    this.router.navigate(['/calendar/zi']);
  }

  processDayCount(date) {
    const startDate = startOfDay(date);
    const endDate = endOfDay(date);
    unsubscriberHelper(this.dayCSubs$);
    this.dayCSubs$ = this.appointments$
      .pipe(filter((v) => this.view === this.CalendarView.Day))
      .subscribe((vals) => {
        let numV = this.calS.eventCounts.value;
        if (date) {
          numV = vals.filter((ev) => {
            const stt = new Date(ev.startTime);
            const ett = new Date(ev.endTime);
            return (
              (this.calS.isBetween(stt, startDate, endDate) ||
                this.calS.isBetween(ett, startDate, endDate) ||
                (differenceInMilliseconds(stt, startDate) <= 0 &&
                  differenceInMilliseconds(ett, endDate) >= 0)) && ev.isNote === false
            );
          }).length;
          this.calS.eventCounts.next(numV);
        } else {
          this.calS.eventCounts.next(vals.length);
        }
      });
  }

  // angular calendar
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.calDataS.modalClickedDate$.next({ date, clearState: 'after' });
    this.router.navigate(['/calendar/zi']);
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    switch (action) {
      case 'week':
      case 'day':
        const isNote = get(event, 'isNote', false);
        const uid = get(event, 'uid', false);
        if (uid) {
          if (isNote && this.permissionsS.canViewNotes) {
            this.router.navigate(['/calendar', 'vizualizare-nota', uid]);
          } else {
            if (this.permissionsS.canViewAppointments) {
              this.router.navigate([
                '/calendar',
                'vizualizare-programare',
                uid,
              ]);
            }
          }
        }
        break;
      default:
        this.viewDate = new Date(event.start);
        this.view = CalendarView.Day;
        this.modalData = { event, action };
        break;
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      this.holidays.forEach((hol) => {
        const isSame =
          isSameDay(new Date(hol.startDate), new Date(day.date)) ||
          isSameDay(new Date(hol.endDate), new Date(day.date));
        if (isSame) {
          day.cssClass = 'holidays';
        }
      });
    });
  }
  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    renderEvent.hourColumns.forEach((hourColumn) => {
      const dow = this.schedules.filter(
        (sc) => sc.dow === getDay(hourColumn.date)
      );
      const breakTime = dow?.filter((e) => e.isBreakTime)[0];
      const allPrivate: Date[] = [];
      const allCnas: Date[] = [];
      const allBreak = breakTime ? [
        this.addTimeToDay(hourColumn.date, breakTime?.start),
        this.subtractMinutes(this.addTimeToDay(hourColumn.date, breakTime?.end)),
      ] : [];
      dow.forEach((e) => {
        if (e?.isPrivate === true && e?.isBreakTime === false) {
          allPrivate.push(
            ...[this.addTimeToDay(hourColumn.date, e?.start),
            this.subtractMinutes(this.addTimeToDay(hourColumn.date, e?.end))]
          );
        } else if (e?.isPrivate === false && e?.isBreakTime === false) {
          allCnas.push(
            ...[this.addTimeToDay(hourColumn.date, e?.start),
            this.subtractMinutes(this.addTimeToDay(hourColumn.date, e?.end))]
          );
        }
      });

      dow.forEach((day) => {
        hourColumn.hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
            const valid = this.filterHolidays(this.holidays, segment.date);
            const isSame = valid.length > 0;
            if (isSame) {
              segment.cssClass = 'holidays sg-day';
            } else {
              if (this.findAndCompareDate(allBreak, segment.date)) {
                segment.cssClass = '';
              } else if (this.findAndCompareDate(allPrivate, segment.date)) {
                segment.cssClass = 'schedule-private no-border';
              } else {
                if (this.findAndCompareDate(allCnas, segment.date)) {
                  segment.cssClass = 'schedule-normal no-border';
                }
              }
            }
          });
        });
      });
    });
    renderEvent.header.forEach((head) => {
      renderEvent.hourColumns.forEach((hourColumn) => {
        hourColumn.hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
            const valid = this.filterHolidays(this.holidays, segment.date);
            const isSame = valid.length > 0;
            if (isSame) {
              segment.cssClass = 'holidays sg-header';
            }
          });
        });
      });
    });
  }
  beforeDayViewRender(renderEvent: CalendarDayViewBeforeRenderEvent) {
    renderEvent.hourColumns.forEach((hourColumn) => {
      const dow = this.schedules.filter(
        (sc) => sc.dow === getDay(hourColumn.date)
      );
      const breakTime = dow?.filter((e) => e?.isBreakTime === true)[0];
      const allPrivate: Date[] = [];
      const allCnas: Date[] = [];
      const allBreak = breakTime ? [
        this.addTimeToDay(hourColumn.date, breakTime?.start),
        this.subtractMinutes(this.addTimeToDay(hourColumn.date, breakTime?.end)),
      ] : [];
      dow.forEach((e) => {
        if (e?.isPrivate === true && e?.isBreakTime === false) {
          allPrivate.push(
            ...[this.addTimeToDay(hourColumn.date, e?.start),
              this.subtractMinutes(this.addTimeToDay(hourColumn.date, e?.end))]
          );
        } else if (e?.isPrivate === false && e?.isBreakTime === false) {
          allCnas.push(
            ...[this.addTimeToDay(hourColumn.date, e?.start),
              this.subtractMinutes(this.addTimeToDay(hourColumn.date, e?.end))]
          );
        }
      });
      dow.forEach((day) => {
        hourColumn.hours.forEach((hour) => {
          hour.segments.forEach((segment) => {
            const valid = this.filterHolidays(this.holidays, segment.date);
            const isSame = valid.length > 0;
            if (isSame) {
              segment.cssClass = 'holidays sg-day2';
            } else {
              if (this.findAndCompareDate(allBreak, segment.date)) {
                segment.cssClass = '';
              } else if (this.findAndCompareDate(allPrivate, segment.date)) {
                segment.cssClass = 'schedule-private no-border';
              } else {
                if (this.findAndCompareDate(allCnas, segment.date)) {
                  segment.cssClass = 'schedule-normal no-border';
                }
              }
            }
          });
        });
      });
    });
  }

  subtractMinutes(date: Date, minutes: number = 1): Date {
    return subMinutes(date, minutes);
  }

  addTimeToDay(date: Date, time: string): Date {
    const [hours, minutes] = time.split(':');
    let newDate = new Date(date);
    newDate = new Date(newDate.setHours(parseInt(hours, 10)));
    newDate = new Date(newDate.setMinutes(parseInt(minutes, 10)));
    return newDate;
  }

  compareDate(date1: Date, date2: Date) {
    return isEqual(date1, date2);
  }

  findAndCompareDate(dates: Date[], date: Date): boolean {
    // sort dates
    dates.sort((a, b) => a.getTime() - b.getTime());
    // check if date is between dates
    for (let i = 0; i < dates.length; i += 2) {
      if (this.compareDate(date, dates[i]) || this.compareDate(date, dates[i + 1])) {
        return true;
      }
      if (date > dates[i] && date < dates[i + 1]) {
        return true;
      }
    }
    return false;
  }

  range(start, end, step = 1) {
    const output = [];
    if (typeof end === 'undefined') {
      end = start;
      start = 0;
    }
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  }

  hourSegmentClicked( { date }: { date: Date } ) {
    this.calS.userDidSelectHourSegment$.next({
      date,
      clickedTime: new Date(),
      isHoliday: this.filterHolidays(this.holidays, date)?.length > 0,
      locationUID:
        this.calS.getSchedulesAtSpecificHour(date)?.[0]?.locationUID || '',
    } );
  }

  // count events for each day on month view
  getCountsForMonthsView(events: any[]) {
    return events.filter(e => !e.isNote).length;
  }

  private filterHolidays(holidays, date) {
    // Holidays are based on physicianUID.
    // Prefiltering will be done based on the type of calendar being viewed.
    return holidays.filter((v) => {
      const parsedS = parseISO(v.startDate);
      const parsedE = parseISO(v.endDate);
      const isSame =
        differenceInMilliseconds(date, parsedS) >= 0 &&
        differenceInMilliseconds(date, parsedE) <= 0;
      return isSame;
    });
  }
  get loadingState() {
    return this.calS.appointments$ ? true : false;
  }
}
