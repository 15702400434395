import { environment } from './../../../environments/environment';

export const serverBaseUrl = environment.serverUrl;

export const baseEndpoints = {
  bizCalendarService: serverBaseUrl + '/BizCalendarService',
};

export const authEndpoints = {
  auth: serverBaseUrl + '/Users/authenticate',
  getUsers: serverBaseUrl + '/Users',
  getParameters: baseEndpoints.bizCalendarService + '/GetParameters',
  getUserRights: baseEndpoints.bizCalendarService + '/GetUserRights',
  getVersion: baseEndpoints.bizCalendarService + '/GetVersion',
};
export const appointmentEndpoints = {
  addApointment: baseEndpoints.bizCalendarService + '/AddNewAppointment',
  addApointmentV2: baseEndpoints.bizCalendarService + '/v2/AddNewAppointment',
  getAppointment: serverBaseUrl + '/BizCalendarService/GetAppointments',
  getUserPhysicians: serverBaseUrl + '/BizCalendarService/GetUsersPhysicians',
  addAppointment: serverBaseUrl + '/BizCalendarService/AddNewAppointment',
  checkAppointmentVsSchedule:
    baseEndpoints.bizCalendarService + '/CheckAppointmentVsSchedule',
  getAppointmentDetails:
    baseEndpoints.bizCalendarService + '/GetAppointmentDetails',
  deleteAppointment: baseEndpoints.bizCalendarService + '/DeletedAppointment',
  requestConsultationGeneration:
    baseEndpoints.bizCalendarService + '/RequestConsultationGeneration',
  updateAppointment: baseEndpoints.bizCalendarService + '/UpdateAppointment',
  updateAppointmentV2: baseEndpoints.bizCalendarService + '/v2/UpdateAppointment',
  checkRecurrenceAvailability:
    baseEndpoints.bizCalendarService + '/CheckRecurrenceAvailability',
};

export const persons = {
  addPerson: baseEndpoints.bizCalendarService + '/AddNewPerson',
  getPersons: baseEndpoints.bizCalendarService + '/GetPersons',
  updatePerson: baseEndpoints.bizCalendarService + '/UpdatePerson',
  GetPersonsGroupDetails: baseEndpoints.bizCalendarService + '/GetPersonsGroupDetails',
};

export const location = {
  getCountries: baseEndpoints.bizCalendarService + '/GetCounties',
  getCities: baseEndpoints.bizCalendarService + '/GetCities',
  getLocations: baseEndpoints.bizCalendarService + '/GetLocations',
};

export const cabinet = {
  getCabinets: baseEndpoints.bizCalendarService + '/GetCabinets',
  getCabinetsSchedules:
    baseEndpoints.bizCalendarService + '/GetCabinetsSchedules',
};

export const tipServiciiEndpoint = {
  getMedicalServices: baseEndpoints.bizCalendarService + '/GetMedicalServices',
  getClinicCNASMedicalServices:
    baseEndpoints.bizCalendarService + '/GetClinicCNASMedicalServices',
};
export const notes = {
  addNote: baseEndpoints.bizCalendarService + '/AddNote',
  getNote: baseEndpoints.bizCalendarService + '/GetAppointmentDetails',
  getNoteTypes: baseEndpoints.bizCalendarService + '/GetNoteTypes',
  updateNote: baseEndpoints.bizCalendarService + '/UpdateNote',
  deleteNote: baseEndpoints.bizCalendarService + '/DeleteNote',
};

export const group = {
  getGroups: baseEndpoints.bizCalendarService + '/GetGroups',
  createGroup: baseEndpoints.bizCalendarService + '/CreateGroup',
  updateGroup: baseEndpoints.bizCalendarService + '/UpdateGroup',
  addMembersToGroup: baseEndpoints.bizCalendarService + '/AddMembersToGroup',
  DeleteGroup: baseEndpoints.bizCalendarService + '/DeleteGroup',
};

export const physicians = {
  getPhysicians: baseEndpoints.bizCalendarService + '/GetPhysicians',
  getThirdPartyPhysicians:
    baseEndpoints.bizCalendarService + '/GetThirdPartyPhysicians',
  getExternalPhysiciansNoCNAS:
    baseEndpoints.bizCalendarService + '/GetExternalPhysiciansNoCNAS',
  getPhysicianSchedule:
    baseEndpoints.bizCalendarService + '/GetPhysicianSchedule',
  getPhysiciansScheduleAndFreeDays:
    baseEndpoints.bizCalendarService + '/GetPhysiciansScheduleAndFreeDays',
};

export const medicalSpecialities = {
  getMedicalSpecialities:
    baseEndpoints.bizCalendarService + '/GetMedicalSpecialities',
  getAllMedicalSpecialities:
    baseEndpoints.bizCalendarService + '/GetAllMedicalSpecialities',
};

export const equipments = {
  getMedicalEquipment:
    baseEndpoints.bizCalendarService + '/GetMedicalEquipment',
  checkMedicalEquipmentAvailability:
    baseEndpoints.bizCalendarService + '/CheckMedicalEquipmentAvailability',
};

export const info = {
  getPersonInfo: baseEndpoints.bizCalendarService + '/GetPersonInfo',
};

export const promotions = {
  getPromotionChannels:
    baseEndpoints.bizCalendarService + '/GetPromotionChannels',
};

export const settings = {
  updateSettings: baseEndpoints.bizCalendarService + '/SendParameters',
};

export const statistics = {
  getReportMonthTotalAppointments:
    baseEndpoints.bizCalendarService + '/ReportMonthTotalAppointments',
  ReportTotalAppointments:
    baseEndpoints.bizCalendarService + '/ReportTotalAppointments',
  ReportIncomeEvolution:
    baseEndpoints.bizCalendarService + '/ReportIncomeEvolution',
  ReportIncomeEvolution2:
    baseEndpoints.bizCalendarService + '/ReportIncomeEvolution2',
  ReportCnasVsPrivateServices:
    baseEndpoints.bizCalendarService + '/ReportCnasVsPrivateServices',
  ReportCnasVsPrivateServices2:
    baseEndpoints.bizCalendarService + '/ReportCnasVsPrivateServices2',
  ReportActiveVsCanceledAppointments:
    baseEndpoints.bizCalendarService + '/ReportActiveVsCanceledAppointments',
  ReportActiveVsCanceledAppointments2:
    baseEndpoints.bizCalendarService + '/ReportActiveVsCanceledAppointments2',
  ReportAppointmentsVsConsultations:
    baseEndpoints.bizCalendarService + '/ReportAppointmentsVsConsultations',
  ReportAppointmentsVsConsultations2:
    baseEndpoints.bizCalendarService + '/ReportAppointmentsVsConsultations2',
  ReportOldVsNewPersons: baseEndpoints.bizCalendarService + '/ReportOldVsNewPersons',
  ReportAppointmentsNewPersonsVsOldPersons: baseEndpoints.bizCalendarService + '/ReportAppointmentsNewPersonsVsOldPersons',
  ReportAppointmentsNewPersonsVsOldPersons2: baseEndpoints.bizCalendarService + '/ReportAppointmentsNewPersonsVsOldPersons2'
};

export const dictionary = {
  getDictionary: baseEndpoints.bizCalendarService + '/GetDictionary',
};
export const checkExisting = {
  pacientByNumber:
    baseEndpoints.bizCalendarService + '/CheckExistingPacientByNumber?phone=',
  pacientByCNP:
    baseEndpoints.bizCalendarService + '/CheckExistingPacientByCNP?cnp=',
};

export const cauta = {
  SearchCalendar: baseEndpoints.bizCalendarService + '/SearchCalendar',
};

export const paymentService = {
  GetPersonCompanies: baseEndpoints.bizCalendarService + '/GetPersonCompanies',
  GetPersonPromotionPackages: baseEndpoints.bizCalendarService + '/GetPersonPromotionPackages',
};

export const questionnaireService = {
  GetQuestionnaires: baseEndpoints.bizCalendarService + '/GetQuestionnaires',
  GetQuestionnaireURL: baseEndpoints.bizCalendarService + '/GetQuestionnaireURL',
};

export const notificationsEndpoint = {
  getNotifications: baseEndpoints.bizCalendarService + '/GetUserNotification',
  readNotification: baseEndpoints.bizCalendarService + '/SetNotificationAsRead',

};
