import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { unsubscriberHelper } from '@app/core/helpers/unsubscriber.helper';
import { IonInputConfig } from '@app/shared/models/components/ion-input-config';
import { NavController, ModalController } from '@ionic/angular';
import { of, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, first, tap } from 'rxjs/operators';
import { QuestionnaireeService } from '../../../../core/services/questionnaire/questionnaire.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { PlatformService } from '@app/core/services/programmare/platform/platform.service';
import { ToastService } from '../../../../core/services/toast/toast.service';

@Component({
  selector: 'app-questionnaire-modal',
  templateUrl: './questionnaire-modal.component.html',
  styleUrls: ['./questionnaire-modal.component.scss']
})
export class QuestionnaireModalComponent implements OnInit {
  @Input() set questionnaires(v: any[]) {
    this.list$.next(v);
    this.questionnairesList = v;
  }
  @Input() appointment: any = null;
  questionnairesList: any[] = [];
  searchForm: FormGroup = this.fb.group({
    keyword: ['', [Validators.required]],
  });
  isSearching = false;
  config: IonInputConfig = {
    placeholder: 'Căutare chestionar',
    type: 'search',
    inputMode: 'search',
    size: 100,
    /* inputLabel: this.label, */
    clearable: true,
    inputClasses: '',
    minLength: 2,
    maxLength: 100,
    bgwhite: true,
    disabled: false,
    removeInputItemBaseLine: true,
    isInputFocused: false,
    debounce: 500,
    hidAssistiveText: true,
  };
  list$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  constructor(
    private fb: FormBuilder,
    public modalController: ModalController,
    private questionnaireeService: QuestionnaireeService,
    private iab: InAppBrowser,
    private platformService: PlatformService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.searchForm.valueChanges
      .pipe(distinctUntilChanged()) // makes sure the value has actually changed.
      .subscribe(
        (data) => {
          this.isSearching = true;
          if (data.keyword !== '') {
            this.list$.next([
              ...(this.searching(data.keyword.replace(/\s/g, '')) || []),
            ]);
          } else {
            this.list$.next(this.questionnaires);
          }
        },
        err => { },
        () => {
          this.isSearching = false;
        }
      );
  }

  goBack() {
    this.modalController.dismiss();
  }

  searching(st: string): any[] {
    return this.questionnairesList.filter(
      (v: any) =>
        v.name
          .replace(/\s/g, '')
          .toLowerCase()
          .includes(st.toLowerCase()) ||
        v.shortDescription
          .replace(/\s/g, '')
          .toLowerCase()
          .includes(st.toLowerCase()) ||
        st
          .toLowerCase()
          .includes(v.shortDescription.replace(/\s/g, '').toLowerCase()) ||
        st
          .toLowerCase()
          .includes(v.name.replace(/\s/g, '').toLowerCase())
    );
  }

  openQuestionnaire(q) {
    this.questionnaireeService.getQuestionnaireUrl(q.id, this.appointment.personUID, this.appointment.consultationID)
      .pipe(
        tap((v) => v))
      .subscribe(
        (res: any) => {
          if (this.platformService.isWeb$.getValue()) {
            this.openIAB(res, 'self');
          } else {
            this.openIAB(res, '_blank');
          }
        }
      );
  }

  openIAB(url, type: '_blank' | 'self' = 'self') {
    const options =
      `location=no,footer=yes,hardwareback=no,hidenavigationbuttons=yes,clearcache=yes,
      clearsessioncache=yes,toolbar=yes,closebuttoncaption=Close,toolbarposition=top`;
    const browser = this.iab.create(url, type, options);
    browser.show();
    if (browser) {
      const sub1 = browser.on('loadstart').subscribe((e) => {
        browser.insertCSS({ code: '.header__cancel{ display: none;}' });
        // TODO: any other action required
      });
      const sub2 = browser.on('loadstop').subscribe((e) => {
        browser.insertCSS({ code: '.header__cancel{ display: none;}' });
      });
      const sub3 = browser.on('loaderror').subscribe((e) => {
        browser.close();
        this.toastService.presentToastWithDurationDismiss('Eroare la încărcarea chestionarului', 'error');
      });
      browser
        .on('exit')
        .pipe(first())
        .subscribe((e) => {
          unsubscriberHelper(sub1);
          unsubscriberHelper(sub2);
          unsubscriberHelper(sub3);
        });
    }
  }
}
