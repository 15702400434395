/* eslint-disable @typescript-eslint/prefer-for-of */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { RequestService } from 'src/app/core/services/request/request.service';
import { inputConfigHelper } from 'src/app/shared/data/input-config-helper';
import { IonRadioInputOption } from 'src/app/shared/models/components/ion-radio-input-option';
import { IonRadiosConfig } from 'src/app/shared/models/components/ion-radios-config';
import { IonSelectConfig } from 'src/app/shared/models/components/ion-select-config';
import { get, isArray } from 'lodash';
import {
  persons,
  location,
  promotions,
  checkExisting,
} from 'src/app/core/configs/endpoints';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import {
  catchError,
  concatAll,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { formatRFC3339 } from 'date-fns';
import { GenderEnum } from 'src/app/core/enum/gender.enum';
import { LoadingControllerService } from '@app/core/services/loadingController/loading-controller.service';
import {
  cnpValidator,
  getDateFromCPN,
  getGenderFromCPN,
} from '@app/core/helpers/cpnValidator';
import { checkIfEmpty } from '@app/core/helpers/checkIfEmpty.helper';
import { Person } from '@app/core/models/person.model';
import { CabinetNotifyComponent } from '../cabinet-notify/cabinet-notify.component';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { DictionaryService } from '@app/core/services/dictionary/dictionary.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { Parameter } from '@app/core/models/parameter.model';
import libphonenumber from 'google-libphonenumber';
import {
  getPhoneDetails,
  getSelectedCountryEnum,
} from '@app/core/helpers/phoneNumber.helper';
import { PermissionsService } from '@app/core/services/permissions/permissions.service';

@Component({
  selector: 'app-new-pacient-modal',
  templateUrl: './new-pacient-modal.component.html',
  styleUrls: ['./new-pacient-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPacientModalComponent implements OnInit, OnDestroy {
  @Input() data!: any;
  @Input() isEdit!: boolean;
  @Input() uid!: string;
  dictionary = this.dictionaryS.getDictionary();
  variant = this.dictionaryS.getVariant();
  numeConfig = inputConfigHelper({
    label: 'Nume',
    type: 'text',
    placeholder: ''
  });
  preNumeConfig = inputConfigHelper({
    label: 'Prenume',
    type: 'text',
    placeholder: '',
    custom: {
      inputAssistiveText: {
        text:
          'Adăugați toate prenumele ' +
          this.dictionary?.user[this.variant].toLowerCase() +
          'ului!',
      },
    },
  });
  dateNasteriiConfig = inputConfigHelper({
    label: 'Data nașterii',
    type: 'date',
    placeholder: 'Alege',
    custom: {
      useIcon: {
        name: 'default',
        classes: 'neutral-grey-medium-color',
      },
    },
  });

  sexRadioConfig: IonRadiosConfig = {
    mode: 'chip',
    inputLabel: {
      text: 'Sex',
      classes: 'm-0',
    },
    itemClasses: 'mr-12',
  };
  telePhoneConfig = inputConfigHelper({
    label: 'Număr de telefon',
    type: 'text',
    placeholder: '',
    custom: {
      useIcon: {
        name: 'phone',
        classes: 'neutral-grey-medium-color',
      },
      minLength: 6,
      maxLength: 30,
    },
  });
  emailConfig = inputConfigHelper({
    label: 'Email',
    type: 'email',
    placeholder: '',
    custom: {
      useIcon: {
        name: 'biz-email',
        classes: 'neutral-grey-medium-color',
      },
    },
  });
  judetConfig: IonSelectConfig = {
    inputLabel: {
      classes: '',
      text: 'Județ',
    },
    forceListItems: false,
    multiple: false,
    disabled: false,
    placeholder: '',
    alertOptions: {
      cssClass: '',
    },
    idKey: 'id',
    labelKey: 'label',
    useIcon: {
      name: 'caret-down',
      classes: 'neutral-grey-medium-color',
    },
  };

  judetOption = [];
  canalDePromovareConfig: IonSelectConfig = {
    inputLabel: {
      classes: '',
      text: 'Canal de Promovare',
    },
    forceListItems: false,
    multiple: false,
    disabled: false,
    placeholder: '',
    alertOptions: {
      cssClass: '',
    },
    idKey: 'uid',
    labelKey: 'name',
    useIcon: {
      name: 'caret-down',
      classes: 'neutral-grey-medium-color',
    },
  };
  canalDePromovareOptions = [];
  orasConfig: IonSelectConfig = {
    inputLabel: {
      classes: '',
      text: 'Oraș',
    },
    forceListItems: false,
    multiple: false,
    disabled: false,
    placeholder: '',
    alertOptions: {
      cssClass: '',
    },
    idKey: 'id',
    labelKey: 'label',
    useIcon: {
      name: 'caret-down',
      classes: 'neutral-grey-medium-color',
    },
  };
  componentFormGroup: FormGroup;
  orasOptions: any;
  addMoreField = false;
  loading = false;
  addUser$: Subscription;
  getCountries$: Subscription;
  getCities$: Subscription;
  judet$: Subscription;
  personData: any;
  getPromotions$: Subscription;
  sexOptions: IonRadioInputOption[] = Object.keys(GenderEnum)
    .filter((value: any) => isNaN(Number(value)) === false)
    .map((key: any) => ({
      label: GenderEnum[key],
      id: Number(key),
    }));
  editComponent = {
    status: false,
    data: null,
    ignoreComponentFormEmit: true,
    cityIdIsAvailable: false,
  };
  editComponent$: BehaviorSubject<{
    status: boolean;
    data: any;
    ignoreComponentFormEmit: boolean;
    cityIdIsAvailable: boolean;
  }> = new BehaviorSubject(this.editComponent);
  getData$: Subscription;
  cnpValueChanges$: Subscription;
  permission$: BehaviorSubject<{
    allowDuplicatePhone: boolean;
    tempName: string;
  }> = new BehaviorSubject({
    allowDuplicatePhone: false,
    tempName: null,
  });
  errMsg = {
    both: 'Numărul de telefon și CNP introdus au fost folosite de un alt utilizator',
    phone: 'Numărul de telefon introdus a fost folosit de un alt utilizator',
    cnp: 'CNP-ul introdus este deja înregistrat în baza de date',
  };
  phoneNumber$: Subscription;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Romania,
    CountryISO.UnitedKingdom,
  ];
  selectedCountry: CountryISO = CountryISO.Romania;
  phoneUtil: any = libphonenumber.PhoneNumberUtil.getInstance();
  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private reqS: RequestService,
    public toastController: ToastController,
    private loadingContollerS: LoadingControllerService,
    private dictionaryS: DictionaryService,
    private auth: AuthService,
    private cdref: ChangeDetectorRef,
    public permissionsS: PermissionsService
  ) {}
  async ngOnInit() {
    this.onInitialize();

    this.judet$ = this.componentFormGroup
      .get('judet')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((d) => {
        if (d && !this.editComponent$.getValue().ignoreComponentFormEmit) {
          this.getCitiesByCountryId(d);
        }
      });
    this.cnpValueChanges$ = this.componentFormGroup.controls?.cnp.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((cnp: any) => {
        if (cnp && this.componentFormGroup.controls?.cnp?.valid) {
          const { sex, dateNasterii } = this.componentFormGroup.controls;
          dateNasterii.patchValue(getDateFromCPN(cnp));
          sex.patchValue(getGenderFromCPN(cnp));
        }
      });
  }
  get isTelePhoneInvalid() {
    return (
      this.componentFormGroup.controls?.telephone?.invalid &&
      this.componentFormGroup.controls?.telephone?.touched
    );
  }
  get cnpConfig() {
    return inputConfigHelper({
      label: 'CNP',
      type: 'number',
      placeholder: '',
      custom: {
        useIcon: {
          name: 'cnp',
          classes: 'neutral-grey-medium-color',
        },
        maxLength: 13,
        inputHasError: checkIfEmpty(
          this.componentFormGroup.controls?.cnp?.value
        )
          ? false
          : this.componentFormGroup.controls?.cnp?.invalid,
      },
    });
  }

  get shouldTelePhoneFocus() {
    return (
      this.componentFormGroup.controls?.telephone?.touched &&
      this.componentFormGroup.controls?.telephone?.valid &&
      !checkIfEmpty(this.componentFormGroup.controls?.telephone?.value)
    );
  }

  // initialize form controls
  async onInitialize() {
    this.componentFormGroup = this.fb.group({
      nume: ['', [Validators.required]],
      preNume: ['', [Validators.required]],
      dateNasterii: [''],
      sex: ['', [Validators.required]],
      telephone: [''],
      email: ['', Validators.email],
      cnp: ['', cnpValidator(false)],
      judet: [{ value: '', disabled: true }],
      oras: [{ value: '', disabled: true }],
      canalDePromovare: '',
    });

    if (this.data) {
      let d = {
        ...this.data,
      };
      if (this.data?.telephone) {
        const f = getPhoneDetails(this.data?.telephone);
        if (f?.isNumberValid) {
          this.selectedCountry = getSelectedCountryEnum(f?.iso2);
          d = {
            ...d,
            telephone: f?.phoneWithoutDailCode.replace(/\s/g, ''),
          };
        }
      }
      this.componentFormGroup.patchValue(d);
      this.componentFormGroup.markAllAsTouched();
      this.componentFormGroup.updateValueAndValidity();
      this.editComponent$.next({
        ...this.editComponent$.getValue(),
        status: true,
        data: this.data,
        cityIdIsAvailable: this.data.oras && this.data.oras !== 0,
      });
    }

    await this.loadingContollerS.presentLoadingWithNoDuration(
      'Vă rugăm să așteptați...'
    );
    const request =
      this.editComponent$.getValue().status &&
      this.editComponent$.getValue().cityIdIsAvailable
        ? this.reqS.post(location.getCities, {
            cityID: this.data.oras,
          })
        : this.reqS.post(location.getCountries, {
            searchString: '',
          });
    this.getData$ = forkJoin([
      this.reqS.get(promotions.getPromotionChannels),
      request,
    ])
      .pipe(
        tap((v: any[]) => {
          this.canalDePromovareOptions = v[0];
          if (
            this.editComponent$.getValue().status &&
            this.editComponent$.getValue().cityIdIsAvailable
          ) {
            this.orasOptions = v[1].cities.map((res: any) => ({
              ...res,
              label: res.name,
            }));
            this.componentFormGroup.controls?.oras?.enable();
          }
        }),
        switchMap((v: any[]) => {
          if (
            this.editComponent$.getValue().status &&
            this.editComponent$.getValue().cityIdIsAvailable
          ) {
            if (v[1] && v[1].cities && v[1].cities.length > 0) {
              this.componentFormGroup.controls.judet.patchValue(
                v[1].cities[0].countyID
              );
            }
            return this.reqS.post(location.getCountries, { searchString: '' });
          } else {
            return of(v[1]);
          }
        }),
        tap(() => {
          const { telephone, email, cnp, judet, oras, canalDePromovare } =
            this.componentFormGroup.controls;
          if (
            telephone.value ||
            judet.value ||
            email.value ||
            cnp.value ||
            oras.value ||
            canalDePromovare.value
          ) {
            this.toggleMoreField();
          }
        })
      )
      .subscribe(
        (res: any) => {
          this.prepareParameters();
          this.loadingContollerS.dismiss();
          this.judetOption = res.counties.map((r: any) => ({
            ...r,
            label: r.name,
            id: r.id,
          }));
          this.componentFormGroup.controls?.judet?.enable();
          this.removeEditComponentFormIgnoreStatus();
          this.cdref.detectChanges();
        },
        async (_err) => {
          this.loadingContollerS.dismiss();
          this.removeEditComponentFormIgnoreStatus();
          await this.presentToast(
            'Apare o eroare în timp ce încercați să obțineți date. Vă rugăm să verificați rețeaua și încercați din nou.'
          );
        }
      );
  }
  removeEditComponentFormIgnoreStatus() {
    this.editComponent$.next({
      ...this.editComponent$.getValue(),
      ignoreComponentFormEmit: false,
    });
  }
  async presentToast(
    message: string = 'message',
    cssClass: 'success' | 'error' = 'error',
    duration: number = 3000
  ) {
    const toast = await this.toastController.create({
      cssClass,
      message,
      duration,
    });
    toast.present();
  }
  getCitiesByCountryId(
    countyID: number = this.componentFormGroup.value.judet,
    searchString: string = ''
  ) {
    this.getCities({
      countyID,
      searchString,
    });
  }
  async getCities(payload: any) {
    await this.loadingContollerS.presentLoadingWithNoDuration(
      'Vă rugăm să așteptați...'
    );
    this.getCities$ = this.reqS.post(location.getCities, payload).subscribe(
      (d: any) => {
        this.loadingContollerS.dismiss();
        this.orasOptions = d.cities.map((res: any) => ({
          ...res,
          label: res.name,
        }));
        this.componentFormGroup.controls?.oras?.enable();
      },
      async (_err) => {
        this.loadingContollerS.dismiss();
        await this.presentToast(
          'An error occur while trying to get cities at this time. Please Check your newtwork and try again.'
        );
      }
    );
  }
  toggleMoreField() {
    this.addMoreField = !this.addMoreField;
  }

  //submit add/edit person
  process() {
    if (this.formGroupValidity) {
      this.loading=true;
      const d = {
        firstName: get(this.componentFormGroup.value, 'preNume', ''),
        lastName: get(this.componentFormGroup.value, 'nume', ''),
        pid: get(this.componentFormGroup.value, 'cnp', ''),
        birthDate: get(this.componentFormGroup.value, 'dateNasterii', '') ?  formatRFC3339(
          new Date(
            get(this.componentFormGroup.value, 'dateNasterii', new Date())
          ),
          { fractionDigits: 3 }
        ) :  null,
        phone: get(this.componentFormGroup.value?.telephone, 'e164Number', ''), // get(this.componentFormGroup.value, 'telephone', ''),
        email: get(this.componentFormGroup.value, 'email', ''),
        cityID: Number(get(this.componentFormGroup.value, 'oras', 0)),
        genderID: Number(get(this.componentFormGroup.value, 'sex', 0)),
        isActive: true,
        wasUpdateByMobile: true,
        mobileUpdateDate: formatRFC3339(new Date(), { fractionDigits: 3 }),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        promotionChannelUID: get(
          this.componentFormGroup.value,
          'canalDePromovare',
          null
        ),
      };
      let postAction: Observable<any>;
      if (this.isEdit && this.uid) {
        postAction = this.reqS.put(persons.updatePerson, {
          ...d,
          uid: this.uid,
        });
      } else {
        postAction = this.reqS.post(persons.addPerson, d);
      }

      const checkPhoneNumberEndpoint = this.reqS.get(
        `${checkExisting.pacientByNumber}${d?.phone}`
      );
      const checkCNPEndpoint = this.reqS.get(
        `${checkExisting.pacientByCNP}${d?.pid}`
      );
      let action$: Observable<any>;
      if (
        !checkIfEmpty(d?.phone) &&
        !checkIfEmpty(d?.pid) &&
        !this.permission$.value.allowDuplicatePhone
      ) {
        action$ = forkJoin([checkPhoneNumberEndpoint, checkCNPEndpoint]).pipe(
          take(1),
          switchMap((t: [Person, Person]) => this.phoneOrCNPCheckThrow(t))
        );
      } else if (
        !checkIfEmpty(d?.phone) &&
        !this.permission$.value.allowDuplicatePhone
      ) {
        action$ = checkPhoneNumberEndpoint.pipe(
          switchMap((t: Person) => this.phoneOrCNPCheckThrow(t))
        );
      } else if (!checkIfEmpty(d?.pid)) {
        action$ = checkCNPEndpoint.pipe(
          switchMap((t: Person) => this.phoneOrCNPCheckThrow(t, true))
        );
      } else {
        action$ = postAction.pipe(
          catchError((_err) =>
            throwError(
              'A aparut o eroare în timp ce încercați să adăugați o persoană nouă.Vă rugăm să încercați din nou.'
            )
          ),
          switchMap((v: any) =>
            of({
              doCheck: false,
              doCheckNeeded: false,
              data: v,
            })
          )
        );
      }

      this.addUser$ = action$
        .pipe(
          switchMap(
            (v: { doCheck: boolean; doCheckNeeded: boolean; data: any }) => {
              if (v && v?.doCheck && v?.doCheckNeeded) {
                return postAction;
              } else {
                return of(v?.data);
              }
            }
          ),
          take(1)
        )
        .subscribe(
          async (res: any) => {
            this.personData = {
              ...d,
              uid: this.uid || res?.insertedUID,
            };
            this.loading = false;
            // present toast and close modal
            await this.presentToast(
              this.isEdit
                ? this.dictionary?.user[this.variant] + ' editat cu succes.'
                : this.dictionary?.user[this.variant] + ' adăugat cu succes.',
              'success'
            );
            this.resetPermission();
            setTimeout(() => {
              if (this.isEdit) {
                this.closeModal(false, true);
              } else {
                this.closeModal(true);
              }
            }, 3000 );
            this.cdref.markForCheck();
          },
          (err: {
            message: string;
            isPhoneError: boolean;
            isCNPError: boolean;
          }) => {
            this.loading = false;
            this.cdref.markForCheck();
            if (err.isPhoneError && err.isCNPError) {
              this.presentToast(this.errMsg.cnp);
            } else if (err.isPhoneError) {
              this.presentCabinentNotify();
            } else {
              this.presentToast(err.message);
            }
          }
        );
    }
  }
  closeModal(
    isPersonCreated: boolean = false,
    isPersonUpdated: boolean = false
  ) {
    this.modalController.dismiss({
      dismissed: true,
      isPersonCreated,
      isPersonUpdated,
      data: (this.personData ||= null),
    });
  }
  get formGroupValidity() {
    return this.componentFormGroup.valid;
  }

  // catch errors
  phoneOrCNPCheckThrow(t: any, isCnp: boolean = false) {
    const error = {
      both: {
        message: this.errMsg.both,
        isPhoneError: true,
        isCNPError: true,
      },
      cnp: {
        message: this.errMsg.cnp,
        isCNPError: true,
        isPhoneError: false,
      },
      phone: {
        message: this.errMsg.phone,
        isPhoneError: true,
        isCNPError: false,
      },
    };
    if (isArray(t)) {
      this.permission$.next({
        ...this.permission$.value,
        tempName:
          !checkIfEmpty(t) && !checkIfEmpty(t[0])
            ? `${t?.[0]?.lastName} ${t?.[0]?.firstName}`
            : ' ',
      });
      if (this.shouldThrowError(t[0]) && this.shouldThrowError(t[1])) {
        return throwError(error.both);
      } else if (this.shouldThrowError(t[0])) {
        return throwError(error.phone);
      } else if (this.shouldThrowError(t[1])) {
        return throwError(error.cnp);
      } else {
        return of({
          doCheck: true,
          doCheckNeeded: true,
          data: null,
        });
      }
    } else {
      if (typeof t === 'object' && t !== null) {
        this.permission$.next({
          ...this.permission$.value,
          tempName: !checkIfEmpty(t) ? `${t?.lastName} ${t?.firstName}` : ' ',
        });
        if (this.shouldThrowError(t)) {
          return throwError(isCnp ? error.cnp : error.phone);
        }
        return of({
          doCheck: true,
          doCheckNeeded: true,
          data: t,
        });
      } else {
        return of({
          doCheck: true,
          doCheckNeeded: true,
          data: t,
        });
      }
    }
  }
  shouldThrowError(t: any) {
    if (!checkIfEmpty(t)) {
      if (this.isEdit && this.uid && t?.uid === this.uid) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  // notify there is a duplicate phone number
  async presentCabinentNotify() {
    const modal = await this.modalController.create({
      component: CabinetNotifyComponent,
      cssClass: 'biz-modal-class-type-a modal-wrapper-with-232px-height',
      backdropDismiss: true,
      componentProps: {
        notifyType: 'typeA-alert',
        cabinetName: 'Confirmare',
        message: `Exista deja un pacient (${this.permission$.value.tempName}) cu acest numar de telefon. Continuati salvarea? DA/NU`,
        buttonOkText: 'DA',
        buttonCancelText: 'NU',
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const { dismissed, renita, veziProgram } = data;
      if (dismissed && veziProgram) {
        this.permission$.next({
          ...this.permission$.value,
          allowDuplicatePhone: true,
        });
        this.process();
      }
    }
  }
  resetPermission() {
    this.permission$.next({
      ...this.permission$.value,
      allowDuplicatePhone: false,
      tempName: '',
    });
  }

  // prepare parameters that are used in add pacient form
  prepareParameters() {
    this.auth
      .getParameterState()
      .pipe(
        map((ps) =>
          of([...ps.parameters.filter((p) => p.dataType === 'bool' || p.dataType === null)])
        ),
        concatAll()
      )
      .subscribe((parameters) => {
        this.configureFormBasedOnParameters(parameters);
      });
  }

  configureFormBasedOnParameters(parameters: Parameter[]) {
    for (let i = 0; i < parameters.length; i++) {
      const param = parameters[i];
      switch (param.code) {
        case 'PersonAddressIsnecessary':
          this.patchFormBasedOnParameters(param, 'judet');
          this.patchFormBasedOnParameters(param, 'oras');
          break;
        case 'phoneNumberIsNecessary':
          this.patchFormBasedOnParameters(param, 'telephone');
          break;
        case 'birthDateIsNecessary':
          this.patchFormBasedOnParameters(param, 'dateNasterii');
          break;
        case 'promoChannelIsNecessary':
          this.patchFormBasedOnParameters(param, 'canalDePromovare');
          break;
        case 'emailIsNecessary':
          this.patchFormBasedOnParameters(param, 'email', Validators.email);
          break;
        case 'PIDIsMandatoryForBizCalendar':
          this.patchFormBasedOnParameters(param, 'cnp', cnpValidator(false));
          break;
        default:
          break;
      }
    }
  }

  // add form validators based on parameters
  patchFormBasedOnParameters(param: Parameter, field, defaultValidator = null) {
    if (param.value === '1' || param.value === true) {
      const val = defaultValidator
        ? [Validators.required, defaultValidator]
        : Validators.required;
      this.componentFormGroup.get(field).setValidators(val);
    } else {
      this.componentFormGroup
        .get(field)
        .setValidators(defaultValidator ? defaultValidator : null);
    }
    this.componentFormGroup.updateValueAndValidity();
  }

  isRequiredField(field: string, valueRequired: boolean) {
    if (valueRequired) {
      const formField = this.componentFormGroup.get(field);
      if (!formField.validator) {
        return false;
      }
      const validator = formField.validator({} as AbstractControl);
      return validator?.required && valueRequired;
    }
    if (!valueRequired) {
      const formField = this.componentFormGroup.get(field);
      if (!formField.validator) {
        return true;
      }
      const validator = formField.validator({} as AbstractControl);
      return !validator?.required && !valueRequired;
    }
  }
  get userRoleRightForSALVEAZABtn(): boolean {
    return this.isEdit && this.uid ? !this.permissionsS.canModifyPatients : !this.permissionsS.canAddPatients;
  }

  get loadingState(): boolean {
    return this.loading;
  }

  ngOnDestroy() {
    unsubscriberHelper(this.getCountries$);
    unsubscriberHelper(this.getCities$);
    unsubscriberHelper(this.addUser$);
    unsubscriberHelper(this.judet$);
    unsubscriberHelper(this.getPromotions$);
    unsubscriberHelper(this.getData$);
    unsubscriberHelper(this.cnpValueChanges$);
  }
}
