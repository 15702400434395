import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { questionnaireService } from '../../configs/endpoints';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireeService {
  constructor(private reqS: RequestService) {
  }

  getQuestionnaires() {
    return this.reqS.get<any>(questionnaireService.GetQuestionnaires);
  }

  getQuestionnaireUrl(questionnaireID: string, personUID: string, consultationID: string) {
    return this.reqS.get<any>(`${questionnaireService.GetQuestionnaireURL}?questionnaireID=${questionnaireID}
    &personUID=${personUID}&consultationID=${consultationID}`, { responseType: 'text' });
  }
}
