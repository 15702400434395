import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() confirmationText: string;
  button = {
    sterge: 'șterge',
    pastreza: 'păstrează',
  };

  constructor(private modalController: ModalController) {}
  closeModal(type: string = '') {
    this.modalController.dismiss({
      dismissed: true,
      sterge: type === this.button.sterge,
      pastreza: type === this.button.pastreza,
    });
  }
  ngOnInit() {}
}
