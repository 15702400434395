/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdaugaProgrammarePermissions } from '@app/core/models/adauga-programmare.permission';
import { CautaService } from '@app/core/services/cauta/cauta.service';
import { DictionaryService } from '@app/core/services/dictionary/dictionary.service';
import { inputConfigHelper } from '@app/shared/data/input-config-helper';
import { IonInputConfig } from '@app/shared/models/components/ion-input-config';
import { IonRadioInputOption } from '@app/shared/models/components/ion-radio-input-option';
import { IonRadiosConfig } from '@app/shared/models/components/ion-radios-config';
import { IonTextItem } from '@app/shared/models/components/ion-text-item';
import { ModalController } from '@ionic/angular';
import { distinct, distinctUntilChanged, last, take, takeLast, withLatestFrom } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { DictionaryModel } from '@app/core/models/dictionary.model';

@Component({
  selector: 'app-mod-de-plata',
  templateUrl: './mod-de-plata.component.html',
  styleUrls: ['./mod-de-plata.component.scss'],
})
export class ModDePlataComponent implements OnInit {
  @Input() set permission(perm: AdaugaProgrammarePermissions) {
    if (!perm.allowInsurancePaymentMethod) {
      this.paymentMethodRadioOptions = this.paymentMethodRadioOptions.filter(x => x.value !== 'insuranceCompany');
      this.paymentMethodRadioOptions = this.paymentMethodRadioOptions.filter(x => x.value !== 'partnerCompany');
    }
    if (!perm.allowServicesPackagePaymentMethod) {
      this.paymentMethodRadioOptions = this.paymentMethodRadioOptions.filter(x => x.value !== 'servicePackage');
    }
  }

  @Input() personUID: string;
  @Input() physicianUID: string;
  @Input() serviceUID: string;
  paymentData: any = null;
  showMsg = true;
  @Input() set selectedAppointmentsServices(data: any) {
    if (data) {
      this.paymentData = data;
      this.patchPaymentServiceRadioForm(data);
    }
    else {
      this.paymentData = null;
      this.watchPaymentMethodSelected();
      this.paymentServiceRadioForm.controls.paymentMethod.patchValue(this.paymentMethodRadioOptions[0].value, { emitEvent: true });
    }
  }

  methodOfPaymentToDisplay: string = null;
  paymentMethodRadioOptions: IonRadioInputOption[] = [
    {
      label: 'Numerar/card în clinică/Link de plata online',
      value: 'cashCard',
    },
    {
      label: 'Printr-o companie de asigurări',
      value: 'insuranceCompany',
    },
    {
      label: 'Plata prin companie partenera',
      value: 'partnerCompany',
    },
    {
      label: 'Pachet de servicii',
      value: 'servicePackage',
    },
  ];
  companyList: any[] = [];
  insuranceCompanyList: any[] = [];
  partnerCompanyList: any[] = [];
  servicePackageList: any[] = [];
  loading = false;

  cardCashInputConfig: IonInputConfig = inputConfigHelper({
    label: null,
    type: 'number',
    placeholder: 'Cantitate',
    custom: {
      removeInputItemBaseLine: true,
      removeLIconeftAndRightPadding: true,
      inputRolClasses: ' mw-155 ',
    },
  });

  insuranceCompanyInputConfig: IonInputConfig = inputConfigHelper({
    label: null,
    type: 'number',
    placeholder: 'Cantitate',
    custom: {
      removeInputItemBaseLine: true,
      removeLIconeftAndRightPadding: true,
      inputRolClasses: ' mw-155 ',
    },
  });

  paymentServiceRadioForm: FormGroup = this.fb.group({
    paymentMethod: [null, [Validators.required]],
  });
  variant: any = null;
  dictionary: DictionaryModel = null;
  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private cautaService: CautaService,
    private dictionaryS: DictionaryService,
  ) {
    this.updateInputsConfig();
  }
  ngOnInit(): void {
    this.watchPaymentMethodSelected();
    this.getDictionary();
  }

  createPaymentServiceRadioFormBasedOnPaymentMethod(methodOfPaymentToDisplay) {
    switch (methodOfPaymentToDisplay) {
      case 'cashCard':
        this.paymentServiceRadioForm.addControl('cashCard', this.fb.group({
          quantity: [1, [Validators.required]],
        }));
        this.loading = false;
        this.removeFormControl('servicePackage', 'insuranceCompany', 'partnerCompany');
        this.patchFormControl();
        break;
      case 'insuranceCompany':
        this.getPersonCompanies('insuranceCompany');
        this.removeFormControl('servicePackage', 'cashCard', 'partnerCompany');
        break;
      case 'partnerCompany':
        this.getPersonCompanies('partnerCompany');
        this.removeFormControl('servicePackage', 'cashCard', 'insuranceCompany');
        break;
      case 'servicePackage':
        this.getPersonPromoPackages();
        this.removeFormControl('cashCard', 'insuranceCompany', 'partnerCompany');
        break;
    }
  }


  patchFormControl() {
    if (this.paymentData) {
      this.patchFormControlSwitchCase(this.paymentData.paymentMethod);
    }
  }

  patchFormControlSwitchCase(paymentMethod) {
    switch (paymentMethod) {
      case 'cashCard':
        this.paymentServiceRadioForm.get('cashCard').patchValue({
          quantity: this.paymentData.quantity,
        });
        break;
      case 'insuranceCompany':
        if (!this.insuranceCompanyList.find(x => Number(x.companyID) === Number(this.paymentData.companyID))) {
          break;
        }
        this.paymentServiceRadioForm.get('insuranceCompany').patchValue({
          [this.paymentData.companyID]: true,
          [this.paymentData.companyID + 'Quantity']: this.paymentData.quantity,
        }, { emitEvent: false, onlySelf: true });
        (this.paymentServiceRadioForm.get('insuranceCompany') as FormGroup).controls[`${this.paymentData.companyID}Quantity`].enable({
          emitEvent: false, onlySelf: true,
        });
        break;
      case 'partnerCompany':
        if (!this.partnerCompanyList.find(x => Number(x.companyID) === Number(this.paymentData.companyID))) {
          break;
        }
        this.paymentServiceRadioForm.get('partnerCompany').patchValue({
          [this.paymentData.companyID]: true,
          [this.paymentData.companyID + 'Quantity']: this.paymentData.quantity,
        }, { emitEvent: false, onlySelf: true });
        (this.paymentServiceRadioForm.get('partnerCompany') as FormGroup).controls[`${this.paymentData.companyID}Quantity`].enable({
          emitEvent: false, onlySelf: true,
        });
        break;
      case 'servicePackage':
        if (!this.servicePackageList.find(x => x.details[0].bL_PromotionID === this.paymentData.BL_PromoID)) {
          break;
        }
        this.paymentServiceRadioForm.get('servicePackage').patchValue({
          [this.paymentData.BL_PromoID]: true,
          [this.paymentData.BL_PromoID + 'Quantity']: this.paymentData.quantity,
        }, { emitEvent: false, onlySelf: true });
        (this.paymentServiceRadioForm.get('servicePackage') as FormGroup).controls[`${this.paymentData.BL_PromoID}Quantity`].enable({
          emitEvent: false, onlySelf: true,
        });
        break;
    }
    this.paymentData = null;
  }

  removeFormControl(...formControlNames: string[]) {
    formControlNames.forEach(formControlName => {
      this.paymentServiceRadioForm.removeControl(formControlName);
    });
  }


  patchPaymentServiceRadioForm(data) {
    this.paymentServiceRadioForm.patchValue({
      paymentMethod: data.paymentMethod,
    });
    this.createPaymentServiceRadioFormBasedOnPaymentMethod(data.paymentMethod);
    this.loading = false;
  }

  createFormControlNameWithValueFromList(PreferedCompanyList, formControlFieldName) {
    const formControlNameWithValueFromPreferedCompanyList = PreferedCompanyList.reduce((acc, curr) => {
      acc[curr[formControlFieldName]] = [false];
      acc[curr[formControlFieldName] + 'Quantity'] = [{ value: 1, disabled: true }];
      return acc;
    }
      , {});
    return formControlNameWithValueFromPreferedCompanyList;
  }

  createFormControlNameWithValueFromServiceList(PreferedCompanyList, formControlFieldName) {
    const formControlNameWithValueFromPreferedCompanyList = PreferedCompanyList.reduce((acc, curr) => {
      acc[curr.details[0][formControlFieldName]] = [false];
      acc[curr.details[0][formControlFieldName] + 'Quantity'] = [{ value: 1, disabled: true }];
      return acc;
    }
      , {});
    return formControlNameWithValueFromPreferedCompanyList;
  }

  updateInputsConfig() {
    this.insuranceCompanyInputConfig.max = 100;
    this.insuranceCompanyInputConfig.min = 0;
    this.insuranceCompanyInputConfig.maxLength = 1;
    this.insuranceCompanyInputConfig.spinnerConfig = {
      step: 1,
      textAlign: true,
    };

    this.cardCashInputConfig.max = 100;
    this.cardCashInputConfig.min = 0;
    this.cardCashInputConfig.maxLength = 1;
    this.cardCashInputConfig.spinnerConfig = {
      step: 1,
      textAlign: true,
    };
  }

  watchPaymentMethodSelected() {
    this.paymentServiceRadioForm.get('paymentMethod').valueChanges.subscribe(
      (value) => {
        this.loading = true;
        this.methodOfPaymentToDisplay = value;
        this.createPaymentServiceRadioFormBasedOnPaymentMethod(value);
      }
    );
  }

  getPersonCompanies(lisType: string) {
    this.showMsg = false;
      this.cautaService.getPersonCompanies(this.personUID).subscribe(
        (data) => {
          if (data?.length > 0) {
            this.showMsg = false;
        this.companyList = data;
        if (lisType === 'insuranceCompany') {
          this.insuranceCompanyList = this.companyList.filter(company => company.isAssuranceComp);
          this.paymentServiceRadioForm.addControl('insuranceCompany',
            this.fb.group(this.createFormControlNameWithValueFromList(this.insuranceCompanyList, 'companyID')));
          this.setFormState();
          this.patchFormControl();
        }
        if (lisType === 'partnerCompany') {
          this.partnerCompanyList = this.companyList.filter(company => !company.isAssuranceComp);
          this.paymentServiceRadioForm.addControl('partnerCompany',
            this.fb.group(this.createFormControlNameWithValueFromList(this.partnerCompanyList, 'companyID')));
          this.setFormState();
          this.patchFormControl();
        }
          } else {
            this.showMsg = true;
          }
          this.loading = false;
        },
        (error) => { },
        () => {
          this.loading = false;
        }
      );
  }



  getPersonPromoPackages() {
    this.showMsg = false;
    const serviceUID = this.serviceUID.split(':')[0];
    this.cautaService.getPersonPromotionPackages(this.personUID, this.physicianUID, serviceUID).subscribe(
      (data) => {
        if (data?.length) {
          this.showMsg = false;
          this.servicePackageList = data;
          const formObj =
            this.createFormControlNameWithValueFromServiceList(this.servicePackageList, 'bL_PromotionID');
          this.paymentServiceRadioForm.addControl('servicePackage',
            this.fb.group(formObj));
          this.setFormState();
          this.patchFormControl();
        } else {
          this.showMsg = true;
        }
      },
      (error) => { },
      () => {
        this.loading = false;
      }
    );
  }

  closeModal(closeStatus: boolean = true) {
    this.modalController.dismiss({
      dismissed: true,
      paymentMethodData: !closeStatus ? this.generatePaymentMethodData() : null,
    });
  }

  generatePaymentMethodData() {
    let appointmentsService = null;
    const paymentMethod = this.paymentServiceRadioForm.get('paymentMethod').value;
    const paymentMethodForm = this.paymentServiceRadioForm.get(paymentMethod);
    if (paymentMethod === 'cashCard') {
      const quantity = paymentMethodForm.get('quantity').value;
      if (quantity) {
        appointmentsService = {
          serviceUID: this.serviceUID,
          quantity,
          paymentMethod
        };
      }
    }
    if (paymentMethod === 'insuranceCompany') {
      const insuranceCompanyForm = paymentMethodForm as FormGroup;
      Object.keys(insuranceCompanyForm.controls).forEach(companyID => {
        if (insuranceCompanyForm.get(companyID).value && !companyID.includes('Quantity')) {
          const foundCompany = this.insuranceCompanyList.find(company => company.companyID === Number(companyID));
          const quantity = insuranceCompanyForm.get(companyID + 'Quantity').value;
          appointmentsService = {
            serviceUID: this.serviceUID,
            quantity,
            companyID: foundCompany.companyID,
            IsAssuranceCompDiscount: false,
            IsCompDiscount: foundCompany?.IsCompDiscount || true,
            paymentMethod
          };
        }
      });
    }
    if (paymentMethod === 'partnerCompany') {
      const partnerCompanyForm = paymentMethodForm as FormGroup;
      Object.keys(partnerCompanyForm.controls).forEach(companyID => {
        if (partnerCompanyForm.get(companyID).value && !companyID.includes('Quantity')) {
          const foundCompany = this.partnerCompanyList.find(company => company.companyID === Number(companyID));
          const quantity = partnerCompanyForm.get(companyID + 'Quantity').value;
          appointmentsService = {
            quantity,
            companyID,
            serviceUID: this.serviceUID,
            IsAssuranceCompDiscount: false,
            IsCompDiscount: foundCompany?.IsCompDiscount || true,
            paymentMethod
          };
        }
      });
    }
    if (paymentMethod === 'servicePackage') {
      const servicePackageForm = paymentMethodForm as FormGroup;
      Object.keys(servicePackageForm.controls).forEach(bL_PromotionID => {
        if (servicePackageForm.get(bL_PromotionID).value && !bL_PromotionID.includes('Quantity')) {
          const quantity = servicePackageForm.get(bL_PromotionID + 'Quantity').value;
          const foundServicePackage = this.servicePackageList.find(servicePackage =>
            servicePackage.details[0].bL_PromotionID === Number(bL_PromotionID));
          const BL_PromoID = foundServicePackage.details[0].bL_PromotionID;
          const BL_PromotionPackage_PersonID = foundServicePackage.bL_PromotionPackage_PersonsID;
          appointmentsService = {
            quantity,
            serviceUID: this.serviceUID,
            BL_PromoID,
            BL_PromotionPackage_PersonID,
            paymentMethod
          };
        }
      });
    }
    return {
      paymentMethod,
      appointmentsService,
    };
  }

  setFormState() {
    const paymentMethod = this.paymentServiceRadioForm.get('paymentMethod').value;
    const paymentMethodForm = this.paymentServiceRadioForm.get(paymentMethod) as FormGroup;
    paymentMethodForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(100)).subscribe(
      (value) => {
        Object.keys(paymentMethodForm.controls).forEach(companyID => {
          if (paymentMethodForm.get(companyID) && !companyID.includes('Quantity')) {
            paymentMethodForm.get(companyID).valueChanges.pipe(distinctUntilChanged(), take(1)).subscribe(
              (controlValue) => {
                if (controlValue) {
                  paymentMethodForm.get(companyID + 'Quantity').enable({ emitEvent: false, onlySelf: true });
                  paymentMethodForm.patchValue(this.formObj(Object.keys(paymentMethodForm.controls), companyID),
                    { emitEvent: true, onlySelf: true });
                } else {
                  paymentMethodForm.get(companyID + 'Quantity').disable({ emitEvent: false, onlySelf: true });
                }
              }
            );
          }
        });
      });
  }

  formObj(keys: any[], excludedKey: string, includeQuantity: boolean = false) {
    const formObj = {};
    keys.forEach(key => {
      if (key.includes('Quantity') === includeQuantity && excludedKey !== key) {
        formObj[key] = false;
      }
    });
    return formObj;
  }

  get paymentMethodFormControl() {
    return this.paymentServiceRadioForm.get('paymentMethod');
  }

  getServicePackageInputConfig(item) {
    if (item) {
      const inputConfig: IonInputConfig = inputConfigHelper({
        label: `Cantitate disponibilă în pachet: ${item.details[0].availableUnits}`,
        type: 'number',
        placeholder: 'Cantitate',
        custom: {
          removeInputItemBaseLine: true,
          removeLIconeftAndRightPadding: true,
          inputRolClasses: ' mw-155 ',
        },
      });
      inputConfig.max = item.details[0].availableUnits;
      inputConfig.min = 0;
      inputConfig.maxLength = 1;
      inputConfig.spinnerConfig = {
        step: 1,
        textAlign: true,
      };
      return inputConfig;
    }
    return null;
  }

  getDictionary() {
    this.dictionaryS.variant$.subscribe((val) => {
      if (val) {
        this.variant = val;
      } else {
        this.variant = this.dictionaryS.getVariant();
      }
      this.dictionary = this.dictionaryS.getDictionary();
    });
  }
}
