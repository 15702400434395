import { Component, Input, OnInit } from '@angular/core';
import {
  Appointment,
  AppointmentResponse,
} from '@app/core/models/appointment.interface';
import {
  MappedAlternativeTimeSlots,
  RecurrenceConflictInterface,
} from '@app/core/models/checkRecurrenceAvailability.interface';
import { GetPhysiciansScheduleAndFreeDaysResponse } from '@app/core/models/getPhysiciansScheduleAndFreeDays.interface';
import { LoadingControllerService } from '@app/core/services/loadingController/loading-controller.service';
import { ProgrammareService } from '@app/core/services/programmare/programmare.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ModalController } from '@ionic/angular';
import { addHours, addMinutes, formatISO, startOfDay } from 'date-fns';
import { CabinetComponent } from '../cabinet/cabinet.component';

@Component({
  selector: 'app-conflict-programari',
  templateUrl: './conflict-programari.component.html',
  styleUrls: ['./conflict-programari.component.scss'],
})
export class ConflictProgramariComponent implements OnInit {
  @Input() conflictData: RecurrenceConflictInterface[];
  @Input() recurrenceType: string;
  @Input() startTime!: Date;
  @Input() endTime!: Date;
  @Input() physicianUID!: string;
  @Input() locationUID!: string;
  @Input() duration: number;
  featureDisabled = true;

  constructor(
    private modalController: ModalController,
    private loadingContollerS: LoadingControllerService,
    private programmareS$: ProgrammareService,
    private toastService: ToastService
  ) {}

  ngOnInit() {}
  trackConflict(item: any) {
    return item?.startTime;
  }
  dismissModal(adauga = false) {
    const checkForCOnflictData = this.conflictData.filter(
      (v: RecurrenceConflictInterface) =>
        v?.isConflict &&
        !v?.conflictDateChanged &&
        v?.alternativeTimeSlots &&
        Object.keys(v?.alternativeTimeSlots).length > 0
    );
    if (adauga && checkForCOnflictData.length > 0) {
      this.toastService.presentToastWithDurationDismiss(
        'Vă rugăm să remediați toate datele conflictului'
      );
    } else {
      this.modalController.dismiss(
        {
          dismissed: true,
          adauga,
          conflictDataFix: checkForCOnflictData.length < 1,
          conflictData: this.sortedConflictData.filter(
            (v: RecurrenceConflictInterface) =>
              (v?.isConfirmed && v?.alternativeSlots.length === 0) ||
              (v?.isConflict && v?.conflictDateChanged)
          ),
        },
        undefined,
        'ConflictProgramariComponent'
      );
    }
  }
  async viewProgram(item: RecurrenceConflictInterface) {
    await this.loadingContollerS.presentLoadingWithNoDuration(
      'Vă rugăm să așteptați...',
      'checkIfRouteIdAvailable'
    );
    this.programmareS$
      .getPhysicianScheduleAndAppointment(
        item?.startTime,
        item?.endTime,
        item?.physicianUID,
        item?.locationUID
      )
      .subscribe(
        (
          v: [GetPhysiciansScheduleAndFreeDaysResponse, AppointmentResponse]
        ) => {
          this.loadingContollerS.dismiss();
          this.presentCabinent(
            item?.uuid,
            v[0]?.physiciansSchedule.map((ps: any) => ({
              ...ps,
              date: startOfDay(new Date(ps.date)),
              endTime: addHours(
                startOfDay(new Date(ps.date)),
                parseInt(ps.end, 10)
              ),
              startTime: addHours(
                startOfDay(new Date(ps.date)),
                parseInt(ps.start, 10)
              ),
            })),
            v?.[1]?.appointments,
            v?.[1]?.phyFreeDays,
            new Date(item?.startTime)
          );
        },
        (_err) => this.loadingContollerS.dismiss()
      );
  }
  async presentCabinent(
    uuid: string,
    schedules: any[],
    appointments: Appointment[],
    phyFreeDays: any[],
    viewDate: Date,
    isRecurentaScheldule: boolean = true
  ) {
    const modal = await this.modalController.create({
      component: CabinetComponent,
      cssClass: 'biz-modal-class width-md-100',
      id: 'cabinet-modal',
      backdropDismiss: false,
      componentProps: {
        cabinetName: 'Program name',
        schedules,
        appointments,
        phyFreeDays,
        viewDate,
        isRecurentaScheldule,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    const { dismissed, isAbletoSelectCainet, dateData } = data;
    if (dismissed && isAbletoSelectCainet && dateData) {
      if (uuid) {
        const getConflitDataIndex = this.conflictData.findIndex(
          (cd: RecurrenceConflictInterface) => cd.uuid === uuid
        );
        if (getConflitDataIndex > -1) {
          this.conflictData[getConflitDataIndex] = {
            ...this.conflictData[getConflitDataIndex],
            isConflict: false,
            startTime: formatISO(new Date(dateData)),
            endTime: formatISO(
              new Date(addMinutes(new Date(dateData), this.timeDuration))
            ),
          };
        }
      }
    }
  }
  selectAlternativeTime(uuid: string, timeSlot: MappedAlternativeTimeSlots) {
    if (uuid) {
      const getConflitDataIndex = this.conflictData.findIndex(
        (cd: RecurrenceConflictInterface) => cd.uuid === uuid
      );
      if (getConflitDataIndex > -1 && timeSlot) {
        const { date, startTime, endTime } = timeSlot;
        this.conflictData[getConflitDataIndex] = {
          ...this.conflictData[getConflitDataIndex],
          // isConflict: false,
          // startTime: formatISO(new Date(`${date}T${startTime}`)),
          // endTime: formatISO(new Date(`${date}T${endTime}`)),
          conflictDateChanged: true,
          changedStartTime: formatISO(new Date(`${date}T${startTime}`)),
          alternativeTimeSlots: {
            ...this.conflictData[getConflitDataIndex]?.alternativeTimeSlots,
            [date]: this.conflictData[
              getConflitDataIndex
            ]?.alternativeTimeSlots?.[date].map((d: any) => ({
              ...d,
              checked: d?.startTime === startTime && d?.endTime === endTime,
            })),
          },
        };
      }
    }
  }
  get sortedConflictData(): RecurrenceConflictInterface[] {
    return (
      this.conflictData.sort(
        (a: RecurrenceConflictInterface, b: RecurrenceConflictInterface) =>
          new Date(a?.startTime)?.getTime() - new Date(b?.startTime)?.getTime()
      ) || []
    );
  }
  get timeDuration() {
    return this.duration || this.programmareS$.duration$.value;
  }
}
