import { Physician } from './../../../../core/models/Physician.model';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import { RequestService } from 'src/app/core/services/request/request.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { IonInputConfig } from 'src/app/shared/models/components/ion-input-config';
import { appointmentEndpoints } from 'src/app/core/configs/endpoints';

@Component({
  selector: 'app-physicians-modal',
  templateUrl: './physicians-modal.component.html',
  styleUrls: ['./physicians-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhysiciansModalComponent implements OnInit, OnDestroy {
  config: IonInputConfig = {
    placeholder: 'Caută',
    type: 'search',
    inputMode: 'search',
    size: 100,
    clearable: false,
    inputClasses: '',
    minLength: 10,
    maxLength: 10,
    bgwhite: true,
    disabled: false,
    removeInputItemBaseLine: true,
    isInputFocused: false,
    debounce: 200,
    hidAssistiveText: true,
  };
  searchForm: FormGroup = this.fb.group({
    search: ['', [Validators.required]],
  });
  subscriptions = new Subscription();
  list$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  getPhysiciansList$: BehaviorSubject<Physician[]> = new BehaviorSubject<Physician[]>([]);
  isFetching = false;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private reqService: RequestService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.getPhysicians();
    // load check list to list
    this.updateData();
    this.subscriptions.add(this.searchForm.valueChanges
      .pipe(distinctUntilChanged()) // makes sure the value has actually changed.
      .subscribe(
        data => {
          if (data.search !== '') {
           this.list$.next([...this.searching(data.search)]);
          } else {
            this.updateData();
          }
        }
      ));
  }

  selectDataAndCloseModal(id: string) {
    this.closeModal(
      this.getDataForUse(id)
    );
  }

  closeModal(medicData: any = null) {
    this.modalController.dismiss({
      dismissed: true,
      medicData,
    });
  }

  getPhysicians() {
    this.reqService
      .get(appointmentEndpoints.getUserPhysicians)
      .subscribe(
        (d: any) => {
          if (d?.physicians?.length > 0) {
            this.getPhysiciansList$.next(d?.physicians);
          } else {
            this.noDataFoundNotification();
            this.getPhysiciansList$.next([]);
          }
          this.isFetching = false;
          this.updateData();
        },
        _err => {
          this.isFetching = false;
        }
      );
  }

  noDataFoundNotification() {
    this.toastService.presentToastWithNoDurationDismiss(
        'A apărut o eroare. Va rugăm să reîncercați.', 'error'
      );
  }

  searching(st: string) {
    return this.list$.value
    .filter(
      (item: any) => item.first.toLowerCase().includes(st.toLowerCase())

  );
  }
  updateData() {
        if (this.getPhysiciansList$.value.length > 0) {
          const p = this.getPhysiciansList$.value.map(
            (y: any) => ({
              id: y.physicianUID,
              first: `${y.firstName} ${y.lastName}`,
              second:  y.stencilNo,
            })
          );
          this.list$.next(
            [...p]
          );
        }
  }

  getDataForUse(id: string | number){
    return this.findData(
      this.getPhysiciansList$.value,
      'physicianUID',
      id
    );
  }

  findData(
    data: any,
    findBy: string,
    findData: any
    ) {
    return data ? (data.find(
      (v: any) => v[findBy] === findData
    ) || null) : null;
  }

  ngOnDestroy(): void {
    unsubscriberHelper(this.subscriptions);
  }

}
