import { Injectable } from '@angular/core';
import {
  addBusinessDays,
  addHours,
  addMonths,
  differenceInMilliseconds,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subBusinessDays,
  subSeconds,
} from 'date-fns';
import { get, isEqual, uniq, uniqBy } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  interval,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
} from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SidemenuService } from 'src/app/core/services/sidemenu/sidemenu.service';
import { distinctCheckObj } from '../../helpers/distinct-check.helper';
import { GetCabinetSchedulesResponseModel } from '../../models/getCabinetSchedules.response.model';
import { WEEK_START_DAY } from './../../../app.module';
import {
  appointmentEndpoints,
  cabinet,
  equipments,
  location,
  physicians,
  tipServiciiEndpoint,
} from './../../configs/endpoints';
import { unsubscriberHelper } from './../../helpers/unsubscriber.helper';
import {
  Appointment,
  AppointmentResponse,
  Equipment,
} from './../../models/appointment.interface';
import {
  Physician,
  PhysicianListResponse,
} from './../../models/Physician.model';
import { RequestService } from './../request/request.service';
export enum CalendarPeriod {
  DAY,
  LUCRATOARE,
  WEEK,
  LIST,
  MONTH,
}
@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  equipments$: BehaviorSubject<{ loaded: false; data: Array<Equipment> }> =
    new BehaviorSubject({ loaded: false, data: [] });
  medServices$: BehaviorSubject<{ loaded: false; data: Array<Equipment> }> =
    new BehaviorSubject({ loaded: false, data: [] });
  selectedDate: BehaviorSubject<string> = new BehaviorSubject(
    new Date().toString()
  );
  selectedPath: BehaviorSubject<string> = new BehaviorSubject(null);
  selectedPhysician: BehaviorSubject<Physician> = new BehaviorSubject(null);
  filterLocation: BehaviorSubject<any> = new BehaviorSubject(null);
  filterProgram: BehaviorSubject<string> = new BehaviorSubject(null);
  appointments$: BehaviorSubject<AppointmentResponse> = new BehaviorSubject({
    errorMessage: null,
    errorCode: null,
    insertedUID: null,
    insertedID: null,
    insertedData: null,
    warnnings: null,
    restrictions: null,
    operationUID: null,
    appointments: [],
    schedules: [],
    phyFreeDays: [],
  });
  cabinetAppointment$: BehaviorSubject<AppointmentResponse> =
    new BehaviorSubject(null);
  compareQuery$: BehaviorSubject<any> = new BehaviorSubject(null);
  locations$: BehaviorSubject<any> = new BehaviorSubject(null);
  eventLists$: BehaviorSubject<Appointment[]> = new BehaviorSubject([]);
  selectedMonth: BehaviorSubject<string> = new BehaviorSubject('');
  eventCounts: BehaviorSubject<number> = new BehaviorSubject(0);
  showPicker: BehaviorSubject<boolean> = new BehaviorSubject(false);
  scroller$ = new BehaviorSubject(null);
  workHours: BehaviorSubject<any> = new BehaviorSubject(this.getWorkHours());
  listNavigation: BehaviorSubject<{ startDate?: any; endDate }> =
    new BehaviorSubject(null);
  pageLoaded$ = new Subject();
  calendarLoadData = {};
  comparativLoadData = {};
  currentListDate;
  appSubs: Subscription;
  cabSubs: Subscription;
  userDidSelectHourSegment$: BehaviorSubject<{
    date: Date;
    clickedTime: Date;
    isHoliday: boolean;
    locationUID: string;
  }> = new BehaviorSubject(null);
  forceFetch$: BehaviorSubject<Date> = new BehaviorSubject(null);
  appointmentFetchLoadingState$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  intervalSource$ = interval(300000);
  intervalSourceSub$: Subscription;
  cabinets$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  constructor(
    private reqS: RequestService,
    private authS: AuthService,
    private sideMenuS: SidemenuService
  ) {
    this.authS.loggedInPhysicianSubject.subscribe((ph) =>
      this.selectedPhysician.next(ph)
    );
    this.calendarLoader().subscribe((v) => {
      this.fetchCalendarAppointment(v);
    });
    this.listCalendarLoader();
    this.calendarComparisonLoader();
    this.sideMenuS.samePageNavigation$.subscribe((v) => {
      this.selectedPath.next(this.selectedPath.value);
    });
    this.getLocations()
      .pipe(catchError((err) => of(null)))
      .subscribe();
  }

  arrUniqueByKey(oldArray: Array<any>, newArray: Array<any>, key: string) {
    oldArray.forEach((v) => {
      const existing = newArray.findIndex((e) => e[key] === v[key]);
      if (existing === -1) {
        newArray.push(v);
      }
    });
  }

  getMedicalServices(physicianUID) {
    return this.reqS.post(tipServiciiEndpoint.getMedicalServices, {
      physicianUID,
    });
  }
  getClinicCNASMedicalServices(physicianUID) {
    return this.reqS.post(tipServiciiEndpoint.getClinicCNASMedicalServices, {
      physicianUID,
    });
  }
  getEquipments(physicianUID) {
    return this.reqS.post(equipments.getMedicalEquipment, { physicianUID });
  }
  getWorkHours() {
    const wh = localStorage.getItem('workHours');
    const obj: { appStartHour: any; appEndHour: any } = wh
      ? JSON.parse(wh)
      : { appStartHour: 0, appEndHour: 0 };
    return obj;
  }
  getUserPhysicians(): Observable<PhysicianListResponse> {
    return this.reqS.get(appointmentEndpoints.getUserPhysicians);
  }

  additionalDataLoader(physicianUID) {
    return forkJoin([
      this.getMedicalServices(physicianUID),
      this.getClinicCNASMedicalServices(physicianUID),
      this.getEquipments(physicianUID),
    ]);
  }
  calendarLoader() {
    return combineLatest([
      this.selectedPath,
      this.selectedDate,
      this.selectedPhysician,
      this.workHours,
    ]).pipe(
      distinctUntilChanged(),
      filter((vals) => (vals[0] && vals[2] && vals[3] ? true : false))
    );
  }

  listCalendarLoader() {
    combineLatest([
      this.selectedPath,
      this.listNavigation,
      this.selectedPhysician,
      this.workHours,
    ])
      .pipe(
        distinctUntilChanged(),
        filter((vals) =>
          vals[0] && vals[1] && vals[2] && vals[3] ? true : false
        )
      )
      .subscribe((vals) => {
        const selectedDates = vals[1];
        // These are currently ignored, as we need to request the whole day to get the holidays.
        const { appStartHour, appEndHour } = vals[3];
        const path = vals[0];
        if (path === 'lista') {
          const obj: any = {
            physicianUID: vals[2].physicianUID,
          };
          obj.StartDate = new Date(selectedDates.startDate).toISOString();
          obj.EndDate = new Date(selectedDates.endDate).toISOString();
          // on lista view we need do display cancelled appointments
          this.getAppointments(obj, true);
        }
      });
  }

  calendarComparisonLoader(newFetch = false) {
    return combineLatest([
      this.compareQuery$,
      this.selectedDate,
      this.selectedPath,
    ])
      .pipe(distinctUntilChanged(distinctCheckObj))
      .subscribe((vals) => {
        const compareQ = vals[0];
        const date = vals[1];
        const path = vals[2];
        if (
          ['comparativ', 'aparate', 'utilizatori', 'cabinet'].includes(path) &&
          date &&
          compareQ
        ) {
          const start = date
            ? startOfDay(new Date(date))
            : startOfDay(new Date());
          const end = date ? endOfDay(new Date(date)) : endOfDay(new Date());
          const reqData = {
            locationUID: compareQ.location,
            startDate: start.toISOString(),
            endDate: end.toISOString(),
          };
          if (
            !isEqual(reqData, this.comparativLoadData) ||
            !this.cabinetAppointment$.value ||
            newFetch
          ) {
            this.comparativLoadData = reqData;
            this.getCabinetAppointment(reqData);
          } else {
            this.cabinetAppointment$.next({
              ...this.cabinetAppointment$.value,
            });
          }
        }
      });
  }

  fetchCalendarAppointment(vals) {
    let selectedDate = vals[1];
    const { appStartHour, appEndHour } = vals[3];
    if (vals[1] === null) {
      selectedDate = new Date();
    }
    const path = vals[0];
    const obj: any = {
      physicianUID: vals[2].physicianUID,
    };
    let period = CalendarPeriod.MONTH;
    // TODO: Watch for performance issues and reduce the number of  months requested.
    // Run on a 3 months period, to be able to properly show weeks between months.
    // default for month view and list view
    const start = startOfMonth(
      addMonths(selectedDate ? new Date(selectedDate) : new Date(), 0)
    );
    const end = endOfMonth(
      addMonths(selectedDate ? new Date(selectedDate) : new Date(), 0)
    );
    obj.StartDate = new Date(start).toISOString();
    obj.EndDate = new Date(end).toISOString();
    // create request period based on calendar mode
    if (path === 'zi') {
      period = CalendarPeriod.DAY;
      obj.StartDate = startOfDay(new Date(selectedDate)).toISOString();
      obj.EndDate = endOfDay(new Date(selectedDate)).toISOString();
      this.getAppointments(obj, false, period);
    } else if (path === 'luna') {
      period = CalendarPeriod.MONTH;
      this.getAppointments(obj, false, period);
    } else if (path === 'saptamana') {
      obj.StartDate = startOfWeek(new Date(selectedDate), {
        weekStartsOn: WEEK_START_DAY,
      }).toISOString();
      obj.EndDate = endOfWeek(new Date(selectedDate), {
        weekStartsOn: WEEK_START_DAY,
      }).toISOString();
      period = CalendarPeriod.WEEK;
      this.getAppointments(obj, false, period);
    } else if (path === 'zile-lucratoare') {
      obj.StartDate = addBusinessDays(
        startOfWeek(new Date(selectedDate), { weekStartsOn: WEEK_START_DAY }),
        0
      ).toISOString();
      obj.EndDate = subBusinessDays(
        endOfWeek(new Date(selectedDate)),
        1
      ).toISOString();
      period = CalendarPeriod.LUCRATOARE;
      this.getAppointments(obj, false, period);
    }
  }

  async getAppointments(
    data = null,
    showDeletedAppointments = false,
    period = CalendarPeriod.MONTH,
    doCount = true
  ) {
    unsubscriberHelper(this.intervalSourceSub$);
    // Cache "request" to be able to speed things up
    const sameData =
      (data ? JSON.stringify(data) : null) ===
      (data ? JSON.stringify(this.calendarLoadData) : null);
    const isForceFetch = this.isForceFetchThreeSecondsAgo();
    const getDataState = isForceFetch || !sameData;
    data.showDeletedAppointments = showDeletedAppointments;
    if (data !== null && getDataState) {
      if (doCount) {
        this.eventCounts.next(0);
      }
      // update appointment loader
      this.appointmentFetchLoadingState$.next(true);
      this.appointments$.next(null);
      unsubscriberHelper(this.appSubs);
      this.appSubs = this.reqS
        .post(appointmentEndpoints.getAppointment, data)
        .pipe(
          mergeMap((res: any) => {
            this.cleanupRes(res);
            if (data.physicianUID) {
              return this.additionalDataLoader(data.physicianUID).pipe(
                map((v: [any[], any[], any[]]) => {
                  this.mapAdditionalDataToAppointments(res.appointments, ...v);
                  return res;
                }),
                catchError((err) => of(res))
              );
            } else {
              return res;
            }
          })
        )
        .subscribe(
          (res: any) => {
            // Only cache if we receive data.
            this.calendarLoadData = data;
            if (doCount) {
              this.eventCounts.next(
                this.processEventsCount(
                  res.appointments,
                  period,
                  parseISO(data.StartDate)
                )
              );
            }
            this.appointments$.next(res);
            this.eventLists$.next(res.appointments);
            // update appointment loader
            this.appointmentFetchLoadingState$.next(false);
          },
          () => {
            this.eventLists$.next([]);
            if (doCount) {
              this.eventCounts.next(0);
            }
            // update appointment loader
            this.appointmentFetchLoadingState$.next(false);
          }
        );
    } else if (doCount) {
      const events = this.appointments$.value
        ? this.appointments$.value.appointments
        : [];
      this.eventCounts.next(
        this.processEventsCount(events, period, parseISO(data.StartDate))
      );
    }
    this.intervalSourceSub$ = this.intervalSource$.subscribe(() => {
      this.calendarLoader()
        .pipe(take(1))
        .subscribe((v) => {
          this.fetchCalendarAppointment(v);
          this.listCalendarLoader();
        });
    });
  }

  processEventsCount(events, period: CalendarPeriod, date = new Date()) {
    let startDate = startOfMonth(date);
    let endDate = endOfMonth(date);

    switch (period) {
      case CalendarPeriod.LIST:
        break;
      case CalendarPeriod.DAY:
        startDate = startOfDay(date);
        endDate = endOfDay(date);
        break;
      case CalendarPeriod.MONTH:
        break;
      case CalendarPeriod.LUCRATOARE:
        startDate = date
          ? addBusinessDays(
              startOfWeek(new Date(date), { weekStartsOn: WEEK_START_DAY }),
              0
            )
          : addBusinessDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_START_DAY }),
              0
            );
        endDate = date
          ? subBusinessDays(endOfWeek(new Date(date)), 1)
          : subBusinessDays(endOfWeek(new Date()), 1);
        break;
      case CalendarPeriod.WEEK:
        startDate = date
          ? startOfWeek(new Date(date), { weekStartsOn: WEEK_START_DAY })
          : startOfWeek(new Date(), { weekStartsOn: WEEK_START_DAY });
        endDate = date
          ? endOfWeek(new Date(date), { weekStartsOn: WEEK_START_DAY })
          : endOfWeek(new Date(), { weekStartsOn: WEEK_START_DAY });
        break;
    }
    const countResult = events.filter((ev) => {
      const stt = new Date(ev.startTime);
      const ett = new Date(ev.endTime);
      return (
        (this.isBetween(stt, startDate, endDate) ||
          this.isBetween(ett, startDate, endDate) ||
          (differenceInMilliseconds(stt, startDate) <= 0 &&
            differenceInMilliseconds(ett, endDate) >= 0)) &&
        ev.isNote === false
      );
    }).length;
    return countResult;
  }

  isBetween(date, start, end) {
    return (
      differenceInMilliseconds(date, start) >= 0 &&
      differenceInMilliseconds(date, end) <= 0
    );
  }
  mapAdditionalDataToAppointments(
    appL,
    services = [],
    cnasServices = [],
    equipmentsList = []
  ) {
    const allServices = services.concat(cnasServices);
    appL.forEach((app) => {
      if (get(app, 'servicesUIDs', []).length) {
        app.serviceNames = uniq(app.servicesUIDs)
          .map((s) => {
            const serviceV = allServices.find(
              (svc) => svc.serviceUID === s || svc.cnasMedicalServiceUID === s
            );
            return serviceV
              ? get(
                  serviceV,
                  'serviceName',
                  get(serviceV, 'cnasMedicalServiceName', null)
                )
              : null;
          })
          .filter((v) => v);
      } else {
        app.serviceNames = [];
      }
      if (get(app, 'equipmentsUIDs', []).length) {
        app.equipmentNames = uniq(app.equipmentsUIDs)
          .map((e) => {
            const eqV = equipmentsList.find(
              (eq) => eq.equipmentUID === e || eq.uid === e
            );
            return eqV ? eqV.equipmentName : null;
          })
          .filter((v) => v);
      } else {
        app.equipmentNames = [];
      }
    });
  }
  cleanupRes(res) {
    const appS = uniqBy(res.appointments, 'uid');
    res.appointments = appS;
    return res;
  }

  getCabinetAppointment(query, date = null) {
    const obj = {
      ...query,
    };
    unsubscriberHelper(this.cabSubs);
    this.cabSubs = this.reqS
      .post(appointmentEndpoints.getAppointment, obj)
      .pipe(
        catchError((er1) => of(null)),
        map((res: AppointmentResponse) => this.cleanupRes(res)),
        mergeMap((res2: AppointmentResponse) =>
          this.mapAdditionalSchedules(obj).pipe(
            map((sched) => {
              res2.postCabinetSchedule = sched[0] ? sched[0] : [];
              res2.postPhysSchedule = sched[1] ? sched[1] : [];
              return res2;
            })
          )
        )
      )
      .subscribe((res: any) => {
        this.cleanupRes(res);
        this.cabinetAppointment$.next(res);
      });
  }
  // In here we also need to add the equipment schedules request.
  mapAdditionalSchedules(obj) {
    const cabinetReq = this.reqS
      .post<Array<GetCabinetSchedulesResponseModel>>(
        cabinet.getCabinetsSchedules,
        obj
      )
      .pipe(catchError((e1) => of([])));
    const physReq = this.reqS
      .post<any>(physicians.getPhysicianSchedule, obj)
      .pipe(catchError((e2) => of([])));
    return forkJoin([cabinetReq, physReq]);
  }

  getLocations() {
    return this.locations$.pipe(
      switchMap((l) => {
        if (l === null) {
          return this.reqS.get(location.getLocations).pipe(
            catchError((err) => of(null)),
            map((l2) => {
              const locs = l2 ? l2.locations : null;
              if (locs !== l) {
                this.locations$.next(locs);
              }

              return locs;
            }),
            distinctUntilChanged(distinctCheckObj),
            take(1)
          );
        } else {
          return of(l);
        }
      }),
      distinctUntilChanged(distinctCheckObj),
      take(1)
    );
  }

  //add color-code styling for cards
  colorCode(code, additionalClasses = '') {
    return additionalClasses + ' color-code-' + code;
  }

  //set color code depending on appointment code from BE
  iconCode(code) {
    switch (code) {
      case 1:
        return 'partially-paid';
        break;
      case 2:
        return 'green-bg';
        break;
      case 3:
        return 'notificare';
        break;
      case 4:
        return 'cnas';
        break;
      case 5:
        return 'nou';
        break;
      case 6:
        return 'recurenta';
        break;
      case 7:
        return 'note';
        break;
      case 8:
        return 'cancelled';
        break;
      case 9:
        return 'user-redirect';
        break;
      case 10:
        return 'computer';
    }
  }
  setForceFetchAppointments() {
    this.forceFetch$.next(new Date());
  }
  isForceFetchThreeSecondsAgo() {
    const threeSecondsAgo = new Date(new Date().getTime() - 3 * 1000);
    return this.forceFetch$.getValue() > threeSecondsAgo;
  }
  filterHolidays(holidays, date) {
    // Holidays are based on physicianUID.
    // Prefiltering will be done based on the type of calendar being viewed.
    return holidays.filter((v) => {
      const parsedS = parseISO(v.startDate);
      const parsedE = parseISO(v.endDate);
      const isSame =
        differenceInMilliseconds(date, parsedS) >= 0 &&
        differenceInMilliseconds(date, parsedE) <= 0;
      return isSame;
    });
  }
  getSchedulesAtSpecificHour(date: Date | string) {
    /*
     * looks like there is an issue as regards how schedules
     * is sent, some days do not have schedules, so we need to use
     * day value to filter the schedules
     * ::dow: day of week which is in number 1 = monday, 7 = sunday
     */
    if (this.appointments$.getValue()) {
      const physicianSchedules = this.appointments$
        .getValue()
        ?.schedules.map((v: any) => ({
          ...v,
          start: parseInt(v.start, 10),
          end: parseInt(v.end, 10),
        }))
        .filter((s: any) => s?.dow === new Date(date).getDay());

      const isBreakTimeSchedule = physicianSchedules.filter(
        (e) => e.isBreakTime
      )[0];
      if (
        new Date(date).getHours() >= isBreakTimeSchedule?.start &&
        new Date(date).getHours() < isBreakTimeSchedule.end
      ) {
        return [];
      } else {
        return physicianSchedules.filter(
          (s: any) =>
            s?.dow === new Date(date).getDay() &&
            isWithinInterval(new Date(date), {
              start: addHours(
                startOfDay(new Date(date)),
                parseInt(s.start, 10)
              ),
              end: subSeconds(
                addHours(startOfDay(new Date(date)), parseInt(s.end, 10)),
                1
              ),
            })
        );
      }
    } else {
      return [];
    }
  }

  getCabinets() {
    return this.reqS.get<any[]>(cabinet.getCabinets).pipe(
      catchError((err) => of(null)),
      map((cab) => {
        this.cabinets$.next(cab);
        return cab;
      })
    );
  }
}
