import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectieServiciiModalComponent } from './selectie-servicii-modal/selectie-servicii-modal.component';
import { InfoPacientModalComponent } from './info-pacient-modal/info-pacient-modal.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BizInputsModule } from '../../modules/biz-inputs/biz-inputs.module';
import { BizRadioModalComponent } from './biz-radio-modal/biz-radio-modal.component';
import { MedicModalComponent } from './medic-modal/medic-modal.component';
import { PacientComponent } from './pacient/pacient.component';
import { NewPacientModalComponent } from './new-pacient-modal/new-pacient-modal.component';
import { GrupNouModalComponent } from './grup-nou-modal/grup-nou-modal.component';
import { PacientViewModalComponent } from './pacient-view-modal/pacient-view-modal.component';
import { BizSearchableRadioModalComponent } from './biz-searchable-radio-modal/biz-searchable-radio-modal.component';
import { CabinetComponent } from './cabinet/cabinet.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgCalendarModule } from 'ionic2-calendar';
import { CabinetNotifyComponent } from './cabinet-notify/cabinet-notify.component';
import { ConflictProgramariComponent } from './conflict-programari/conflict-programari.component';
import { DatePickerModalComponent } from './date-picker-modal/date-picker-modal.component';
import { PhysiciansModalComponent } from './physicians-modal/physicians-modal.component';
import { RecurentaComponent } from './recurenta/recurenta.component';
import { NumarDeProgramariComponent } from './numar-de-programari/numar-de-programari.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ModDePlataComponent } from './mod-de-plata/mod-de-plata.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { RedirectionareComponent } from './redirectionare/redirectionare.component';
import { QuestionnaireModalComponent } from './questionnaire-modal/questionnaire-modal.component';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { GroupMembersModalComponent } from './group-members-modal/group-members-modal.component';


@NgModule({
  declarations: [
    SelectieServiciiModalComponent,
    InfoPacientModalComponent,
    BizRadioModalComponent,
    MedicModalComponent,
    PacientComponent,
    NewPacientModalComponent,
    GrupNouModalComponent,
    PacientViewModalComponent,
    BizSearchableRadioModalComponent,
    CabinetComponent,
    CabinetNotifyComponent,
    ConflictProgramariComponent,
    DatePickerModalComponent,
    PhysiciansModalComponent,
    RecurentaComponent,
    NumarDeProgramariComponent,
    ConfirmationModalComponent,
    ModDePlataComponent,
    RedirectionareComponent,
    QuestionnaireModalComponent,
    GroupMembersModalComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    BizInputsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgCalendarModule,
    NgxIntlTelInputModule,
  ],
  exports: [
    SelectieServiciiModalComponent,
    InfoPacientModalComponent,
    BizRadioModalComponent,
    MedicModalComponent,
    PacientComponent,
    NewPacientModalComponent,
    GrupNouModalComponent,
    PacientViewModalComponent,
    BizSearchableRadioModalComponent,
    CabinetComponent,
    CabinetNotifyComponent,
    ConflictProgramariComponent,
    DatePickerModalComponent,
    PhysiciansModalComponent,
    RecurentaComponent,
    NumarDeProgramariComponent,
    ConfirmationModalComponent,
    ModDePlataComponent,
    QuestionnaireModalComponent,
    GroupMembersModalComponent,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'ro' },
    InAppBrowser
  ],
})
export class ModalModule {}
