import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CalendarDataService {
  modalClickedDate$: BehaviorSubject<{ date: Date; clearState: 'before' | 'after' }> =
    new BehaviorSubject({ date: null, clearState: 'before' });

  constructor() { }

  PrepareDays(viewDate) {
      const days: Date[] = [];
      moment.locale('ro');
      // push 30 days before viewDate into days array
    for (let i = 0; i < 365; i++) {
        const date = moment(viewDate).subtract(i, 'days').toDate();
        days.push(date);
      }
      //  push 30 days from viewDate to days array
    for (let i = 1; i <= 365; i++) {
        days.push(moment(viewDate).add(i, 'days').toDate());
      }
      days.sort((a, b) => a.getTime() - b.getTime());
    return of(days);
  }

  prepareMonths(viewDate) {
      const months: Date[] = [];
      moment.locale('ro');
      const monthsRequired = 12;
      for (let i = 1; i <= monthsRequired; i++) {
        months.push(moment().subtract(i, 'months').toDate());
      }
      for (let i = 1; i <= monthsRequired; i++) {
        months.push(moment().add(i, 'months').toDate());
      }
      months.push(moment(viewDate).startOf('day').toDate());
      months.sort((a, b) => a.getTime() - b.getTime());
    return of(months);
  }

  prepareWeeks(viewDate) {
      const weeks: Date[] = [];
      moment.locale('ro');
      const weeksRequired = 36;
      const firstDayOfWeek = moment(viewDate).startOf('week');
      for (let i = 1; i <= weeksRequired; i++) {
        weeks.push(moment(firstDayOfWeek).subtract(i, 'weeks').toDate());
      }
      for (let i = 1; i <= weeksRequired; i++) {
        weeks.push(moment(firstDayOfWeek).add(i, 'weeks').toDate());
      }
      weeks.push(viewDate);
      weeks.sort((a, b) => a.getTime() - b.getTime());
    return of(weeks);
  }

}
