/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CNAS } from 'src/app/core/models/cnas.service.model';
import { Cuplata } from 'src/app/core/models/cuplata.service.model';
import { SelectieServiciiModalComponent } from '../modal/selectie-servicii-modal/selectie-servicii-modal.component';
import { BizSelectieServiciiConfig } from '../../models/components/biz-selectie-servicii.config';
import { get, isNaN, isNumber, uniqBy } from 'lodash';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { Service } from 'src/app/core/models/appointment.interface';
import { ModDePlataComponent } from '../modal/mod-de-plata/mod-de-plata.component';
import { AdaugaProgrammarePermissions } from '@app/core/models/adauga-programmare.permission';
import { AppointmentService } from '@app/core/services/appointment.service';
@Component({
  selector: 'app-add-service-selection',
  templateUrl: './add-service-selection.component.html',
  styleUrls: ['./add-service-selection.component.scss'],
})
export class AddServiceSelectionComponent implements OnInit {
  @Input() configOptions: any;
  @Input() serviceType: number;
  @Input() set tipServicesData(d: Cuplata[] | CNAS[]) {
    this.servicesData = d ? d : [];
    this.appointmentsServices = d?.length > 0 ? this.appointmentsServices : [];
    if (this.serviceType === 1) {
      this.servicesData = this.concatBlIDWithServiceID(this.servicesData);
    }
  }

  @Input() set selectedServicii(d: string[]) {
    this.selectedValue = d ? d : [];
  }

  @Input() set privateServices(data: any[]) {
    this.makeAppointmentsServices(data);
  }

  @Input()
  set config(c: any) {
    this.vConfig = c;
  }
  get config() {
    return this.vConfig;
  }
  @Output() selectedValueChange = new EventEmitter<string[]>();
  @Input() disabled: boolean;
  @Input() permission: AdaugaProgrammarePermissions;
  @Input() physicianUID: string;
  @Input() personUID: string;
  @Output() sendPaymentMethodData: EventEmitter<any[]> = new EventEmitter();
  toUseConfig = {
    idKey: 'locationUID',
    labelKey: 'locationName',
  };
  tipServiciiOptionConfig: Array<BizSelectieServiciiConfig> = [
    {
      firstKey: 'serviceName',
      secondKey: 'price',
      currencyKey: 'currency',
      idKey: 'serviceUID',
    },
    {
      firstKey: 'cnasMedicalServiceName',
      secondKey: 'medicalServiceCategory',
      thirdKey: '',
      idKey: 'cnasMedicalServiceCode',
    },
  ];

  selectedValue: any[] = [];
  servicesData: Array<any> = [];
  appointmentsServices: Array<any> = [];
  private vConfig: any;

  constructor(
    public modalController: ModalController,
    private toastService: ToastService,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {}

  makeAppointmentsServices(data: any[]) {
    this.appointmentsServices = this.concatBlIDWithServiceID(data.map((service) =>
      this.appointmentService.mapServicesToPaymentMethod(service)));
    this.sendPaymentMethodData.emit(this.splitBlIDAndServiceID(this.appointmentsServices));
  }

  concatBlIDWithServiceID(items: any[]) {
    return items.map((v) => {
      if (v?.bL_Physician_ServicesID && !v?.combinedKey) {
        return {
          ...v,
          serviceUID: `${v?.serviceUID}:${v?.bL_Physician_ServicesID}`,
          combinedKey: true
        };
      } else {
        return v;
      }
    });
  }

  splitBlIDAndServiceID(items: any[]) {
    return items.map((v) => {
      const split: any[] = v?.serviceUID?.split(':');
      if (split?.length > 1) {
        return {
          ...v,
          serviceUID: split[0],
          bL_Physician_ServicesID: split[1],
        };
      } else {
        return v;
      }
    });
  }

  async presentSelectionModal() {
    if (this.servicesData.length > 0) {
      const modal = await this.modalController.create({
        component: SelectieServiciiModalComponent,
        cssClass: 'biz-modal-class',
        componentProps: {
          checkList: this.isUniqServiceData,
          config: this.getTipServiciiConfigType(this.serviceType),
          selectedValue: this.selectedValue,
        },
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data && data.checkedValue) {
        const { checkedValue } = data;
        this.selectedValue = checkedValue;
        this.setDefaultValue(checkedValue);
        this.sendDataToParent();
      }
    } else {
      this.toastService.presentToastWithDurationDismiss(
        'Nu există servicii de acest tip.',
        'error'
      );
    }
  }

  setDefaultValue(data: string[]) {
    const arr = [];
    for (const id of data) {
      const newObj = {
        serviceUID: this.servicesData.find((sUId) => sUId[this.getTipServiciiConfigType(this.serviceType).idKey] == id)?.serviceUID,
        [this.getTipServiciiConfigType(this.serviceType).idKey]: id,
        quantity: 1,
        paymentMethod: 'cashCard',
      };
      if (this.serviceType === 1) {
        newObj.price = this.servicesData.find((sUId) => sUId[this.getTipServiciiConfigType(this.serviceType).idKey] == id)?.price;
      }
      arr.push(newObj);
    }
    this.appointmentsServices = arr.map((service) => {
      const foundIndex = this.appointmentsServices.findIndex((item) =>
        item[`${this.getTipServiciiConfigType(this.serviceType).idKey}`]
        === service[`${this.getTipServiciiConfigType(this.serviceType).idKey}`]);
      if (foundIndex === -1) {
        return service;
      }
      return this.appointmentsServices[foundIndex];
    }
    );
  }

  get isUniqServiceData() {
    return this.concatBlIDWithServiceID(this.servicesData);
    // return (uniqBy(
    //   this.servicesData,
    //   this.getTipServiciiConfigType(this.serviceType).idKey
    // ) || []);
  }

  get selectedServices() {
    return this.isUniqServiceData
      .filter((v: any) => {
        // make sure id is a string
        let id = v[`${this.getTipServiciiConfigType(this.serviceType).idKey}`];
        id = isNumber(id) ? id.toString() : id;
        return this.selectedValue.includes(
          id
        );
      }
      )
      .map((v) => ({
        first: get(
          v,
          `${this.getTipServiciiConfigType(this.serviceType).firstKey}`,
          ''
        ),
        second: `${get(
          v,
          `${this.getTipServiciiConfigType(this.serviceType).secondKey}`,
          ''
        )} ${get(
          v,
          `${this.getTipServiciiConfigType(this.serviceType)?.currencyKey}`,
          ''
        )}`,
        third: get(
          v,
          `${this.getTipServiciiConfigType(this.serviceType).thirdKey}`,
          null
        ),
        id: get(
          v,
          `${this.getTipServiciiConfigType(this.serviceType).idKey}`,
          ''
        ),
      }));
  }

  getPaymentMethodName(item) {
    item.paymentMethod = this.appointmentsServices.find((v) => v?.serviceUID === item.id)?.paymentMethod;
    item.quantity = this.appointmentsServices.find((v) => v?.serviceUID === item.id)?.quantity;
    if (item?.paymentMethod === 'servicePackage' && this.serviceType === 1) {
      return 'Pachet de servicii';
    }
    else if (item?.paymentMethod === 'partnerCompany' && this.serviceType === 1) {
      return 'Plata prin companie partenera';
    }
    else if (item?.paymentMethod === 'insuranceCompany' && this.serviceType === 1) {
      return 'Printr-o companie de asigurări';
    }
    else if (item?.paymentMethod === 'cashCard' && this.serviceType === 1) {
      return 'Numerar/card în clinică/Link de plata online';
    }
    else {
      return null;
    }
  }

  getPaymentMethodQuantity(item) {
    item.quantity = this.appointmentsServices.find((v) => v?.serviceUID === item.id)?.quantity;
    if (item?.quantity) {
      return `${item?.quantity}x`;
    }
    return '1x';
  }

  calQuantityToPrice(item: any) {
    const quantity = item.quantity = this.appointmentsServices.find((v) => v?.serviceUID === item.id)?.quantity || 1;
    const price = item.second;
    const currency = price.split(' ')[price.split(' ').length - 1];
    if (price && !isNaN(parseInt(price.replace(/\D/g, ''), 10)) && quantity) {
      return `${parseInt(price.replace(/\D/g, ''), 10) * quantity} ${currency}`;
    } else {
      return price;
    }
  }

  unCheckItem(data: string): void {
    if (typeof data !== null && data !== '') {
      const indexOfData = this.selectedValue.findIndex(
        (v: string) => v === data
      );
      if (indexOfData > -1) {
        this.selectedValue.splice(indexOfData, 1);
      }
    }
    this.appointmentsServices = this.appointmentsServices.filter((v: any) => v.serviceUID !== data);
    this.sendDataToParent();
  }
  get isSericeDataAvailable() {
    return this.servicesData.length > 0 ? true : false;
  }
  getTipServiciiConfigType(d: number = this.configOptions[0].id) {
    switch (d) {
      case this.configOptions[0].id:
        return this.tipServiciiOptionConfig[0];
      case this.configOptions[1].id:
        return this.tipServiciiOptionConfig[1];
      default:
        return this.tipServiciiOptionConfig[1];
    }
  }

  async openModeOfPaymentModal(item) {
    const modal = await this.modalController.create({
      component: ModDePlataComponent,
      cssClass: 'biz-modal-class',
      componentProps: {
        permission: this.permission,
        physicianUID: this.physicianUID,
        personUID: this.personUID,
        serviceUID: item.id,
        selectedAppointmentsServices: this.appointmentsServices.find((v: any) => v?.serviceUID === item.id),
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.appointmentsServices = this.findAndReplace(this.appointmentsServices, data?.paymentMethodData?.appointmentsService);
    this.sendPaymentMethodData.emit(this.splitBlIDAndServiceID(this.appointmentsServices));
  }

  findAndReplace(arr, newData) {
    if (newData) {
      const index = arr.findIndex((v: any) => v.serviceUID === newData.serviceUID);
      if (index > -1) {
        const price = get(this.concatBlIDWithServiceID(this.servicesData).find((v: any) => v.serviceUID === newData.serviceUID), 'price', 0)
          * get(newData, 'quantity', 1);
        arr[index] = { ...newData, price };
      } else {
        arr.push(newData);
      }
    }
    return arr;
  }

  sendDataToParent() {
    this.selectedValueChange.emit(this.selectedValue);
    this.sendPaymentMethodData.emit(this.splitBlIDAndServiceID(this.appointmentsServices));
  }
  get disableStatus() {
    return this.disabled || false;
  }
}
