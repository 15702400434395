import { AuthService } from './../services/auth/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isBoolean } from 'lodash';



@Injectable({ providedIn: 'root' })
export class UndoVersionGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authenticationService.isInMaintainanceOrBelowMinVersion().pipe(
      take(1),
      map(isInMaintainanceOrBelowMinVersion => {
        if (isBoolean(isInMaintainanceOrBelowMinVersion)) {
          this.router.navigate(['/calendar']);
          return false;
        }
        return true;
      }
      )
    );
  }
}

