import { WEEK_START_DAY } from './../../../app.module';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
} from 'angular-calendar';
import { getISODay, isSameDay } from 'date-fns';
import { get, has, set } from 'lodash';
import { Subscription } from 'rxjs';
import { filter, tap, throttleTime } from 'rxjs/operators';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import { CalendarService } from './../../../core/services/calendar/calendar.service';
import { PlatformService } from '../../../core/services/programmare/platform/platform.service';
import { DayViewSchedulerComponent } from './day-view-scheduler.component';
import moment from 'moment';
import { dayInAWeekWithDate } from '@app/core/helpers/date.helper';
import { AuthService } from '@app/core/services/auth/auth.service';

const colors: any = {
  green: {
    primary: '#D5EED1',
    secondary: '#2EA81B',
  },
  blue: {
    primary: '#D6E9FE',
    secondary: '#3093F8',
  },
  yellow: {
    primary: '#e7d597',
    secondary: '#FFC715',
  },
  orange: {
    primary: '#ecb986',
    secondary: '#F77C00',
  },
  transparent: {
    primary: 'white',
    secondary: 'white',
  },
};
@Component({
  selector: 'app-comparativ',
  templateUrl: './comparativ.component.html',
  styleUrls: ['./comparativ.component.scss'],
})
export class ComparativComponent implements OnInit, OnDestroy {
  @Input() activePage;
  @ViewChild(DayViewSchedulerComponent) calendarRef;
  weekStartsOn = WEEK_START_DAY;
  viewDate = new Date();
  emptyPlaceHolder = [
    {
      id: 0,
      name: '',
      title: '',
      color: '',
    },
  ];
  users = [];
  allUsers = [];
  numDisplay = 5;
  currentIndex = 0;
  isMobile = false;
  events: CalendarEvent[] = [];
  allEvents: CalendarEvent[] = [];
  appointmentResponse: any;
  schedules: any;
  holidays: any;
  startEndTime = { appStartHour: 0, appEndHour: 24 };

  dataSubs$: Subscription;
  constructor(
    private calS: CalendarService,
    private pS: PlatformService,
    private authS: AuthService
  ) {}

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.events = [...this.events];
  }

  userChanged({ event, newUser }) {
    event.color = newUser.color;
    event.meta.user = newUser;
    this.events = [...this.events];
  }

  ngOnInit() {
    this.calS.calendarComparisonLoader(true);
    this.startEndTime = JSON.parse(localStorage.getItem('workHours'));
    this.loadEvent();
    this.pS.isDesktopWidth$.subscribe((e) => {
      this.isMobile = e ? false : true;
      this.numDisplay = e ? 10 : 5;
    });
    this.calS.scroller$.pipe(filter((v) => v)).subscribe((x) => {
      if (this.calendarRef) {
        this.calendarRef.swiperTooltipHide();
      }
    });
  }

  loadEvent() {
    unsubscriberHelper(this.dataSubs$);
    this.dataSubs$ = this.calS.cabinetAppointment$
      .pipe(
        tap((x) => {
          this.calS.getCabinets().subscribe();
          if (this.authS.userPhysicians$.getValue().init === false) {
            this.authS.getUserPhysicians().subscribe();
          }
        }),
        filter((v) => {
          let p = 'equipment';
          switch (this.activePage) {
            case 'aparate':
              p = 'equipment';
              break;
            case 'cabinet':
              p = 'cabinet';
              break;
            case 'utilizatori':
              p = 'physician';
              break;
          }
          // Make sure we're on the same page.
          const comp = this.calS.compareQuery$.value;
          const p2 = comp ? comp.program : 'equipment';
          return p === p2;
        }),
        throttleTime(10)
      )
      .subscribe((e: any) => {
        this.viewDate = new Date(
          this.calS.selectedDate.value
            ? this.calS.selectedDate.value
            : new Date()
        );
        let schedules = e?.schedules ? e?.schedules : [];
        const cabSchedules = e?.postCabinetSchedule
          ? e?.postCabinetSchedule
          : [];
        const physSchedules = e?.postPhysSchedule ? e?.postPhysSchedule : [];
        this.appointmentResponse = e;
        this.holidays = [];
        const dow = getISODay(this.viewDate);
        if (e !== null) {
          this.currentIndex = 0;
          const comp = this.calS.compareQuery$.value;
          let p = comp ? comp.program : 'equipment';
          //show schedule based on comparativ selection
          switch (comp.program) {
            case 'cabinet':
              p = 'cabinet';
              schedules = schedules.concat(cabSchedules).concat(physSchedules);
              break;
            case 'equipment':
              p = 'equipment';
              break;
            case 'physician':
              p = 'physician';
              schedules = this.getMyPhysicians(schedules.concat(cabSchedules).concat(physSchedules));
              // This only applies to our case.
              this.holidays = e?.phyFreeDays ? e?.phyFreeDays : [];
              break;
            default:
              p = 'equipment';
              break;
          }
          const fullProgramList = e.appointments.concat(schedules).map((v) => {
            if (!has(v, 'physicianName') && has(v, 'physicianFirstName')) {
              set(
                v,
                'physicianName',
                get(v, 'physicianLastName', '') +
                  ' ' +
                get(v, 'physicianFirstName', '')
              );
            }
            return v;
          });
          this.allEvents = this.mapAppointments(e.appointments || [], p, schedules || []);
          this.allUsers = this.mapProgram(fullProgramList, p);
          this.schedules = this.mapSchedules(schedules, p, dow);
          if (this.allEvents.length > 0) {
            this.events = this.allEvents.slice();
            this.users = this.allUsers.slice();
            this.currentIndex = this.numDisplay;
            setTimeout(() => {
              this.calS.eventCounts.next(this.allEvents.filter((v) => !v?.meta?.isNote).length);
            }, 500);
          } else {
            this.events = [];
            this.users = this.emptyPlaceHolder;
          }
        }
      });
  }

  // get physicians list that will appear on comparativ calendar
  getMyPhysicians(schedules: any[]) {
    return schedules.filter(f => this.authS.userPhysicians$.getValue().physicians.some(item => item.physicianUID === f.physicianUID));
  }

  mapAppointments(appointments: any[] = [], field, schedules = []) {
    // filter out the appointments that do not have physician uid for the medici/avocati view
    if (field === 'physician') {
      appointments = appointments.filter((v) => v.physicianUID !== '00000000-0000-0000-0000-000000000000');
    }

    if (field !== 'cabinet') {
      schedules = [];
    }

    const uidK = field + 'UID';
    const nameK = field + 'Name';
    const arrayOfDaysInWeek = dayInAWeekWithDate(moment(this.viewDate).startOf('day').toDate());
    if (appointments.length > 0 || schedules.length > 0) {
      const eventList = appointments.concat(schedules.filter((v) => v?.cabinetsScheduleUID !== null
        && v?.cabinetsScheduleUID !== undefined
        && this.calS.compareQuery$.getValue().location === v.locationUID
        && v.dayID === arrayOfDaysInWeek.findIndex((day) => isSameDay(day, this.viewDate))))
        .filter((ap) => ap[uidK] !== null && ap[uidK] !== undefined)
        .map((apt, i) => {
          let title = '';
          if (field !== 'physician') {
            const phys = get(apt, 'physicianName', '');
            title = this.acronym(phys, 'physician');
          }
          if ((field === 'physician' || field === 'cabinet') && apt.isNote) {
            title = get(apt, 'comment', '');
          }
          // appointment card
          return {
            title,
            color: {
              secondary: apt.colorCode ? this.calS.colorCode(apt.colorCode, 'full-bg')
                : this.calS.colorCode('2CS', 'full-bg'),
              primary: apt.colorCode ? this.calS.colorCode(apt.colorCode, 'full-bg')
                : this.calS.colorCode('2CS', 'full-bg'),
            },
            start: apt.startTime ? new Date(apt.startTime) :
              moment(this.viewDate).set('hours', apt.startHour).set('minutes', apt.startMin).toDate(),
            end: apt.endTime ? new Date(apt.endTime) :
              moment(this.viewDate).set('hours', apt.endHour).set('minutes', apt.endMin).toDate(),
            meta: {
              user: {
                id: apt[uidK],
                name: apt[nameK] || '',
                color: colors.yellow,
              },
              isNote: apt?.isNote ? true : false,
            },
            resizable: {
              beforeStart: false,
              afterEnd: false,
            },
            draggable: false,
          };
        });
      return eventList;
    } else {
      return [];
    }
  }

  mapSchedules(schedules, field, dow) {
    const uidK = field + 'UID';
    const nameK = field + 'Name';
    if (schedules.length > 0) {
      const eventList = schedules
        .filter(
          (ap) =>
            (ap[uidK] !== null && ap[uidK] !== undefined) || ap.dow === dow
        )
        .map((sch, i) => ({
          ...sch,
          ...{
            meta: {
              user: {
                id: sch[uidK],
                name: sch[nameK] || '',
                type: field,
              },
            },
          },
        }));
      return eventList;
    } else {
      return [];
    }
  }

  mapProgram(appointments, field) {
    const uidK = field + 'UID';
    const nameK = field + 'Name';
    const filteredItems = [];

    // filter out the appointments that do not have physician uid for the medici/avocati view
    if (field === 'physician') {
      appointments = appointments.filter((v) => v.physicianUID !== '00000000-0000-0000-0000-000000000000');
      appointments = appointments.filter(f =>
        this.authS.userPhysicians$.getValue().physicians.some(item => item.physicianUID === f.physicianUID));
    }

    if (field === 'cabinet') {
      this.calS.cabinets$.getValue()
        .filter((ap) => (ap[uidK] !== null && ap[uidK] !== undefined) && this.calS.compareQuery$.getValue().location === ap.locationUID)
        .forEach((apt, i) => {

          const existing = filteredItems.find((us) => us.id === apt[uidK]);
          if (!existing) {
            filteredItems.push({
              id: apt[uidK],
              name: this.acronym(apt[nameK], field),
              title: this.acronym(apt[nameK], field),
              fullName: apt[nameK] || '',
              color: colors.yellow,
            });
          }
        });
      return filteredItems;
    } else {
    appointments
      .filter((ap) => ap[uidK] !== null && ap[uidK] !== undefined)
      .forEach((apt, i) => {
        const existing = filteredItems.find((us) => us.id === apt[uidK]);
        if (!existing) {
          filteredItems.push({
            id: apt[uidK],
            name: this.acronym(apt[nameK], field),
            title: this.acronym(apt[nameK], field),
            fullName: apt[nameK] || '',
            color: colors.yellow,
          });
        }
      });
    return filteredItems;
  }
  }

  range(start, end, step = 1) {
    const output = [];
    if (typeof end === 'undefined') {
      end = start;
      start = 0;
    }
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  }

  acronym(text, field) {
    if (text === null || text === undefined) {
      return '';
    }
    if (field === 'physician') {
      const res = text
        .split(/\s/)
        .reduce((accumulator, word) => accumulator + word.charAt(0) + '.', '');
      return res.slice(0, res.length - 1).replace('-.', '');
    } else {
      return text.slice(0, 3);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    unsubscriberHelper(this.dataSubs$);
  }
  customClickHandler(evt) {
    let userId = null;
    switch (evt?.type) {
      case 'event':
        const userE = evt?.event?.event.meta?.user;
        userId = userE ? userE.physicianUID : null;
        break;
      case 'segment':
        const user = evt?.user;
        userId = user ? user.physicianUID : null;
        break;
      default:
        break;
    }

    if (this.activePage === 'utilizatori' && userId) {
      // TBD
    }
  }
}
