import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, forkJoin, Subscription, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import {
  appointmentEndpoints,
  medicalSpecialities,
  physicians,
} from 'src/app/core/configs/endpoints';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import { MedicalSpecialitiesModel } from 'src/app/core/models/medical.specialities.model';
import { RequestService } from 'src/app/core/services/request/request.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { IonInputConfig } from 'src/app/shared/models/components/ion-input-config';
import { IonSelectConfig } from 'src/app/shared/models/components/ion-select-config';
import { IonTextItem } from 'src/app/shared/models/components/ion-text-item';
import { get } from 'lodash';
import { DictionaryService } from 'src/app/core/services/dictionary/dictionary.service';

@Component({
  selector: 'app-medic-modal',
  templateUrl: './medic-modal.component.html',
  styleUrls: ['./medic-modal.component.scss'],
})
export class MedicModalComponent implements OnInit, OnDestroy {
  @Input() set medicOptionTip(value: number | string) {
    if (value) {
      this.medicOptionFormControl.disable({ onlySelf: true, emitEvent: false });
      this.medicOptionFormControl.setValue(value, {
        emitEvent: true,
        onlySelf: true
      });
    }
  }
  list!: any;
  variant = this.dictionaryS.getVariant();
  dictionary = this.dictionaryS.getDictionary();
  isFormSubmitted = false;

  label: IonTextItem = {
    text: 'Default',
    classes: '',
    slot: 'end',
  };
  config: IonInputConfig = {
    placeholder: 'Caută',
    type: 'search',
    inputMode: 'search',
    size: 100,
    /* inputLabel: this.label, */
    clearable: false,
    inputClasses: '',
    bgwhite: true,
    disabled: false,
    removeInputItemBaseLine: true,
    isInputFocused: false,
    debounce: 500,
    hidAssistiveText: true,
  };
  searchForm: FormGroup = this.fb.group({
    search: ['', [Validators.required]],
  });
  subscriptions = new Subscription();
  medicConfig: IonSelectConfig = {
    inputLabel: {
      classes: 'd-none',
      text: `Tip ${this.dictionary?.sender[this.variant].toLowerCase()}`,
    },
    forceListItems: false,
    multiple: false,
    disabled: false,
    placeholder: `Tip: ${this.dictionary?.sender_option_1[this.variant]}`,
    alertOptions: {
      cssClass: '',
    },
    idKey: 'id',
    labelKey: 'label',
    useIcon: {
      name: 'chevron-down',
      classes: 'neutral-grey-medium-color',
    },
  };
  medicOption = [];

  listOfMedicOption$ = new BehaviorSubject([
    {
      id: 1,
      label: 'Intern (din clinică)',
      dataConfig: {
        type: 'getPhysicians',
        idKey: 'uid',
      },
    },
    {
      id: 2,
      label: 'Extern cu contract CNAS',
      dataConfig: {
        type: 'getThirdPartyPhysiciansList',
        idKey: 'id',
      },
    },
    {
      id: 3,
      label: 'Extern fără contract CNAS',
      dataConfig: {
        type: 'getExternalPhysiciansNoCNASList',
        idKey: 'id',
      },
    },
  ]);
  componentFormGroup: FormGroup = this.fb.group({
    medicOptionTip: ['', [Validators.required]],
    getThirdPartyPhysicians: ['', [Validators.required]],
  });
  medicOptionTipSubscription$: Subscription;
  list$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  getPhysiciansList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  getExternalPhysiciansNoCNASList$: BehaviorSubject<any[]> =
    new BehaviorSubject<any[]>([]);
  getThirdPartyPhysiciansList$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  isFetching = false;
  getMedicalSpecialities$: Subscription;
  getMedicalSpecialitiesData$: BehaviorSubject<MedicalSpecialitiesModel[]> =
    new BehaviorSubject<MedicalSpecialitiesModel[]>([]);
  getAllMedicalSpecialities$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  pageSize = 50;
  pageNo = 1;
  isLastFetch = false;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private reqService: RequestService,
    private toastService: ToastService,
    private dictionaryS: DictionaryService
  ) { }
  ngOnInit(): void {
    this.getMedicalSpecialities();
    this.toastService.presentToastWithNoDurationDismiss(
      'Selectați o opțiune',
      'success'
    );
    this.medicOptionTipSubscription$ = this.medicOptionFormControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((b: string) => {
        this.searchForm.patchValue({
          search: '',
        });
      });
    // load check list to list
    this.subscriptions.add(
      this.searchForm.valueChanges
        .pipe(
          distinctUntilChanged(),
          debounceTime(500) // debounce value
        ) // makes sure the value has actually changed.
        .subscribe((data) => {
          this.toastService.dismissToast();
          this.isLastFetch = false;
          this.getPhysicianType(this.medicOptionFormControl.value, data.search);
        })
    );
    this.medicOption = this.listOfMedicOption$.value;
    this.medicOption.forEach((val) => {
      if (val.id === 1) {
        val.label = this.dictionary.sender_option_1[this.variant];
      } else if (val.id === 2) {
        val.label = this.dictionary.sender_option_2[this.variant];
      }
      else if (val.id === 3) {
        val.label = this.dictionary.sender_option_3[this.variant];
      }
    });
    this.medicOption = this.medicOption.filter((val) => val.label !== '');
  }

  get medicOptionFormControl() {
    return this.componentFormGroup.get('medicOptionTip');
  }

  selectDataAndCloseModal(id: string | number) {
    this.toastService.dismissToast();
    this.closeModal(this.getDataForUse(id));
  }

  closeModal(medicData: any = null) {
    this.toastService.dismissToast();
    this.modalController.dismiss({
      dismissed: true,
      medicData,
      physicianTypeID: this.medicOptionFormControl.value || 0,
      medicName:
        get(medicData, 'name', '') ||
        `${get(medicData, 'firstName', '')} ${get(medicData, 'lastName', '')}`,
    });
  }
  getPhysicians(searchString: string = '') {
    // search by payload not working
    const payload = {
      name: searchString ? searchString.toUpperCase() : '',
    };

    this.reqService
      .post(physicians.getPhysicians, payload)
      .pipe(
        map((r: any) => r?.physicians),
        switchMap((res: any) =>
          this.reqService.get(appointmentEndpoints.getUserPhysicians).pipe(
            map((userPhResp: any) => userPhResp?.physicians),
            map((ph: any) => [
              ...res.map((p: any) => ({
                ...p,
                stencilNo:
                  ph.filter((d: any) => d.physicianUID === p.uid)[0]
                    ?.stencilNo || '',
              })),
            ])
          )
        )
      )
      .subscribe(
        (d: any) => {
          if (d?.length > 0) {
            this.getPhysiciansList$.next(d);
          } else {
            this.noDataFountNotification();
            this.getPhysiciansList$.next([]);
          }
          this.isFetching = false;
          this.updateData();
        },
        (_err) => {
          this.isFetching = false;
        }
      );
  }
  getThirdPartyPhysicians(searchString: string = '', newSearch: boolean = false) {
    if (newSearch) {
      this.pageNo = 1;
    }
    const payload = {
      name: searchString,
      pageSize: this.pageSize,
      pageNo: this.pageNo
    };
    this.reqService.post(physicians.getThirdPartyPhysicians, payload)
      .pipe(
        map((v: any[]) => {
          this.toastService.dismissToast();
          if (v.length < 50) {
            this.isLastFetch = true;
          }
          return newSearch ? [...v] : [...this.getThirdPartyPhysiciansList$.value, ...v];
        })
      )
      .subscribe(
      (d: any[]) => {
        if (d?.length > 0) {
          this.getThirdPartyPhysiciansList$.next(d);
          this.toastService.dismissToast();
        } else {
          this.noDataFountNotification();
          this.getThirdPartyPhysiciansList$.next([]);
        }
        this.isFetching = false;
        this.updateData();
      },
      (_err) => {
        this.isFetching = false;
      }
    );
  }
  getExternalPhysiciansNoCNAS(searchString: string = '') {
    const payload = {
      name: searchString ? searchString.toUpperCase() : '',
    };
    this.reqService
      .post(physicians.getExternalPhysiciansNoCNAS, payload)
      .subscribe(
        (d: any) => {
          if (d?.length > 0) {
            this.getExternalPhysiciansNoCNASList$.next(d);
          } else {
            this.noDataFountNotification();
            this.getExternalPhysiciansNoCNASList$.next([]);
          }
          this.isFetching = false;
          this.updateData();
        },
        (_err) => {
          this.isFetching = false;
        }
      );
  }
  getThirdPartyPhysiciansNotify() {
    this.toastService.presentToastWithNoDurationDismiss(
      'Introduceți un nume în câmpul de căutare',
      'success'
    );
  }
  noDataFountNotification() {
    this.toastService.presentToastWithNoDurationDismiss(
      'Nu s-au gasit potriviri. Încercați un alt nume.',
      'error'
    );
  }
  getPhysicianType(
    d: number = this.medicOptionFormControl.value,
    searchString: string = '',
  ): void {
    if (d) {
      this.toastService.dismissToast();
      this.isFetching = true;
      this.list$.next([]);
      switch (d) {
        case this.listOfMedicOption$.value[0].id:
          this.getPhysicians(searchString);
          break;
        case this.listOfMedicOption$.value[1].id:
          // if (searchString !== '') {
            this.getThirdPartyPhysicians(searchString, true);
          // } else {
            this.isFetching = true;
          //   this.getThirdPartyPhysiciansNotify();
          // }
          break;
        case this.listOfMedicOption$.value[2].id:
          this.getExternalPhysiciansNoCNAS(searchString);
          break;
        default:
          this.getPhysicians();
      }
    } else {
      this.toastService.presentToastWithNoDurationDismiss(
        'Selectați o opțiune',
        'success'
      );
    }
  }
  searching(st: string) {
    switch (this.medicOptionFormControl.value) {
      case this.listOfMedicOption$.value[0].id:
        if (this.list$.value.length > 0) {
          const filt = this.list$.value.filter((item: any) =>
            item.first.toLowerCase().includes(st.toLowerCase())
          );
          this.list$.next(filt);
        }
        break;
      case this.listOfMedicOption$.value[1].id:
        this.isFetching = true;
        this.getThirdPartyPhysicians(st);
        break;
      case this.listOfMedicOption$.value[2].id:
        if (this.list$.value.length > 0) {
          const filt = this.list$.value.filter(
            (v: any) => v.first.toLowerCase().indexOf(st.toLowerCase()) > -1
          );
          this.list$.next(filt);
        }
        break;
      default:
        this.updateData();
    }
  }
  updateData(data: any = this.medicOptionFormControl.value) {
    switch (data) {
      case this.listOfMedicOption$.value[0].id:
        if (this.getPhysiciansList$.value.length > 0) {
          const p = this.getPhysiciansList$.value.map((y: any) => ({
            id: y.uid,
            first: y.name,
            second:
              this.getMedicalSpecialitiesData$.value.find(
                (x: any) => x.code === y.specialityCode
              )?.name || '',
            third: y.stencilNo,
          }));
          this.list$.next([...p]);
        }
        break;
      case this.listOfMedicOption$.value[1].id:
          if (this.getThirdPartyPhysiciansList$.value.length > 0) {
            const p = this.getThirdPartyPhysiciansList$.value.map((y: any) => ({
              id: y.id,
              first: y.name,
              second:
                this.getAllMedicalSpecialities$.value.find(
                  (x: any) => x.id === y.specialityID
                )?.name || '', // specialityID =>

              third: y.stencilNo, // contractNo =>
            }));
            this.list$.next([...p]);
          } else {
            this.list$.next([]);
          }
        break;
      case this.listOfMedicOption$.value[2].id:
        if (this.getExternalPhysiciansNoCNASList$.value.length > 0) {
          const p = this.getExternalPhysiciansNoCNASList$.value.map(
            (y: any) => ({
              id: y.id,
              first: `${y.firstName} ${y.lastName}`,
              second:
                this.getAllMedicalSpecialities$.value.find(
                  (x: any) => x.id === y.specialityID
                )?.name || '',
              third: y.stencilNo,
            })
          );
          this.list$.next([...p]);
        }
        break;
      default:
    }
  }
  getMedicalSpecialities(): void {
    this.getMedicalSpecialities$ = forkJoin([
      this.reqService.get(medicalSpecialities.getMedicalSpecialities),
      this.reqService.get(medicalSpecialities.getAllMedicalSpecialities),
    ]).subscribe(
      (d: any) => {
        this.getMedicalSpecialitiesData$.next(d?.[0]?.specialities || []);
        this.getAllMedicalSpecialities$.next(d?.[1] || []);
      },
      (_err) =>
        this.toastService.presentToastWithDurationDismiss(
          'A apărut o eroare. Va rugăm să reîncercați.'
        )
    );
  }
  getDataForUse(
    id: string | number,
    switchValue: number = this.medicOptionFormControl.value
  ) {
    switch (switchValue) {
      case this.listOfMedicOption$.value[0].id:
        return this.findData(
          this.getPhysiciansList$.value,
          this.listOfMedicOption$.value[0].dataConfig.idKey,
          id
        );

      case this.listOfMedicOption$.value[1].id:
        return this.findData(
          this.getThirdPartyPhysiciansList$.value,
          this.listOfMedicOption$.value[1].dataConfig.idKey,
          id
        );
      case this.listOfMedicOption$.value[2].id:
        return this.findData(
          this.getExternalPhysiciansNoCNASList$.value,
          this.listOfMedicOption$.value[2].dataConfig.idKey,
          id
        );
      default:
        return null;
    }
  }
  findData(data: any, findBy: string, findData: any) {
    return data ? data.find((v: any) => v[findBy] === findData) || null : null;
  }

  loadMore() {
    this.pageNo++;
    this.getThirdPartyPhysicians(this.searchForm.value.search, false);
  }

  ngOnDestroy(): void {
    unsubscriberHelper(this.subscriptions);
    unsubscriberHelper(this.medicOptionTipSubscription$);
    unsubscriberHelper(this.getMedicalSpecialities$);
    this.toastService.dismissToast();
  }
}
