import { GenderEnum } from '../enum/gender.enum';

export const genderList = (): Array<{ label: string; id: number}> => Object.keys(GenderEnum)
      .filter((value: any) => isNaN(Number(value)) === false)
      .map((key: any) => ({
        label: GenderEnum[key],
        id: Number(key),
      } ) );

export const getGender = (id: number) =>
  genderList().find((v: { label: string; id: number }) => v.id === id)?.label ||
  null;
