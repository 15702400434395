import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor() { }

  mapServicesToPaymentMethod(data: any) {
    if (data?.bL_PromoID !== null && data?.bL_PromoID !== undefined &&
      data?.bL_PromotionPackage_PersonID !== null && data?.bL_PromotionPackage_PersonID !== undefined) {
      return {
        serviceUID: data.serviceUID,
        quantity: data.quantity,
        BL_PromoID: data.bL_PromoID,
        BL_PromotionPackage_PersonID: data.bL_PromotionPackage_PersonID,
        paymentMethod: 'servicePackage',
      };
    }
    if (!data?.bL_PromoID && data?.companyID && data?.isInsuranceCompany === false) {
      return {
        companyID: data.companyID,
        paymentMethod: 'partnerCompany',
        quantity: data.quantity,
        serviceUID: data.serviceUID,
      };
    }
    if (!data?.bL_PromoID && data?.companyID && data?.isInsuranceCompany === true
    ) {
      return {
        companyID: data.companyID,
        paymentMethod: 'insuranceCompany',
        quantity: data.quantity,
        serviceUID: data.serviceUID,
      };
    }
    if ((data?.bL_PromoID === null || data?.bL_PromoID === undefined)
      && (data?.companyID === null || data?.companyID === undefined)
      && (data?.isAssuranceCompDiscount !== null || data?.isAssuranceCompDiscount !== undefined)) {
      return {
        serviceUID: data.serviceUID,
        quantity: data.quantity,
        paymentMethod: 'cashCard',
        price: data?.price,
        bL_Physician_ServicesID: data?.bL_Physician_ServicesID,
      };
    }
  }
}
