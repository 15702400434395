import { AuthService } from '../services/auth/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class UnportedUserGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authenticationService.getVersion().pipe(
      take(1),
      map(appVersion => {
        if (!appVersion?.data?.allowNewBizCalendar) {
          this.router.navigate(['/no-access']);
          return false;
        }
        return true;
      }
      )
    );
  }
}
