import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() useLineLoader: boolean;
  hid = false;
  constructor() {}

  ngOnInit() { }
  get lineLoaderRequired() {
    return this.useLineLoader || false;
  }
}
