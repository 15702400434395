import { Injectable } from '@angular/core';
import { RequestService } from '@app/core/services/request/request.service';
import { group, persons } from 'src/app/core/configs/endpoints';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  public reloadPaceient$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private groupEndpoint = group;
  constructor(
    private reqS: RequestService,
  ) { }

  deleteGroup(groupUID: string) {
    return this.reqS.delete<any>(`${this.groupEndpoint.DeleteGroup}?personGroupUID=${groupUID}`);
  }

  editGroup(payload) {
    return this.reqS.put<any>(`${this.groupEndpoint.updateGroup}`, payload);
  }
}
