import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { getYear } from 'date-fns';
import { Subscription } from 'rxjs';
import { Person } from 'src/app/core/models/person.model';
import { RequestService } from 'src/app/core/services/request/request.service';
import { location } from 'src/app/core/configs/endpoints';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import { getGender } from '@app/core/helpers/app-gender-helper';
import { DictionaryService } from '@app/core/services/dictionary/dictionary.service';
import { PermissionsService } from '@app/core/services/permissions/permissions.service';
import { getAge } from '@app/core/helpers/date.helper';
@Component({
  selector: 'app-pacient-view-modal',
  templateUrl: './pacient-view-modal.component.html',
  styleUrls: ['./pacient-view-modal.component.scss'],
})
export class PacientViewModalComponent implements OnInit, OnDestroy {
  @Input() data!: Person;
  @Input() menu!: boolean;
  getCities$: Subscription;
  address = '';
  addressLoader = true;
  dictionary = this.dictionaryS.getDictionary();
  variant = this.dictionaryS.getVariant();

  constructor(
    private modalController: ModalController,
    private reqS: RequestService,
    private toastService: ToastService,
    private dictionaryS: DictionaryService,
    public permissionsS: PermissionsService
  ) {}
  ngOnInit() {
    this.getCities();
  }
  closeModal(closeStatus: boolean = false, action: string = '') {
    this.modalController.dismiss({
      dismissed: true,
      personData: closeStatus ? this.data : null,
      action,
    });
  }
  get birthMonth() {
    return new Intl.DateTimeFormat('ro', { month: 'long' }).format(
      new Date(this.data?.birthDate)
    );
  }
  get birthYear() {
    return getYear(new Date(this.data?.birthDate));
  }
  get birthDay() {
    return new Date(this.data?.birthDate)?.getDate();
  }
  get gender() {
    return this.data && getGender(this.data?.genderID)
      ? getGender(this.data?.genderID).slice(0, 1)
      : '-';
  }
  get birthAge() {
    return getAge(this.data?.birthDate)?.fullAgeText;
  }
  getCities() {
    if (this.data.cityID) {
      this.getCities$ = this.reqS
        .post(location.getCities, {
          cityID: this.data.cityID,
        })
        .subscribe(
          (d: any) => {
            this.address = d?.cities?.[0]?.name;
            this.addressLoader = false;
          },
          (_err) => {
            this.toastService.presentToastWithDurationDismiss(
              'A apărut o eroare. Va rugăm să reîncercați.'
            );
            this.addressLoader = false;
          }
        );
    } else {
      this.addressLoader = false;
      this.address = '-';
    }
  }
  get userAddress() {
    return this.address;
  }
  ngOnDestroy() {
    unsubscriberHelper(this.getCities$);
  }
}
