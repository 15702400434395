import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { format } from 'date-fns';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { group, persons } from 'src/app/core/configs/endpoints';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import { GroupList } from 'src/app/core/models/groupList.model';
import { Person } from 'src/app/core/models/person.model';
import { RequestService } from 'src/app/core/services/request/request.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { IonInputConfig } from 'src/app/shared/models/components/ion-input-config';
import { IonTextItem } from 'src/app/shared/models/components/ion-text-item';
import { GrupNouModalComponent } from '../grup-nou-modal/grup-nou-modal.component';
import { NewPacientModalComponent } from '../new-pacient-modal/new-pacient-modal.component';
import { DictionaryService } from 'src/app/core/services/dictionary/dictionary.service';
import { PersonsResponse } from 'src/app/core/models/person.response.interface';
import { getGender } from 'src/app/core/helpers/app-gender-helper';
import { Router } from '@angular/router';
import { PacientViewModalComponent } from '../pacient-view-modal/pacient-view-modal.component';
import { PermissionsService } from '@app/core/services/permissions/permissions.service';
import { getAge } from '@app/core/helpers/date.helper';
import { checkIfEmpty } from '@app/core/helpers/checkIfEmpty.helper';
import { GroupMembersModalComponent } from '../group-members-modal/group-members-modal.component';
import { GroupService } from '@app/shared/services/group.service';

@Component({
  selector: 'app-pacient',
  templateUrl: './pacient.component.html',
  styleUrls: ['./pacient.component.scss'],
})
export class PacientComponent implements OnInit, OnDestroy {
  @Input() menu: boolean;
  dictionary = this.dictionaryS.getDictionary();
  variant = this.dictionaryS.getVariant();
  // 2 tabs in the modal: one for pacienti listing and the other for groups listing
  segment = {
    one: this.dictionary?.users[this.variant],
    two: 'Grupuri',
  };
  list!: any;

  isFormSubmitted = false;

  label: IonTextItem = {
    text: 'Default',
    classes: '',
    slot: 'end',
  };
  config: IonInputConfig = {
    placeholder: 'Caută',
    type: 'search',
    inputMode: 'search',
    size: 100,
    clearable: false,
    inputClasses: '',
    bgwhite: true,
    disabled: false,
    removeInputItemBaseLine: true,
    isInputFocused: false,
    debounce: 500,
    hidAssistiveText: true,
  };
  searchForm: FormGroup = this.fb.group({
    search: ['', [Validators.required]],
  });
  public subscriptions = new Subscription();
  currentSegement: any = this.segment.one;
  getPersons$: Subscription;
  personsList$: BehaviorSubject<Array<Person>> = new BehaviorSubject<
    Array<Person>
  >([]);
  groupList$: BehaviorSubject<Array<GroupList>> = new BehaviorSubject<
    Array<GroupList>
  >([]);
  list$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  getGroups$: Subscription;
  isFetchingGroups = true;
  isFetchingPerson = true;
  gender = ['M', 'F'];
  viewFromGroup = false;
  pageSize = 50;
  pageNo = 1;
  isLastFetch = false;
  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private reqS: RequestService,
    private toastService: ToastService,
    private dictionaryS: DictionaryService,
    private router: Router,
    public permissionsS: PermissionsService,
    private groupService: GroupService,
  ) {
  }

  // open new pacient form
  async presentPacientnew() {
    const modal = await this.modalController.create({
      component: NewPacientModalComponent,
      cssClass: 'biz-modal-class',
      backdropDismiss: false,
      componentProps: {},
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.dismissed && data?.isPersonCreated) {
      this.currentSegement = this.segment.one;
      this.isFetchingPerson = true;
      this.getPersons();
    }
  }

  // open new group form
  async presentGrupNouModal() {
    const modal = await this.modalController.create({
      component: GrupNouModalComponent,
      cssClass: 'biz-modal-class',
      backdropDismiss: false,
      componentProps: {},
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.dismissed && data?.groupCreated) {
      this.currentSegement = this.segment.two;
      this.isFetchingGroups = true;
      this.getGroups();
    }
  }
  ngOnInit(): void {
    // load GetPersons
    this.getPersons();
    // get groups
    this.getGroups();
    // load check list to list
    this.updateData();
    this.subscriptions.add(
      this.searchForm.valueChanges
        .pipe(distinctUntilChanged()) // makes sure the value has actually changed.
        .subscribe((data) => {
          if (data.search !== '' && this.currentSegement === this.segment.two) {
            this.list$.next([
              ...(this.searching(data.search.replace(/\s/g, '')) || []),
            ]);
          } else if (this.currentSegement === this.segment.one) {
            this.isLastFetch = false;
            this.getPersons(true);
          }
          else {
            this.updateData();
          }
        })
    );
    this.groupService.reloadPaceient$.subscribe(
      (reload) => {
        if (reload) {
          // load GetPersons
          this.getPersons();
          // get groups
          this.getGroups();
        }
      }
    );
  }
  get seaching(): boolean {
    return this.searchForm.controls?.search &&
      !checkIfEmpty(this.searchForm.controls?.search?.value)
      ? true
      : false;
  }
  get searchText(): string {
    return this.searching && this.searchForm.controls?.search?.value;
  }
  updateData(data: any = this.currentSegement) {
    if (this.personsList$.value.length > 0 && data === this.segment.one) {
      this.list$.next([...this.personsList$.value]);
    } else if (this.groupList$.value.length > 0 && data === this.segment.two) {
      this.list$.next([...this.groupList$.value]);
    } else {
      this.list$.next([...this.personsList$.value]);
    }
  }

  segmentChanged(ev: any) {
    this.currentSegement = ev?.detail?.value || '';
    this.updateData();
  }
  get segmentToShow() {
    return this.currentSegement;
  }

  //choose pacient or group to view details
  submit(data: any) {
    if (
      this.menu &&
      this.currentSegement === this.segment.one &&
      this.permissionsS.canViewPatients
    ) {
      return this.presentPacientViewModal(data);
    } else if (data?.personsGroupUID && this.currentSegement === this.segment.two &&
      this.permissionsS.canViewPatients && this.menu
    ) {
      return this.getPersonsGroup(data?.personsGroupUID);
    }
    this.modalController.dismiss({
      dismissed: true,
      data,
      isPerson: this.currentSegement === this.segment.one,
      isGroup: this.currentSegement === this.segment.two,
    });
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true,
      data: null,
    });
    if (this.menu) {
      this.router.navigate(['/']);
    }
  }

  searching(st: string): any[] {
    switch (this.currentSegement) {
      case this.segment.one:
        return this.personsList$.value.filter(
          (v: any) =>
            v.firstName
              .replace(/\s/g, '')
              .toLowerCase()
              .includes(st.toLowerCase()) ||
            v.lastName
              .replace(/\s/g, '')
              .toLowerCase()
              .includes(st.toLowerCase()) ||
            st
              .toLowerCase()
              .includes(v.firstName.replace(/\s/g, '').toLowerCase()) ||
            st
              .toLowerCase()
              .includes(v.lastName.replace(/\s/g, '').toLowerCase())
        );
      case this.segment.two:
        return this.groupList$.value.filter(
          (v: any) =>
            v.groupName
              .replace(/\s/g, '')
              .toLowerCase()
              .includes(st.toLowerCase()) ||
            st
              .toLowerCase()
              .includes(v.groupName.replace(/\s/g, '').toLowerCase())
        );
      default:
        return [];
    }
  }

  getPersons(newSearch: boolean = false) {
    if (newSearch) {
      this.pageNo = 1;
    }
    this.getPersons$ = this.reqS
      .post<any>(persons.getPersons, {
        searchString: this.searchText || '',
        pageSize: this.pageSize,
        pageNo: this.pageNo
      }).pipe(
        map((data: PersonsResponse) => {
          if (data.persons.length < 50) {
            this.isLastFetch = true;
          }
          return data?.persons
          .map((v: any) => ({
            ...v,
            gender: getGender(v?.genderID)
              ? getGender(v?.genderID).slice(0, 1)
              : '-',
            fullName: `${v?.firstName} ${v?.lastName}`,
          }))
          .sort((a: Person, b: Person) => a?.fullName.localeCompare(b?.fullName)
          );
        }),
      )
      .subscribe(
        (d) => {
          if (d && d?.length > 0) {
            const list = newSearch ? d : [...this.personsList$.value, ...d];
            this.personsList$.next([
              ...list
            ]);
          }
          if (this.currentSegement === this.segment.one) {
            // call update
            this.updateData();
          }
          this.isFetchingPerson = false;
        },
        (_err) => {
          this.toastService.presentToastWithDurationDismiss(
            'A apărut o eroare. Va rugăm să reîncercați.'
          );
          this.isFetchingPerson = false;
        }
      );
  }
  getGroups() {
    this.getGroups$ = this.reqS.get<any>(group.getGroups).subscribe(
      (d: GroupList[]) => {
        this.groupList$.next([
          ...d.sort((a, b) => a?.groupName.localeCompare(b?.groupName)),
        ]);
        if (this.currentSegement === this.segment.two) {
          // call update
          this.updateData();
        }
        this.isFetchingGroups = false;
      },
      (_err) => {
        this.toastService.presentToastWithDurationDismiss(
          'A apărut o eroare. Va rugăm să reîncercați.'
        );
        this.isFetchingGroups = false;
      }
    );
  }

  getPersonsGroup(personsGroupUID) {
    this.reqS
      .get<any>(`${persons.GetPersonsGroupDetails}?personGroupUID=${personsGroupUID}`)
      .subscribe(
        (d: any) => {
          const groupMembers = this.createArrayOFPersonFromPersonList(d.personsGroupMembers);
          this.openGroupMembersModal(groupMembers, d);
          this.isFetchingPerson = false;
        },
        (_err) => {
          this.toastService.presentToastWithDurationDismiss(
            'A apărut o eroare. Va rugăm să reîncercați.'
          );
          this.isFetchingPerson = false;
        }
      );
  }

  openGroupMembersModal(groupMembers: any[], groupInfo) {
    this.modalController.create({
      component: GroupMembersModalComponent,
      cssClass: 'biz-modal-class',
      backdropDismiss: false,
      componentProps: {
        members: groupMembers,
        groupInfo,
        menu: this.menu,
      },
    }).then((modal) => {
      modal.present();
    }
    );
  }

  // create group member's  list
  createArrayOFPersonFromPersonList(personsGroupMembers: any[]) {
    const groupMembers = [];
    this.personsList$.value.forEach(person => {
      const member = personsGroupMembers.find(p => p.personUID === person.uid);
      if (member) {
        groupMembers.push({ ...person, registrationDate: member.registrationDate, personsGroupMemberUID: member.personsGroupMemberUID });
      }
    }
    );
    return groupMembers;
  }

  // get age from birth date
  getAge(birthDate: string) {
    return getAge(new Date(birthDate))?.ageYear;
  }

  ngOnDestroy(): void {
    unsubscriberHelper(this.subscriptions);
    unsubscriberHelper(this.getPersons$);
    unsubscriberHelper(this.getGroups$);
  }


  //open pacient view page
  async presentPacientViewModal(item: Person) {
    if (!this.menu) {
      return null;
    }
    const modal = await this.modalController.create({
      component: PacientViewModalComponent,
      cssClass: 'biz-modal-class',
      componentProps: {
        data: item,
        menu: this.menu,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.personData) {
      switch (data?.action) {
        case 'edit':
          this.presentNewPacientModal(data?.personData);
          break;
        default:
          break;
      }
    }
  }

  // prepare data for edit pacient form
  async presentNewPacientModal(personData: any) {
    const {
      firstName,
      lastName,
      birthDate,
      genderID,
      email,
      phone,
      pid,
      cityID,
      uid,
      promotionChannelUID,
    } = personData;
    const d = {
      nume: lastName || '',
      preNume: firstName || '',
      dateNasterii: format(new Date(birthDate), 'yyyy-MM-dd'),
      sex: genderID || 0,
      telephone: phone || '',
      email: email || '',
      cnp: pid || '',
      oras: cityID || '',
      canalDePromovare: promotionChannelUID || '',
    };
    const modal = await this.modalController.create({
      component: NewPacientModalComponent,
      cssClass: 'biz-modal-class',
      backdropDismiss: true,
      componentProps: {
        data: d,
        isEdit: true,
        uid,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.dismissed && data?.isPersonUpdated) {
      this.getPersons();
    }
  }

  loadMore() {
    this.pageNo++;
    this.getPersons();
  }
}
