import { Component, Input, OnInit } from '@angular/core';
import { getAge } from '@app/core/helpers/date.helper';
import { ModalController } from '@ionic/angular';
import { format, getYear } from 'date-fns';
import { InfoPatient } from 'src/app/core/models/infoPatient.model';
import { DictionaryService } from 'src/app/core/services/dictionary/dictionary.service';

@Component({
  selector: 'app-info-pacient-modal',
  templateUrl: './info-pacient-modal.component.html',
  styleUrls: ['./info-pacient-modal.component.scss'],
})
export class InfoPacientModalComponent implements OnInit {
  @Input() pacientPersonData: InfoPatient;
  @Input() pacientPersonName: InfoPatient;
  dictionary = this.dictionaryS.getDictionary();
  variant = this.dictionaryS.getVariant();

  constructor(
    private modalController: ModalController,
    private dictionaryS: DictionaryService
  ) {}

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  get personAge() {
    return this.pacientPersonData?.personAge;
  }

  get personRegistrationDate() {
    return this.pacientPersonData?.personRegistrationDate
      ? format(
          new Date(this.pacientPersonData?.personRegistrationDate),
          'dd.MM.yyyy'
        )
      : '-';
  }

  get lastAppointmentDate() {
    return this.pacientPersonData?.lastAppointmentDate
      ? format(
          new Date(this.pacientPersonData?.lastAppointmentDate),
          'dd.MM.yyyy'
        )
      : '-';
  }

  get birthMonth() {
    return new Intl.DateTimeFormat('ro', { month: 'long' }).format(
      new Date(this.pacientPersonData?.birthdate)
    );
  }
  get birthYear() {
    return getYear(new Date(this.pacientPersonData?.birthdate));
  }
  get birthDay() {
    return new Date(this.pacientPersonData?.birthdate)?.getDate();
  }

  get birthAge() {
    return getAge(this.pacientPersonData?.birthdate)?.fullAgeText;
  }
}
