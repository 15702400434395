import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {
  @Input() popoverTitle: string;
  @Input() items: { id; item }[];
  constructor(public popoverController: PopoverController) { }

  ngOnInit(): void {
  }

  selected(selectedItem: { id; item }) {
    this.popoverController.dismiss(selectedItem);
  }
}

