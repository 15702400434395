import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './maintenance-screen.page.html',
  styleUrls: ['./maintenance-screen.page.scss']
})
export class MaintenanceScreenPage implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
