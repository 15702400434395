export const CalendarPages = [
  { title: 'Listă', url: '/calendar/lista', icon: '1-day' },
  { title: 'Zi', url: '/calendar/zi', icon: 'schedule' },
  {
    title: 'Zile lucrătoare',
    url: '/calendar/zile-lucratoare',
    icon: '3-days',
  },
  { title: 'Săptămână', url: '/calendar/saptamana', icon: '5-days' },
  { title: 'Lună', url: '/calendar/luna', icon: 'month' },
  { title: 'Comparativ', url: '/calendar/aparate', icon: 'coparativ' },
];
