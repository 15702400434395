import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injectable,
  Input,
  LOCALE_ID,
  NgZone,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  CalendarUtils,
  CalendarWeekViewComponent,
  DateAdapter,
  getWeekViewPeriod,
} from 'angular-calendar';
import { DragEndEvent, DragMoveEvent } from 'angular-draggable-droppable';
import {
  CalendarEvent,
  EventColor,
  GetWeekViewArgs,
  WeekView,
  WeekViewAllDayEvent,
  WeekViewTimeEvent,
} from 'calendar-utils';
import { format, getISODay, isSameDay, parseISO, startOfDay, subMinutes } from 'date-fns';
import { ro } from 'date-fns/locale';
import { get } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import SwiperCore, { Controller, Navigation, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { BizCalendarTooltipDirective } from '../../directives/biz-calendar-tooltip.directive';
import { unsubscriberHelper } from './../../../core/helpers/unsubscriber.helper';
import { Schedule } from './../../../core/models/appointment.interface';

SwiperCore.use([Controller, Navigation]);
export interface User {
  id: number;
  name: string;
  title: string;
  fullName: string;
  color: EventColor;
}

interface DayViewScheduler extends WeekView {
  users: User[];
}

interface GetWeekViewArgsWithUsers extends GetWeekViewArgs {
  users: User[];
  schedules?: Schedule[];
  holidays?: any[];
}

@Injectable()
export class DayViewSchedulerCalendarUtils extends CalendarUtils {
  getWeekView(args: GetWeekViewArgsWithUsers): DayViewScheduler {
    const { period } = super.getWeekView(args);
    const view: DayViewScheduler = {
      period,
      allDayEventRows: [],
      hourColumns: [],
      users: [...args.users],
    };
    const dow = getISODay(args.viewDate);
    view.users.forEach((user, columnIndex) => {
      const events =
        args.events !== undefined
          ? args.events.filter((event) => event?.meta?.user?.id === user.id)
          : [];
      const schedules = this.prepareSchedules(args, user, dow);
      let columnView = super.getWeekView({
        ...args,
        events,
      });
      columnView = { ...columnView, ...{ schedules, user } };
      view.hourColumns.push({
        ...columnView.hourColumns[0],
        ...{ schedules, user },
      });
      columnView.allDayEventRows.forEach(({ row }, rowIndex) => {
        view.allDayEventRows[rowIndex] = view.allDayEventRows[rowIndex] || {
          row: [],
        };
        view.allDayEventRows[rowIndex].row.push({
          ...row[0],
          offset: columnIndex,
          span: 1,
        });
      });
    });

    return view;
  }

  prepareSchedules(args, user, dow) {
    return args.schedules !== undefined
      ? args.schedules.filter(
        (event) =>
          event?.meta?.user?.id === user.id ||
          (event?.meta?.type === 'cabinet' && event.dow === dow)
      )
      : [];
  }
}

@Component({
  // tslint:disable-line max-classes-per-file
  selector: 'app-mwl-day-view-scheduler',
  templateUrl: './day-view-scheduler.component.html',
  providers: [DayViewSchedulerCalendarUtils],
})
export class DayViewSchedulerComponent
  extends CalendarWeekViewComponent
  implements OnChanges, AfterViewInit
{
  @Input() users: User[] = [];
  @Input() schedules;
  @Input() holidays;
  @Output() customClick = new EventEmitter();
  @Output() userChanged = new EventEmitter();
  @ViewChild('swiper1', { static: false }) swiperHeader?: SwiperComponent;
  @ViewChild('swiper2', { static: false }) swiperContent?: SwiperComponent;
  @ViewChildren(BizCalendarTooltipDirective)
  tooltips: QueryList<any>;

  view: DayViewScheduler;
  currDay = format(new Date(), 'E', { locale: ro });
  currDate = format(new Date(), 'd', { locale: ro });
  isLocked1 = true;
  isLocked2 = true;
  swiperThrottled$ = new BehaviorSubject('lock');
  swiperThrottledSub$: Subscription;
  daysInWeek = 1;
  controller1;
  controller2;
  bp = {
    0: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    320: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    576: {
      slidesPerView: 5,
      spaceBetween: 0,
    },
    768: {
      slidesPerView: 6,
      spaceBetween: 0,
    },
    992: {
      slidesPerView: 7,
      spaceBetween: 0,
    },
    1200: {
      slidesPerView: 8,
      spaceBetween: 0,
    },
  };

  // Headers.
  config1: SwiperOptions = {
    slidesPerView: 4,
    watchOverflow: true,
    spaceBetween: 0,
    resistanceRatio: 0.1,
  };

  // Content.
  config2: SwiperOptions = {
    slidesPerView: 4,
    spaceBetween: 0,
    watchOverflow: true,
    navigation: {
      nextEl: '#next-el-navigation',
      prevEl: '#prev-el-navigation',
      disabledClass: 'ion-hide',
      hiddenClass: 'ion-hide',
    },
    resistanceRatio: 0.1,
  };

  constructor(
    protected cdr: ChangeDetectorRef,
    protected utils: DayViewSchedulerCalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    private vNgZone: NgZone
  ) {
    super(cdr, utils, locale, dateAdapter);
  }
  trackByUserId = (index: number, row: User) => row.id;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.currDay = format(new Date(this.viewDate), 'E', { locale: ro });
    this.currDate = format(new Date(this.viewDate), 'd', { locale: ro });
    if (changes.users) {
      this.refreshBody();
      this.emitBeforeViewRender();
      this.onSwiper('-1');
    }
  }
  /**
   * Properly style the swiper if we have less than needed items.
   *
   * @param swiperComp
   * - Content reference to one of the 2 swipers.
   * @param type
   * - Type of lock to test.
   * @returns
   * - If we update the UI or not.
   */
  checkLocked(swiperComp, type = 1) {
    let doUpdate = false;
    if (swiperComp?.swiperRef) {
      const val = get(swiperComp?.swiperRef, 'isLocked', true);
      if (type === 1) {
        doUpdate = val !== this.isLocked1;
        this.isLocked1 = get(swiperComp?.swiperRef, 'isLocked', true);
      } else {
        doUpdate = val !== this.isLocked2;
        this.isLocked2 = get(swiperComp?.swiperRef, 'isLocked', true);
      }
    } else {
      if (type === 1) {
        this.isLocked1 = true;
      } else {
        this.isLocked2 = true;
      }
      doUpdate = true;
    }
    return doUpdate;
  }

  // Make sure our tooltip matches the parent position.
  swiperTooltipMove() {
    this.tooltips
      .filter((v) => v && v.tooltipRef)
      .forEach((v1) => v1.doReposition());
  }

  public swiperTooltipHide() {
    this.tooltips.filter((v) => v && v.tooltipRef).forEach((v1) => v1.doHide());
  }

  /* Connect the header swiper to the content one. */
  updateSwiperConfig() {
    this.vNgZone.runOutsideAngular(() => {
      if (this.swiperHeader && this.swiperContent) {
        let reinit = false;
        if (this.swiperHeader?.swiperRef?.controller) {
          reinit = true;
          this.swiperHeader.swiperRef.controller.control =
            this.swiperContent.swiperRef;
          // Hook into the plugin object directly,
          // as the component handling is a mess.
          this.swiperHeader.swiperRef.update();
        }
        if (this.swiperContent?.swiperRef?.controller) {
          this.swiperContent.swiperRef.controller.control =
            this.swiperHeader.swiperRef;
          // Hook into the plugin object directly,
          // as the component handling is a mess.
          this.swiperContent.swiperRef.update();
          reinit = true;
        }
        if (reinit) {
          this.vNgZone.run(() => {
            this.onSwiper('0');
            this.cdr.detectChanges();
          });
        }
      }
    });
  }

  onSwiper(type) {
    this.swiperThrottled$.next(type);
  }

  ngAfterViewInit(): void {
    this.updateSwiperConfig();
    unsubscriberHelper(this.swiperThrottledSub$);
    this.swiperThrottledSub$ = this.swiperThrottled$
      .pipe(distinctUntilChanged())
      .subscribe((type) => {
        const ref1 = this.checkLocked(this.swiperHeader, 1);
        const ref2 = this.checkLocked(this.swiperContent, 2);
        if (ref1 || ref2) {
          this.cdr.detectChanges();
        }
      });
    this.onSwiper('1');
    this.cdr.markForCheck();
  }

  getDayColumnWidth(eventRowContainer: HTMLElement): number {
    return Math.floor(eventRowContainer.offsetWidth / this.users.length);
  }

  dragMove(dayEvent: WeekViewTimeEvent, dragEvent: DragMoveEvent) {
    if (this.snapDraggedEvents) {
      const newUser = this.getDraggedUserColumn(dayEvent, dragEvent.x);
      const newEventTimes = this.getDragMovedEventTimes(
        dayEvent,
        { ...dragEvent, x: 0 },
        this.dayColumnWidth,
        true
      );
      const originalEvent = dayEvent.event;
      const adjustedEvent = {
        ...originalEvent,
        ...newEventTimes,
        meta: { ...originalEvent.meta, user: newUser },
      };
      const tempEvents = this.events.map((event) => {
        if (event === originalEvent) {
          return adjustedEvent;
        }
        return event;
      });
      this.restoreOriginalEvents(
        tempEvents,
        new Map([[adjustedEvent, originalEvent]])
      );
    }
    this.dragAlreadyMoved = true;
  }

  dragEnded(
    weekEvent: WeekViewAllDayEvent | WeekViewTimeEvent,
    dragEndEvent: DragEndEvent,
    dayWidth: number,
    useY = false
  ) {
    super.dragEnded(
      weekEvent,
      {
        ...dragEndEvent,
        x: 0,
      },
      dayWidth,
      useY
    );
    const newUser = this.getDraggedUserColumn(weekEvent, dragEndEvent.x);
    if (newUser && newUser !== weekEvent.event.meta.user) {
      this.userChanged.emit({ event: weekEvent.event, newUser });
    }
  }

  customClickEv(event) {
    this.customClick.emit(event);
  }
  protected getWeekView(events: CalendarEvent[]) {
    return this.utils.getWeekView({
      events,
      users: this.users,
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      precision: this.precision,
      absolutePositionedEvents: true,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute,
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute,
      },
      segmentHeight: this.hourSegmentHeight,
      weekendDays: this.weekendDays,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn,
        this.excludeDays,
        this.daysInWeek
      ),
      schedules: this.schedules,
      holidays: this.filterHolidayDay(this.holidays, this.viewDate),
    });
  }

  private filterHolidayDay(holidays, date) {
    const hh = holidays
      ? holidays.filter((v) => {
          const parsedS = parseISO(v.startDate);
          const parsedE = parseISO(v.endDate);
          const isSame = isSameDay(parsedS, date) || isSameDay(parsedE, date);
          return isSame;
        })
      : [];
    return hh;
  }

  private getDraggedUserColumn(
    dayEvent: WeekViewTimeEvent | WeekViewAllDayEvent,
    xPixels: number
  ) {
    const columnsMoved = Math.round(xPixels / this.dayColumnWidth);
    const currentColumnIndex = this.view.users.findIndex(
      (user) => user === dayEvent.event.meta.user
    );
    const newIndex = currentColumnIndex + columnsMoved;
    return this.view.users[newIndex];
  }
}
