import { AbstractControl, ValidationErrors } from '@angular/forms';
import { getYearDateDiff } from './date.helper';
const centuryMapping = {
  1: '19',
  2: '19',
  3: '18',
  4: '18',
  5: '20',
  6: '20',
};
export const cnpValidator =
  (validate18 = false) =>
  (control: AbstractControl): ValidationErrors | null =>
    verifyCNP(control, validate18);

const verifyCNP = (control: AbstractControl, validate18 = false) => {
  if (control.value) {
    let bigSum = 0;
    const cnpArray = control.value
      .split('')
      .map((x: string) => parseInt(x, 10))
      .filter((x: number) => !Number.isNaN(x));
    const controlNum: any = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    const ageError = validate18 ? verifyAge18(control.value) : null;
    if (cnpArray.length === 13) {
      if (control.value === '0000000000000') {
        return { invalidCnp: true };
      }
      for (let i = 0; i < 12; i++) {
        bigSum += cnpArray[i] * controlNum[i];
      }
      bigSum = bigSum % 11;
      if (bigSum === 10) {
        bigSum = 1;
      }
      if (bigSum === cnpArray[12]) {
        return mergeErrors(ageError, null);
      } else {
        return mergeErrors(ageError, { invalidCnp: true });
      }
    }
    return mergeErrors(ageError, { invalidCnp: true });
  }

  return null;
};

// Validate minimum age requirements (18Yrs old).
const verifyAge18 = (cnp: string) => {
  const ageCode = +cnp.substring(0, 1);
  const yearPart = cnp.substring(1, 3);
  const year = `${centuryMapping[ageCode]}${yearPart}`;
  const month = +cnp.substring(3, 5);
  const day = +cnp.substring(5, 7);
  try {
    const birthDate = new Date(
      new Date().setFullYear(Number(year), month - 1, day)
    );
    const today = new Date();
    const dateDiff = getYearDateDiff(today, birthDate);
    return dateDiff >= 18 ? null : { invalidCnp18Years: dateDiff };
  } catch {
    return null;
  }
};

const mergeErrors = (err1: any, err2: any) => {
  err1 = err1 ? err1 : {};
  err2 = err2 ? err2 : {};
  const merged = { ...err1, ...err2 };
  return Object.keys(merged).length ? merged : null;
};

export const getDateFromCPN = (cnp: string) => {
  const ageCode = cnp.substring(0, 1);
  const yearPart = cnp.substring(1, 3);
  const year = `${centuryMapping[Number(ageCode)]}${yearPart}`;
  const month = cnp.substring(3, 5);
  const day = cnp.substring(5, 7);
  return `${year}-${month}-${day}`;
};

export const getGenderFromCPN = (cnp: string) => {
  const genderNum = cnp.substring(0, 1);
  switch (genderNum) {
    case '1':
    case '3':
    case '5':
    case '7':
      return 1; // male
    case '2':
    case '4':
    case '6':
    case '8':
      return 2; // female
    default:
      return 0;
  }
};
