import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { get } from 'lodash';
import { AparaturaAsociataModel } from 'src/app/core/models/aparatura-asociata.model';
import { BizCustomSelectionConfig } from 'src/app/shared/models/components/biz-custom-selection-config';
import { DemoCheckList } from 'src/app/style-guide/components/selection/selection.component';


@Component({
  selector: 'app-biz-custom-selection',
  templateUrl: './biz-custom-selection.component.html',
  styleUrls: ['./biz-custom-selection.component.scss'],
})
export class BizCustomSelectionComponent implements OnInit {
  @Input() data!: AparaturaAsociataModel[];
  @Input() config: BizCustomSelectionConfig;
  @Input() selectedValue: any[] = [];
  @Input() disabled: boolean;
  @Output() modalOpenEvent = new EventEmitter<any>();
  @Output() removeItemEvent: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  constructor() { }

  ngOnInit() {}
  removeChips(event: any, d: AparaturaAsociataModel): void {
    this.stopModalPropagation(event);
    this.unCheckItem(d);
    this.removeItemEvent.emit(true);
  }
  openModal() {
    this.modalOpenEvent.emit(true);
  }
  stopModalPropagation(event: Event): void {
    event.stopPropagation();
  }
  get useIcon(): boolean {
    return this.config.hasOwnProperty('useIcon') ? true : false;
  } // caret-down
  get filtercustomComponentData(): AparaturaAsociataModel[]{
    return this.data.filter(
      (v: AparaturaAsociataModel) => this.selectedValue.includes(
        v[this.configIdKey]
      )
    );
  }
  get configIdKey() {
    return get(this.config, 'idKey', 'equipmentUID');
  }
  unCheckItem(data: AparaturaAsociataModel): void {
    if (data) {
      const indexOfData = this.selectedValue.findIndex(
        (v: string) => v === data[this.configIdKey]);
     if (indexOfData > -1) {
       this.selectedValue.splice(indexOfData, 1);
      }
    }
  }

}
