import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AparaturaAsociataModel } from '@app/core/models/aparatura-asociata.model';
import { CalendarService } from '@app/core/services/calendar/calendar.service';
import { CautaService } from '@app/core/services/cauta/cauta.service';
import { BizCustomSelectionConfig } from '@app/shared/models/components/biz-custom-selection-config';
import { IonInputConfig } from '@app/shared/models/components/ion-input-config';
import { ModalController, NavController } from '@ionic/angular';
import { NumarDeProgramariComponent } from '../modal/numar-de-programari/numar-de-programari.component';
import { switchMap, take } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';

@Component({
  selector: 'app-cauta-modal',
  templateUrl: './cauta-modal.component.html',
  styleUrls: ['./cauta-modal.component.scss']
})
export class CautaModalComponent implements OnInit {
  searching = false;
  searchData: any[] = [];
  searchForm: FormGroup = this.fb.group({
    keyword: ['', [Validators.required]],
  });
  config: IonInputConfig = {
    placeholder: 'Caută după nume sau serviciu',
    type: 'search',
    inputMode: 'search',
    size: 100,
    clearable: true,
    inputClasses: '',
    minLength: 2,
    maxLength: 100,
    bgwhite: true,
    disabled: false,
    removeInputItemBaseLine: true,
    isInputFocused: false,
    debounce: 500,
    hidAssistiveText: true,
  };
  placeHolder = 'Ordonează după: ';
  sortLabel = 'Dată crescător';
  cautaConfig: BizCustomSelectionConfig = {
    placeholder: `${this.placeHolder + this.sortLabel}`,
    inputLabel: {
      text: null
    },
  };
  list = [
    {
      label: 'Dată crescător',
      value: 'StartTime',
      sortCriteria: 'asc',
      icon: 'default',
      disabled: true
    },
    {
      label: 'Dată descrescător',
      value: 'StartTime',
      sortCriteria: 'desc',
      icon: 'users',
      disabled: true
    },
    {
      label: 'Nume și prenume  A-Z',
      value: 'Person',
      sortCriteria: 'asc',
      icon: 'default',
      disabled: true
    },
    {
      label: 'Nume și prenume Z-A',
      value: 'Person',
      sortCriteria: 'desc',
      icon: 'users',
      disabled: true
    },
  ];

  cautaData: AparaturaAsociataModel[] = [];
  searchFormSub: Subscription = null;
  persistResultSub: Subscription = null;
  constructor(private fb: FormBuilder,
    private modalController: ModalController,
    private cautaService: CautaService,
    private navC: NavController,
    private calendarS: CalendarService
  ) { }

  ngOnInit(): void {
  }

  ionViewWillEnter() {
    this.calendarS.selectedPath.pipe(
      switchMap(path => this.cautaService.searchKeyword$.pipe(
        switchMap(keyword => of({ keyword, path })))),
      switchMap(({ keyword, path }) => this.cautaService.searchResult$.pipe(
        switchMap(result => this.cautaService.sortLabel$.pipe(
          switchMap(sortLabel => of({ keyword, path, result, sortLabel })))))),
      take(1)
    ).subscribe(
      ({ keyword, path, result, sortLabel }) => {
        if (path === '/cauta' && keyword && result) {
          this.searchForm.patchValue({ keyword }, { emitEvent: false, onlySelf: false });
          this.searchData = result;
          this.cautaConfig.placeholder = `${this.placeHolder + sortLabel}`;
          this.list.forEach(item => {
            item.disabled = this.searchData?.length > 0 ? false : true;
          });
        }
      },
      error => { },
      () => {
        this.cautaService.searchResult$.next(null);
        this.cautaService.searchKeyword$.next(null);
      }
    );
    this.watchForFormChanges();
  }

  // when search string is changed make the search request
  watchForFormChanges() {
    this.searchFormSub = this.searchForm.valueChanges.subscribe(() => {
      this.searching = true;
      this.searchData = [];
      this.sortLabel = 'Dată crescător';
      this.placeHolder = 'Ordonează după: ';
      this.cautaConfig.placeholder = `${this.placeHolder + this.sortLabel}`;
      this.cautaService.searchCalendar([this.searchForm.value]).subscribe(
        data => {
          if (data?.Appointments) {
          this.searchData = this.combineServicesIntoSingleArray(data?.Appointments);
          this.sortBy('StartTime', 'asc');
          }
        },
        error => {
        },
        () => {
          this.searching = false;
          this.list.forEach(item => {
            item.disabled = this.searchData?.length > 0 ? false : true;
          });
        }
      );
    });
  }

  combineServicesIntoSingleArray(data: any[]) {
    if (!data) {
      return null;
    }
    return data.map(item => {
      const services = [];
      if (item?.PrivateServices?.length > 0) {
        item.Services = [...services, ...item.PrivateServices];
      }
      if (item?.CNASServices?.length > 0) {
        item.Services = [...services, ...item.CNASServices];
      }
      return item;
    });
  }

  // open order by options modal
  async presentCautaDataModal() {
    const modal = await this.modalController.create({
      component: NumarDeProgramariComponent,
      cssClass: 'biz-modal-class-type-a',
      componentProps: {
        list: this.list,
      },
    });
    modal.onDidDismiss()
      .then((data) => {
        if (data.data) {
          this.sortBy(data.data.value, this.list.find(item => item.label === data.data.label).sortCriteria);
          this.sortLabel = data.data.label;
          this.cautaConfig.placeholder = `${this.placeHolder + this.sortLabel}`;
        }
      });
    await modal.present();
  }

  //sort algorithm
  sortBy(sortKey: string, sortCriteria: string) {
    if (sortCriteria === 'asc') {
    this.searchData = this.searchData.sort((a, b) => {
      if (a[sortKey] && b[sortKey]) {
        return a[sortKey] > b[sortKey] ? 1 : -1;
      }
      return 0;
    });
    } else {
      this.searchData = this.searchData.sort((a, b) => {
        if (a[sortKey] && b[sortKey]) {
          return a[sortKey] < b[sortKey] ? 1 : -1;
        }
        return 0;
      });
    }
  }

  navigateTo(item: any) {
    this.calendarS.selectedPath.next('/cauta');
    this.cautaService.sortLabel$.next(this.sortLabel);
    this.cautaService.searchKeyword$.next(this.searchForm.value.keyword);
    this.cautaService.searchResult$.next(this.searchData);
    this.navC.navigateForward(`calendar/vizualizare-programare/${item.AppointmentUID}`);
  }

  ionViewWillLeave() {
    unsubscriberHelper(this.searchFormSub);
  }

}
