import { DictionaryService } from 'src/app/core/services/dictionary/dictionary.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { addHours, addMinutes, format } from 'date-fns';
import { ProgrammareService } from 'src/app/core/services/programmare/programmare.service';

@Component({
  selector: 'app-cabinet-notify',
  templateUrl: './cabinet-notify.component.html',
  styleUrls: ['./cabinet-notify.component.scss'],
})
export class CabinetNotifyComponent implements OnInit {
  @Input() notifyType!: 'typeA' | 'typeB' | 'typeC' | 'typeA-alert';
  @Input() cabinetName!: string;
  @Input() date: Date;
  @Input() notifyTitle!: string;
  @Input() isCabinet = true;
  @Input() duration: number;
  @Input() message: string;
  @Input() buttonOkText: string;
  @Input() buttonCancelText: string;
  variant = this.dictionaryS.getVariant();
  dictionary = this.dictionaryS.getDictionary();
  reserveTitle = `Rezervare slot ${this.dictionary?.profesie[this.variant]?.toLowerCase()}`;
  notifyTypes: any = {
    typeA: 'cabinet notify',
    typeAAlert: 'cabinet notify alert',
    typeB: 'calendar notify',
    typeC: 'note notify',
  };

  constructor(
    private modalController: ModalController,
    private programmareS$: ProgrammareService,
    private dictionaryS: DictionaryService
  ) {
  }

  ngOnInit() {
    this.reserveTitle = `Rezervare slot ${this.dictionary?.profesie[this.variant]?.toLowerCase()}`;
  }

  closeModal(v: string = '') {
    this.modalController.dismiss({
      dismissed: true,
      renita: v === 'RENUNȚĂ' ? true : false,
      veziProgram: v === 'VEZI PROGRAM' ? true : false,
      sterge: v === 'ȘTERGE' ? true : false,
      selecteaza: v === 'SELECTEAZA' ? true : false,
      isHoutMinutesPicker: v === 'HOUR-MINUTES-PICKER' ? true : false,
      isDayMonthPicker: v === 'MONTH-DAY-PICKER' ? true : false,
      dateData: this.date,
    });
  }
  get notifyTypeToUse() {
    if (typeof this.notifyTypes !== 'undefined') {
      switch (this.notifyType) {
        case 'typeA':
          return this.notifyTypes.typeA;
        case 'typeA-alert':
          return this.notifyTypes.typeAAlert;
        case 'typeB':
          return this.notifyTypes.typeB;
        case 'typeC':
          return this.notifyTypes.typeC;
        default:
          return this.notifyTypes.typeA;
      }
    }
  }
  get istypeAAlert() {
    return this.notifyTypes.typeAAlert === this.notifyTypeToUse;
  }
  intlDate() {
    return new Intl.DateTimeFormat('ro', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }
  get theDate() {
    return this.date || new Date();
  }
  get formattedDate() {
    return this.intlDate().format(this.theDate);
  }
  timeFormat(date: Date) {
    return new Intl.DateTimeFormat('ro', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }
  get timeDuration() {
    return this.duration || this.programmareS$.duration$.value;
  }
  get addDuration() {
    return addMinutes(this.date, this.timeDuration);
  }
  get time() {
    return `${this.timeFormat(this.theDate)} - ${this.timeFormat(
      this.addDuration
    )}`;
  }
  houtMinutesPicker() {
    this.closeModal('HOUR-MINUTES-PICKER');
  }
  isDayMonthPicker() {
    this.closeModal('MONTH-DAY-PICKER');
  }
}
