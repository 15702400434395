import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NoteService } from '@app/core/services/notes/note.service';
import { PermissionsService } from '@app/core/services/permissions/permissions.service';
import { IonContent, IonInfiniteScroll } from '@ionic/angular';
import {
  addDays,
  addMonths,
  differenceInHours,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDayOfYear,
  getMonth,
  getWeek,
  getYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { ro } from 'date-fns/locale';
import { get, uniq, uniqBy } from 'lodash';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import {
  switchMap,
  take,
} from 'rxjs/operators';
import { WEEK_START_DAY } from 'src/app/app.module';
import { unsubscriberHelper } from './../../../core/helpers/unsubscriber.helper';
import { CalendarService } from './../../../core/services/calendar/calendar.service';

@Component({
  selector: 'app-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarListComponent implements OnInit, OnDestroy {
  @Input() eventList = [];
  @Input() isTablet = false;
  @Input() parentContentRef: IonContent;
  @ViewChild('scrollBottomI') scrollBottom: IonInfiniteScroll;
  @ViewChild('scrollTopI') scrollTop: IonInfiniteScroll;
  @ViewChild('monthsListRef') listRef: ElementRef<any>;
  loadingSubj = new BehaviorSubject(false);
  directionalLoader = new BehaviorSubject(null);
  scrollElement: HTMLElement;
  timer;
  currentDate = new Date();
  monthsList = [];
  structureNavAmount = 0;
  dataNavAmount = 0;
  navDiff = 1;
  minDate;
  maxDate;
  minStructureDate;
  maxStructureDate;
  dayNumber;
  navSubs$: Subscription;
  dateSubs$: Subscription;
  calSubs$: Subscription;
  constructor(
    private calS: CalendarService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private noteS: NoteService,
    private permissionsS: PermissionsService
  ) {}

  ngOnInit() {
    this.setupDates(this.calS.selectedDate.value || new Date());
    this.dateSubs$ = this.calS.selectedDate.pipe().subscribe((v) => {
      let load = true;
      const oMinDate = this.minDate ? new Date(this.minDate).getTime() : null;
      const oMaxDate = this.maxDate ? new Date(this.maxDate).getTime() : null;
      this.setupDates(v || new Date());
      if (oMinDate && oMaxDate) {
        if (
          new Date(this.minDate).getTime() >= oMinDate &&
          new Date(this.maxDate).getTime() <= oMaxDate
        ) {
          load = false;
        }
      }
      this.handleDateSetup(null, load);
    });

    this.parentContentRef
      .getScrollElement()
      .then((el) => (this.scrollElement = el));

    this.dayNumber = this.getDayNumber();
  }

  setupDates(date) {
    this.currentDate = new Date(date);
    // Next ones should be +/-1 multiplication factors
    this.minDate = startOfMonth(
      addMonths(startOfMonth(this.currentDate), -1 * this.dataNavAmount)
    );
    this.maxDate = endOfMonth(
      addMonths(endOfMonth(this.currentDate), 1 * this.dataNavAmount)
    );
    this.minStructureDate = startOfMonth(
      addMonths(startOfMonth(this.currentDate), -1 * this.structureNavAmount)
    );
    this.maxStructureDate = endOfMonth(
      addMonths(endOfMonth(this.currentDate), 1 * this.structureNavAmount)
    );
  }
  scrollToDate(date) {
    const d = new Date(date);
    const day = getDayOfYear(d);
    const week = getWeek(d, { weekStartsOn: WEEK_START_DAY });
    const month = getMonth(d);
    const year = getYear(d);
    const yId = 'dateId-' + year;
    const mId = yId + '-' + month;
    const wId = mId + '-' + week;
    const dId = wId + '-' + day;
    const dayEl = document.getElementById(dId);
    const weekEl = document.getElementById(wId);
    const monthEl = document.getElementById(mId);
    if (this.parentContentRef) {
      let parentTop = 0;
      if (this.scrollElement) {
        parentTop = this.scrollElement.getBoundingClientRect().top;
      }
      if (dayEl) {
        dayEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else if (weekEl) {
        weekEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else if (monthEl) {
        monthEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  execScroll(element) {
    if (this.scrollElement) {
      this.scrollElement.scrollTo({
        top: element.getBoundingClientRect().top - element.offsetTop,
      });
    } else {
      this.parentContentRef.scrollToPoint(
        0,
        element.getBoundingClientRect().top - element.offsetTop,
        0
      );
    }
  }
  loadData(direction) {
    this.directionalLoader.next(direction);
  }

  handleDateSetup(direction, load = true) {
    let loadStart = this.minDate;
    let loadEnd = this.maxDate;
    if (load || this.monthsList.length === 0) {
      if (direction === 'F') {
        loadStart = startOfMonth(
          addMonths(startOfMonth(this.maxDate), this.dataNavAmount)
        );
        loadEnd = endOfMonth(
          addMonths(endOfMonth(this.maxDate), this.dataNavAmount)
        );
      }
      if (direction === 'B') {
        loadStart = startOfMonth(
          addMonths(startOfMonth(this.minDate), -1 * this.dataNavAmount)
        );
        loadEnd = endOfMonth(
          addMonths(endOfMonth(this.minDate), -1 * this.dataNavAmount)
        );
      }
      const monthsList = this.setupStructure(direction);
      this.monthsList = uniqBy(monthsList, 'monthId');
      this.getEventLists();
      this.cdRef.markForCheck();
    }

    this.scrollToDate(this.currentDate);
    this.minDate = loadStart;
    this.maxDate = loadEnd;
  }

  setupStructure(
    direction = null,
    minDate = this.minStructureDate,
    maxDate = this.maxStructureDate
  ) {
    let minDateV = minDate;
    let maxDateV = maxDate;
    let monthsList = [];
    if (direction === 'B') {
      minDateV = startOfMonth(addMonths(minDate, -1 * this.structureNavAmount));
      maxDateV = minDate;
      this.minStructureDate = minDateV;
      monthsList = Object.values(this.buildEvLists(minDateV, maxDateV));

      monthsList = monthsList.filter(
        (newMonth) =>
          this.monthsList.findIndex(
            (existingMonth) => existingMonth.monthId === newMonth.monthId
          ) === -1
      );

      monthsList = monthsList.concat(this.monthsList);
      if (monthsList.length >= 3 * this.structureNavAmount) {
        monthsList.splice(3 * this.structureNavAmount);
      }
      this.minStructureDate = minDateV;
      if (monthsList.length) {
        this.minStructureDate = monthsList[monthsList.length - 1].monthS;
      }
      this.currentDate = maxDateV;
    } else if (direction === 'F') {
      minDateV = maxDate;
      maxDateV = endOfMonth(addMonths(maxDate, 1 * this.structureNavAmount));
      monthsList = Object.values(this.buildEvLists(minDateV, maxDateV));
      monthsList = monthsList.filter(
        (newMonth) =>
          this.monthsList.findIndex(
            (existingMonth) => existingMonth.monthId === newMonth.monthId
          ) === -1
      );
      monthsList = this.monthsList.concat(monthsList);

      if (monthsList.length >= 3 * this.structureNavAmount) {
        monthsList.splice(0, monthsList.length - 3 * this.structureNavAmount);
      }
      if (monthsList.length) {
        this.minStructureDate = monthsList[0].monthS;
      }
      this.maxStructureDate = maxDateV;
      this.currentDate = minDateV;
    } else {
      monthsList = Object.values(this.buildEvLists(minDate, maxDate));
    }
    this.calS.listNavigation.next({ startDate: minDateV, endDate: maxDateV });
    monthsList.forEach((month) => {
      month.weekList.forEach((wk) => (wk.filled = false));
      month.filled = false;
    });
    return monthsList;
  }

  stopLoaders() {
    this.loadingSubj.next(true);
    if (this.scrollBottom) {
      this.scrollBottom.complete();
    }
    if (this.scrollTop) {
      this.scrollTop.complete();
    }
  }

  buildEvLists(start, end) {
    const monthLists = {};
    const setup = [];
    const sd = startOfDay(start);
    const ed = endOfDay(end);
    if (differenceInHours(ed, sd) >= 23) {
      let testD = sd;
      while (differenceInHours(ed, testD) >= 23) {
        const day = getDayOfYear(testD);
        const weekNo = getWeek(testD, { weekStartsOn: WEEK_START_DAY });
        const month = getMonth(testD);
        const year = getYear(testD);
        setup.push({
          year,
          weekNo,
          weekS: startOfWeek(testD, { weekStartsOn: WEEK_START_DAY }),
          weekE: endOfWeek(testD, { weekStartsOn: WEEK_START_DAY }),
          day: startOfDay(testD),
          month,
          monthS: startOfMonth(testD),
          monthE: endOfMonth(testD),
        });
        testD = addDays(testD, 1);
      }
    }
    setup.forEach((dateS) => {
      const sdI = dateS.day;
      const dayI = getDayOfYear(sdI);
      const weekNoI = getWeek(sdI, { weekStartsOn: WEEK_START_DAY });
      const monthI = getMonth(sdI);
      const yearI = getYear(sdI);
      const yId = 'dateId-' + yearI;
      const mId = yId + '-' + monthI;
      const wId = mId + '-' + weekNoI;
      const dId = wId + '-' + dayI;
      const monthK = new Date(dateS.monthS).getTime();
      const weekK = new Date(dateS.weekS).getTime();
      if (!monthLists[monthK]) {
        monthLists[monthK] = {
          dataId: mId,
          monthId: monthK,
          month: dateS.month,
          year: dateS.year,
          monthS: dateS.monthS,
          monthName: '',
          monthE: dateS.monthE,
          difYears: getYear(dateS.monthS) !== getYear(new Date()),
          weeks: {},
          weekList: [],
          filled: false,
        };
        monthLists[monthK].monthName = monthLists[monthK].difYears
          ? format(new Date(dateS.monthS), 'MMMM yyyy', {
              locale: ro,
            })
          : format(new Date(dateS.monthS), 'MMMM yyyy', {
              locale: ro,
            });
      }
      if (!monthLists[monthK].weeks[weekK]) {
        monthLists[monthK].weeks[weekK] = {
          dataId: wId,
          weekId: weekK,
          month: dateS.month,
          year: dateS.year,
          weekNo: dateS.weekNo,
          weekS: dateS.weekS,
          weekE: dateS.weekE,
          weekName: '',
          appCount: 0,
          startsInMonth: getMonth(dateS.weekS) === getMonth(dateS.monthS),
          difMonths: getMonth(dateS.weekS) !== getMonth(dateS.weekE),
          difYears: getYear(dateS.monthS) !== getYear(new Date()),
          days: [],
          filled: false,
        };
        this.weekNameSetup(monthLists[monthK].weeks[weekK]);
        monthLists[monthK].weekList = Object.values(monthLists[monthK].weeks);
      }
    });
    return monthLists;
  }

  //get info for each card
  getEventLists() {
    unsubscriberHelper(this.calSubs$);
    this.calSubs$ = this.calS.appointments$
      .pipe(
        switchMap((val) => {
          this.noteS.getNoteTypes().pipe(take(1)).subscribe();
          return of(val);
        })
      )
      .subscribe((e) => {
        e?.appointments.forEach((ap) => {
          const icons = new Set(ap.icons.map(this.calS.iconCode));
          const start = new Date(ap.startTime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });
          const end = new Date(ap.endTime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });
          const dd = new Date(ap.startTime);
          const ed = new Date(ap.endTime);
          const dayI = getDayOfYear(dd);
          const weekNoI = getWeek(dd, { weekStartsOn: WEEK_START_DAY });
          const monthI = getMonth(dd);
          const yearI = getYear(dd);
          const yId = 'dateId-' + yearI;
          const mId = yId + '-' + monthI;
          const wId = mId + '-' + weekNoI;
          const dId = wId + '-' + dayI;
          // use different fields for observation for appointments and notes
          const obs = ap?.isNote
            ? ap?.comment
              ? ap.comment
              : ''
            : ap?.infPacient
            ? ap.infPacient
            : '';
          const eqN = ap.equipmentNames?.length
            ? [ap.equipmentNames.join(' , ')]
            : [];
            // use different fields for services based on services type
          const sN =
            ap.paymentTypeID === 1
              ? ap.serviceNames?.length
                ? [ap.serviceNames.join(' , ')]
                : []
              : ap.cnasMedicalServices?.length
              ? [
                  ap.cnasMedicalServices
                    .map((v: any) => v?.description || '')
                    .filter((v) => v !== '')
                    .join(' , '),
                ]
              : [];
          const name = ap.groupName ? ap.groupName : ap.personName;
          const item = {
            weekNo: weekNoI,
            yearNo: yearI,
            monthNo: monthI,
            dayNo: dayI,
            date: dd.getTime(),
            eDate: ed.getTime(),
            monthK: new Date(startOfMonth(dd)).getTime(),
            weekK: new Date(
              startOfWeek(dd, { weekStartsOn: WEEK_START_DAY })
            ).getTime(),
            id: ap.uid,
            uid: ap.uid,
            isNote: ap.isNote,
            icons: [...icons],
            title: this.constructTitle(name, ap.noteTypeID),
            time: start + ' - ' + end,
            desc: uniq(
              sN
                .concat(eqN)
                .concat([obs])
                .filter((v) => v)
            ).join(' • '),
            location: ap.isDeletedAppointment
              ? 'ANULAT'
              : ap.isOnlineAppointment
              ? 'On-line'
              : ap.locationName,
            class: this.calS.colorCode(ap.colorCode, 'list-appointment'),
          };
          const month = this.monthsList.find(
            (m) => m.year === item.yearNo && m.monthId === item.monthK
          );
          if (month) {
            const week = month.weekList.find(
              (w) => w.month === item.monthNo && w.weekId === item.weekK
            );
            if (week) {
              let day = week.days.find((d) => d.dayNo === item.dayNo);
              if (day) {
                const foundIndex = day.appointments.findIndex(
                  (x) => x.id === item.id
                );
                if (day.appointments[foundIndex]) {
                  day.appointments[foundIndex] = item;
                } else {
                  day.appointments.push(item);
                }
              } else {
                day = {
                  dataId: dId,
                  dayNo: item.dayNo,
                  dayName: format(dd, 'EEE d', {
                    locale: ro,
                  }),
                  date: startOfDay(dd),
                  dateTime: startOfDay(dd).getTime(),
                  appointments: [item],
                };
                week.days.push(day);
              }
              day.appointments.sort((a, b) => a.date - b.date);
              week.days.sort((a, b) => a.dateTime - b.dateTime);
              week.appCount = week.days.reduce(
                (s, itV) =>
                  s + itV.appointments.filter((v) => !v.isNote).length
                ,
                0
              );
              week.filled = true;
            }
            month.weekList.forEach((wk) => (wk.filled = true));
            month.filled = true;
          }
        });
        if (e?.appointments?.length === 0) {
          this.monthsList.forEach((month) => {
            month.weekList.forEach((wk) => (wk.filled = true));
            month.filled = true;
          });
        }
        this.cdRef.detectChanges();
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.scrollToDate(this.currentDate);
        }, 100);
        this.loadingSubj.next(true);
      });
  }

  // building title for appointment or notes
  // if appointment, then display person/group name
  // else if note, display note type
  constructTitle(name, noteTypeID) {
    if (!noteTypeID) {
      return name;
    }
    const noteType = this.noteS.noteTypes$
      .getValue()
      .find((e: any) => e.noteTypeID === noteTypeID);
    return noteType ? noteType.description : null;
  }

  filterDuplicates() {
    for (const month of this.monthsList) {
      for (const week of month.weekList) {
        for (const day of week.days) {
          day.appointments = day.appointments.filter(
            (a, i) => day.appointments.findIndex((b) => b.uid === a.uid) === i
          );
        }
      }
    }
  }

  weekNumber(date) {
    return getWeek(new Date(date));
  }

  weekNameSetup(weekSetup) {
    const firstStr = weekSetup.difMonths
      ? format(new Date(weekSetup.weekS), 'MMM d', {
          locale: ro,
        })
      : format(new Date(weekSetup.weekS), 'MMM d', {
          locale: ro,
        });
    const secStr = weekSetup.difMonths
      ? format(new Date(weekSetup.weekE), 'MMM d', {
          locale: ro,
        })
      : format(new Date(weekSetup.weekE), 'd', {
          locale: ro,
        });

    weekSetup.weekName = firstStr + ' - ' + secStr;
  }
  ngOnDestroy() {
    unsubscriberHelper(this.calSubs$);
    unsubscriberHelper(this.dateSubs$);
    this.calS.intervalSourceSub$.unsubscribe();
  }

  //handle click based on permissions (open view note/appointment)
  handleClickEvent(event) {
    const isNote = get(event, 'isNote', false);
    const uid = get(event, 'uid', false);
    if (uid) {
      if (isNote && this.permissionsS.canViewNotes) {
        this.router.navigate(['/calendar', 'vizualizare-nota', uid]);
      } else {
        if (this.permissionsS.canViewAppointments) {
          this.router.navigate(['/calendar', 'vizualizare-programare', uid]);
        }
      }
    }
  }

  getDayNumber() {
    const now: any = new Date();
    const start: any = new Date(now.getFullYear(), 0, 0);
    const diff = now - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    return day;
  }
}
