import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toast: any;

  constructor(public toastController: ToastController) {}
  /**
   *
   * @param message  a string
   * @param cssClass This could be success or error
   * @param duration duration in milliseconds
   */
  async presentToastWithDurationDismiss(
    message: string = 'message',
    cssClass: 'success' | 'error' | 'warning' = 'error',
    duration: number = 3000
  ) {
    this.toast = await this.toastController.create({
      cssClass,
      message,
      duration,
    });
    this.toast.present();
  }
  async presentToastWithNoDurationDismiss(
    message: string = 'message',
    cssClass: 'success' | 'error' | 'warning' = 'error'
  ) {
    this.toast = await this.toastController.create({
      cssClass,
      message,
    });
    this.toast.present();
  }
  dismissToast() {
    if (this.toast) {
      this.toast.dismiss();
    }
  }
}
