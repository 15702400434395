import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DictionaryService } from '@app/core/services/dictionary/dictionary.service';
import { ProgrammareService } from '@app/core/services/programmare/programmare.service';
import { TextAreaConfig } from '@app/shared/models/components/ion-textarea-config';
import { ModalController } from '@ionic/angular';
import { MedicModalComponent } from '../medic-modal/medic-modal.component';
import { take } from 'rxjs/operators';
import { ToastService } from '@app/core/services/toast/toast.service';
import { get } from 'lodash';
import { AppointmentService } from '@app/core/services/appointment.service';

@Component({
  selector: 'app-redirectionare',
  templateUrl: './redirectionare.component.html',
  styleUrls: ['./redirectionare.component.scss'],
})
export class RedirectionareComponent implements OnInit {
  @Input() appointment = null;
  observatiiConfig: TextAreaConfig = {
    textAreaLabel: {
      text: 'Observații (opțional)',
      classes: '',
      slot: '',
    },
    placeholder: '',
    disabled: false,
  };
  componentFormGroup: FormGroup = this.fb.group({
    observatii: '',
    redirectedPhysicianUID: ['', Validators.required],
    redirectedPhysicianName: ['', Validators.required],
  });
  isUpdating = false;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    public dictionaryS: DictionaryService,
    private programmareS: ProgrammareService,
    private toastS: ToastService,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {}

  closeModal(save: boolean) {
    if (save) {
      this.saveRedirectionare();
    } else {
      this.modalController.dismiss({
        dismissed: true,
        update: false,
      });
    }
  }

  saveRedirectionare() {
    this.isUpdating = true;
    const payload = {
      ...this.mapAppointmentForWS(this.appointment),
    };
    this.programmareS.updateAppointmentV2(payload).pipe(take(1)).subscribe(
      (res: any) => {
        if (Number(res?.errorCode) === 0) {
          this.modalController.dismiss({
            dismissed: true,
            update: true,
          });
          this.toastS.presentToastWithDurationDismiss('Redirectionare salvata cu succes!', 'success');
        } else {
          this.toastS.presentToastWithDurationDismiss('Eroare la salvarea redirectionarii!', 'error');
        }
        this.isUpdating = false;
      },
      (err) => {
        this.isUpdating = false;
        this.toastS.presentToastWithDurationDismiss('Eroare la salvarea programării', 'error');
      },
      () => {
        this.isUpdating = false;
      }
    );
  }

  mapAppointmentForWS(appointment) {
    return {
      ...appointment,
      appointments_Services: appointment.privateServices.map((service) => this.appointmentService.mapServicesToPaymentMethod(service)),
      uid: get(appointment, 'appointmentUID', null),
      redirectedObs: this.componentFormGroup.value.observatii,
      redirectedPhysicianUID: this.componentFormGroup.value.redirectedPhysicianUID,
    };
  }

  async presentMedicModal() {
    const modal = await this.modalController.create({
      component: MedicModalComponent,
      cssClass: 'biz-modal-class',
      backdropDismiss: false,
      componentProps: {
        medicOptionTip: 1,
      },
    });
    await modal.present();
    const d = await modal.onWillDismiss();
    const { dismissed, medicData, medicName, physicianTypeID } = d?.data;
    if (dismissed && medicData && medicName && physicianTypeID) {
      this.componentFormGroup.patchValue({ redirectedPhysicianName: medicName });
      this.componentFormGroup.patchValue({ redirectedPhysicianUID: medicData.uid });
    }
  }
}
