import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './access-restriction.page.html',
  styleUrls: ['./access-restriction.page.scss']
})
export class AccessRestrictionPage implements OnInit {

  constructor(private authS: AuthService, private router: Router) { }

  ngOnInit(): void {

  }


  logout() {
    this.authS.logout();
    this.router.navigate(['/login']);
  }
}
