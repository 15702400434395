import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingControllerService {
  loading: any;

  constructor(public loadingController: LoadingController) { }
  async presentLoadingWithNoDuration(
    message: string = '',
    id: string = '',
  ) {
    this.loading = await this.loadingController.create({
      message,
      id,
      spinner: 'crescent',
      mode: 'md',
      // duration: 2000,
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await this.loading.present();
  }
  dismiss(id: string = '', data: any = null, role: any = null) {
    this.loading.dismiss(data, role, id);
  };
}
