import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription, timer } from 'rxjs';
import {
  ActionPerformed, PushNotifications, PushNotificationSchema, Token
} from '@capacitor/push-notifications';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isTablet = false;
  timer$: Subscription;
  constructor(private platform: Platform, private authS: AuthService) {

    this.authS.user.pipe(switchMap((val) => this.authS.loggedInPhysicianSubject.pipe(map((val2) => {
      if (val && val2) {
        return true;
      }
      return false;
    })))).subscribe(
      (val) => {
        if (val) {
          this.platform.ready().then(() => {
            if (this.platform.is('mobile') && this.authS.isAuthenticated.value) {
              this.initializePush();
        }
          });
        }
      }
    );

    this.platform.ready().then(() => {
      if (platform.width() >= 768) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
    });
  }
  initializePush(): void {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      this.authS.registerPushToken(token.value).subscribe();
    });

    PushNotifications.addListener('registrationError', (error) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        alert(notification.data.message);
      },
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        // add actions such as redirects
      },
    );
  }
}
