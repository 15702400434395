import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidemenuService {
  isIonSplitPaneVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
  samePageNavigation$ = new Subject();
  constructor() {}

  setSplitPaneVisible(isVisible: boolean) {
    this.isIonSplitPaneVisible.next(isVisible);
  }
  getIonSplitPaneVisibilityStatus() {
    return this.isIonSplitPaneVisible.asObservable();
  }
}
