import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { notes } from '../../configs/endpoints';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  note$: BehaviorSubject<any> = new BehaviorSubject(null);
  noteTypes$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  constructor(private reqS: RequestService) {
    this.getNoteTypes();
  }

  addNotes(obj) {
    return this.reqS.post<any>(notes.addNote, obj);
  }
  getNotes(id) {
    return this.reqS.get<any>(notes.getNote + '?appointmentUID=' + id);
  }
  getNoteTypes() {
    return this.reqS.get<any>(notes.getNoteTypes).pipe(
      map((res) => {
        this.noteTypes$.next(res);
        return res;
      })
    );
  }
  updateNotes(id, obj) {
    return this.reqS.put<any>(notes.updateNote, obj);
  }
  deleteNotes(id) {
    return this.reqS.delete<any>(notes.deleteNote + '?noteUID=' + id);
  }
}
