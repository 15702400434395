export const locationOptions = [
  {
    id: 'Toate',
    label: 'Toate',
  },
  {
    id: 'Bucuresti Presciziei',
    label: 'Bucuresti Presciziei',
  },
  {
    id: 'Bucuresti Aviatori',
    label: 'Bucuresti Aviatori',
  },
  {
    id: 'Ploiesti Central',
    label: 'Ploiesti Central',
  },
  {
    id: 'Braila I L Caragiale',
    label: 'Braila I L Caragiale',
  },
  {
    id: 'Braila Grivitei',
    label: 'Braila Grivitei',
  },
];

export const programOptions = [
  {
    id: '1',
    label: 'Program utilizatori',
  },
  {
    id: '2',
    label: 'Program cabinete',
  },
  {
    id: '3',
    label: 'Program aparate',
  },
];
export const selectConfig = {
  inputLabel: {
    classes: 'ion-hide',
    text: '',
  },
  forceListItems: true,
  multiple: true,
  mode: 'md',
  disabled: false,
  placeholder: 'Selection',
  alertOptions: {
    cssClass: '',
  },
  useIcon: {
    name: 'caret-down',
    classes: 'neutral-grey-medium-color',
  },
  idKey: 'id',
  labelKey: 'label',
};
export const selectConfigB = {
  inputLabel: {
    classes: 'ion-hide',
    text: '',
  },
  forceListItems: true,
  mode: 'ios',
  multiple: false,
  disabled: false,
  placeholder: 'Selection',
  alertOptions: {
    cssClass: '',
  },
  useIcon: {
    name: 'caret-down',
    classes: 'neutral-grey-medium-color',
  },
  idKey: 'id',
  labelKey: 'label',
};
export const utilizatorList = [
  {
    label: 'D.A.',
    title: 'Dna.',
  },
  {
    label: 'I.A.',
    title: 'Dna.',
  },
  {
    label: 'L.B.',
    title: 'Dna.',
  },
  {
    label: 'E.C.D.',
    title: 'Dna.',
  },
  {
    label: 'A.P.D.',
    title: 'Dna.',
  },
];
export const cabinetList = [
  {
    label: '1',
    title: 'Cabinet',
  },
  {
    label: '2',
    title: 'Cabinet',
  },
  {
    label: '3',
    title: 'Cabinet',
  },
  {
    label: '1',
    title: 'Sală operații',
  },
  {
    label: '2',
    title: 'Sală operații',
  },
];
export const aparatList = [
  {
    label: '1',
    title: 'Ecograf',
  },
  {
    label: 'cop 1',
    title: 'Endos-',
  },
  {
    label: 'cop 2',
    title: 'Endos-',
  },
  {
    label: '1',
    title: 'Doppler',
  },
  {
    label: '1',
    title: 'CT',
  },
];
