import { Injectable } from '@angular/core';
import { checkIfEmpty } from '@app/core/helpers/checkIfEmpty.helper';
import { AppointmentChecksFlag } from '@app/core/models/appointmentChecksFlag.interface';
import { CheckRecurrenceAvailabilityPayload } from '@app/core/models/checkRecurrenceAvailability.interface';
import { CNAS } from '@app/core/models/cnas.service.model';
import { Cuplata } from '@app/core/models/cuplata.service.model';
import { GetPhysiciansScheduleAndFreeDaysResponse } from '@app/core/models/getPhysiciansScheduleAndFreeDays.interface';
import { IonRadioInputOption } from '@app/shared/models/components/ion-radio-input-option';
import {
  startOfDay,
  addMinutes,
  addHours,
  format,
  formatRFC3339,
  differenceInMinutes,
  startOfWeek,
  endOfWeek,
  areIntervalsOverlapping,
  isSameDay,
  endOfDay,
  formatISO,
} from 'date-fns';
import { isEqual, uniq } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  cabinet,
  physicians,
  appointmentEndpoints,
  location,
  equipments,
  tipServiciiEndpoint,
} from '../../configs/endpoints';
import { dayInAWeekWithDate } from '../../helpers/date.helper';
import {
  Appointment,
  AppointmentResponse,
} from '../../models/appointment.interface';
import { GetCabinetSchedulesResponseModel } from '../../models/getCabinetSchedules.response.model';
import { ProgrammareDateDataUpdate } from '../../models/programmareDateDataUpdate-model';
import { DictionaryService } from '../dictionary/dictionary.service';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ProgrammareService {
  dictionary = this.dictionaryS.getDictionary();
  variant = this.dictionaryS.getVariant();
  appointmentEndpointData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  cabinetScheldulesEndpointData$: BehaviorSubject<any> =
    new BehaviorSubject<any>([]);
  getPhysicianScheduleEndPointData$: BehaviorSubject<any> =
    new BehaviorSubject<any>([]);
  duration$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  startTime$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  endTime$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  physicianUID$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  locationUID$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  startTimeOfTheWeek$: BehaviorSubject<Date> = new BehaviorSubject(null);
  endTimeOfTheWeek$: BehaviorSubject<Date> = new BehaviorSubject(null);
  message$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  programmareDateDataUpdate$: BehaviorSubject<ProgrammareDateDataUpdate> =
    new BehaviorSubject({
      isUpdate: false,
      time: null,
      date: null,
      cabinetUID: '',
      message: '',
    });
  appointmentTempDataStorage$: BehaviorSubject<Appointment> =
    new BehaviorSubject<Appointment>(null);
  tipServiciiOption: Array<IonRadioInputOption> = [
    {
      label: 'Cu plată',
      id: 1,
      key: 'cuplata',
      idKey: 'serviceUID',
    },
    {
      label: 'C.N.A.S.',
      id: 2,
      key: 'cnas',
      idKey: 'cnasMedicalServiceCode',
    },
  ];
  appointmentChecksTempStore$: BehaviorSubject<{
    physiciansScheduleAndFreeDaysResponse: GetPhysiciansScheduleAndFreeDaysResponse;
    appointmentResponse: AppointmentResponse;
    appointmentCheckFlag: AppointmentChecksFlag;
    isEdit: boolean;
  }> = new BehaviorSubject<any>({
    physiciansScheduleAndFreeDaysResponse: null,
    appointmentResponse: null,
    appointmentCheckFlag: null,
    isEdit: false,
  });
  appointmentChecksErrorMessages = {
    appointmentConflict: 'Există un conflict de programare.',
    isBreakTimeSchedule:
      this.dictionary?.profesie[this.variant] + 'ul este în pauză.',
    isOutOfPhysicianSchedule:
      'Ora selectată nu se încadrează în programul ' +
      this.dictionary?.profesie[this.variant].toLowerCase() +
      'ului.',
    isphysicianFreeDays:
      this.dictionary?.profesie[this.variant] + 'ul este în concediu.',
    cabinetConfilct: 'Cabinetul este deja utilizat in intervalul selectat.',
  };

  constructor(
    private reqService: RequestService,
    private dictionaryS: DictionaryService
  ) {}

  // check if cabinet is available
  isCabinetAvailable(
    cabinetUID: string,
    locationUID: string,
    startTime: Date,
    endTime: Date,
    physicianUID: string,
    durat?: number
  ): Observable<boolean> {
    const getSchedulePayload = {
      cabinetUID,
      locationUID,
    };
    const getPhysicianSchedulePayload = {
      physicianUID,
      locationUID,
    };
    const appointmentPayload = {
      locationUID,
      cabinetUID,
    };
    const duration =
      durat || differenceInMinutes(new Date(endTime), new Date(startTime));
    const arrayOfDaysInWeek = dayInAWeekWithDate(new Date(startTime));

    return forkJoin([
      this.reqService.post<Array<GetCabinetSchedulesResponseModel>>(
        cabinet.getCabinetsSchedules,
        getSchedulePayload
      ),
      this.reqService.post(physicians.getPhysicianSchedule, {
        ...getPhysicianSchedulePayload,
        startDate: formatISO(startOfDay(arrayOfDaysInWeek[0])),
        endDate: formatISO(
          endOfDay(arrayOfDaysInWeek[arrayOfDaysInWeek.length - 1])
        ),
      }),
      this.reqService.post(appointmentEndpoints.getAppointment, {
        ...appointmentPayload,
        startDate: formatISO(startOfDay(arrayOfDaysInWeek[0])),
        endDate: formatISO(
          endOfDay(arrayOfDaysInWeek[arrayOfDaysInWeek.length - 1])
        ),
        showDeletedAppointments: false,
      }),
    ]).pipe(
      tap((res: any) => {
        this.cabinetScheldulesEndpointData$.next(
          res[0]
            .map((v: any) => ({
              ...v,
              cabinetDate: startOfDay(arrayOfDaysInWeek[v.dayID]),
            }))
            .map((q: any) => ({
              ...q,
              startTime: addMinutes(
                addHours(startOfDay(q.cabinetDate), q.startHour),
                q.startMin
              ),
              endTime: addMinutes(
                addHours(startOfDay(q.cabinetDate), q.endHour),
                q.endMin
              ),
            }))
        );
        // getPhysicianScheduleEndPointData
        this.getPhysicianScheduleEndPointData$.next(
          res[1].map((w: any) => ({
            ...w,
            date: startOfDay(new Date(w.date)),
            endTime: addHours(
              startOfDay(new Date(w.date)),
              parseInt(w.end, 10)
            ),
            startTime: addHours(
              startOfDay(new Date(w.date)),
              parseInt(w.start, 10)
            ),
          }))
        );
        this.appointmentEndpointData$.next({
          ...res[2],
          appointments: res[2]?.appointments
            .map((q: any) => ({
              ...q,
              startTime: new Date(q.startTime),
              endTime: new Date(q.endTime),
            }))
            .filter((a: Appointment) => a?.isDeletedAppointment === false),
        });

        // set otherData
        this.saveDataForUse({
          startTime,
          endTime,
          physicianUID,
          duration,
          locationUID,
        });
      }),
      switchMap((_v: any) =>
        of([
          this.cabinetScheldulesEndpointData$.getValue(),
          this.getPhysicianScheduleEndPointData$.getValue(),
          this.appointmentEndpointData$.getValue(),
        ])
      ),
      switchMap((_v: any) =>
        of(
          this.runCheckCabinetAvailabilityProcess(
            startTime,
            endTime,
            physicianUID
          )
        )
      )
    );
  }
  runCheckCabinetAvailabilityProcess(
    startTime: Date = this.startTime$.value,
    endTime: Date = this.endTime$.value,
    physicianUID: string = this.physicianUID$.value
  ): boolean {
    // if exist data it means user can't select this cabinet
    const checkCabinetAvailability = this.doesDataContainConflict(
      this.cabinetScheldulesEndpointData$.value.filter(
        (v: any) => v.physicianUID !== physicianUID
      ),
      new Date(startTime),
      new Date(endTime)
    );

    const checkAppointmentAvailability = this.doesDataContainConflict(
      this.appointmentEndpointData$.value.appointments.filter(
        (t: any) => t?.physicianUID !== physicianUID
      ),
      startTime,
      endTime
    );
    if (!checkCabinetAvailability && !checkAppointmentAvailability) {
      return true;
    } else {
      return false;
    }
  }
  setDuration(duration: number) {
    this.duration$.next(duration);
  }
  saveDataForUse({ startTime, endTime, duration, physicianUID, locationUID }) {
    this.duration$.next(duration);
    this.startTime$.next(startTime);
    this.endTime$.next(endTime);
    this.physicianUID$.next(physicianUID);
    this.locationUID$.next(locationUID);
    this.startTimeOfTheWeek$.next(startOfWeek(new Date(startTime)));
    this.endTimeOfTheWeek$.next(endOfWeek(new Date(endTime)));
  }

  timeFormat(time: string | Date) {
    return format(new Date(time), 'HH:mm');
  }
  formatDate(date: string | Date) {
    return format(new Date(date), 'yyyy-MM-dd');
  }
  customFormatDate(date: Date | string) {
    return `${this.formatDate(date)}T${this.timeFormat(date)}`;
  }
  updateProgrammareDateData(date: Date, cabinetUID: string, message?: string) {
    this.programmareDateDataUpdate$.next({
      isUpdate: true,
      time: this.timeFormat(date),
      date: this.formatDate(date),
      cabinetUID,
      message: message || '',
    });
  }
  getProgrammareDateData(): Observable<any> {
    return this.programmareDateDataUpdate$.asObservable();
  }
  getAppointmentDetails(id: string) {
    return this.reqService.get<any>(
      appointmentEndpoints.getAppointmentDetails + '?appointmentUID=' + id
    );
  }

  requestConsultation(data) {
    return this.reqService.post<any>(
      appointmentEndpoints.requestConsultationGeneration,
      data
    );
  }
  deleteAppointment(appointmentUID: string, deleteCancelMessage: string) {
    return this.reqService.post(appointmentEndpoints.deleteAppointment, {
      deletedAppUID: appointmentUID,
      deleteCancelMessage,
    });
  }
  set setAppointmentTempDataForEdit(d: Appointment) {
    this.appointmentTempDataStorage$.next(d);
  }
  isAppointmentTempDataAvailableAndValidForRoute(routeId: string) {
    return (
      this.appointmentTempDataStorage$.value &&
      this.appointmentTempDataStorage$.value.appointmentUID &&
      this.appointmentTempDataStorage$.value.appointmentUID === routeId
    );
  }
  getLocations() {
    return this.reqService.get(location.getLocations);
  }
  getMedicalServices(equipmentLocationUID: string): Observable<any> {
    return this.reqService.post(equipments.getMedicalEquipment, {
      equipmentLocationUID,
    });
  }
  addAppointment(payload: any) {
    return this.reqService.post(appointmentEndpoints.addApointment, payload);
  }

  addAppointmentV2(payload: any) {
    return this.reqService.post(appointmentEndpoints.addApointmentV2, payload);
  }

  updateAppointment(payload: any) {
    return this.reqService.put(appointmentEndpoints.updateAppointment, payload);
  }

  updateAppointmentV2(payload: any) {
    return this.reqService.put(appointmentEndpoints.updateAppointmentV2, payload);
  }

  // check selected equipment availability
  checkMedicalEquipmentAvailability(
    equipmentUID: string,
    physicianUID: string,
    startTime: string | Date,
    endTime: string | Date
  ): Observable<boolean> {
    const data = {
      equipmentUID,
      startDate: formatRFC3339(addHours(new Date(startTime), 1), { fractionDigits: 3 }),
      endDate: formatRFC3339(new Date(endTime), { fractionDigits: 3 }),
      physicianUID,
    };
    return this.reqService.post(equipments.checkMedicalEquipmentAvailability, data);
  }

  getTipServicii(data: {
    physicianUID: string;
    locationUID: string;
    appointmentStartDate: string;
    appointmentEndDate: string;
    paymentTypeID: number;
    specialityCode?: string;
  }) {
    const {
      physicianUID,
      locationUID,
      appointmentStartDate,
      appointmentEndDate,
      paymentTypeID,
      specialityCode,
    } = data;
    return this.getTipServiciiType({
      paymentTypeID,
      physicianUID,
      specialityCode: specialityCode || '',
      locationUID,
    }).pipe(
      switchMap((m: any) => {
        if (paymentTypeID === this.tipServiciiOption[1].id) {
          return this.reqService
            .post(appointmentEndpoints.checkAppointmentVsSchedule, {
              appointmentStartDate,

              appointmentEndDate,

              physicianUID,

              locationUID,

              paymentTypeID,
            })
            .pipe(
              switchMap((d: any) =>
                of({ data: m, checkAppointmentVsSchedule: d })
              )
            );
        } else {
          return of({
            data: m,
            // always true for cuplata
            checkAppointmentVsSchedule: true,
          });
        }
      })
    );
  }
  getTipServiciiType({
    paymentTypeID = this.tipServiciiOption[0].id,
    physicianUID = '',
    specialityCode = '',
    locationUID = '',
  }: {
    paymentTypeID?: number;
    physicianUID?: string;
    specialityCode?: string;
    locationUID?: string;
  }): Observable<Array<Cuplata | CNAS>> {
    switch (paymentTypeID) {
      case this.tipServiciiOption[0].id:
        const cuplataPayLoad: any = {
          physicianUID,
          specialityCode,
        };
        /* if (!checkIfEmpty(locationUID)) {
          cuplataPayLoad = {
            ...cuplataPayLoad,
            locationUID,
          };
        } */
        return this.reqService.post<Cuplata[]>(
          tipServiciiEndpoint.getMedicalServices,
          cuplataPayLoad
        );
      case this.tipServiciiOption[1].id:
        return this.reqService.post<CNAS[]>(
          tipServiciiEndpoint.getClinicCNASMedicalServices,
          {
            specialityCode,
          }
        );
      default:
        return this.reqService.post<CNAS[]>(
          tipServiciiEndpoint.getClinicCNASMedicalServices,
          {
            specialityCode,
          }
        );
    }
  }

  // check recurence available slots
  checkRecurrenceAvailability(
    data: CheckRecurrenceAvailabilityPayload
  ): Observable<any> {
    return this.reqService.post(
      appointmentEndpoints.checkRecurrenceAvailability,
      data || {}
    );
  }

  // check conflicts for physician schedule and concurrent appointments
  appointmentsChecks(
    startDate: string,
    endDate: string,
    physicianUID: string,
    locationUID: string,
    editAppointmentUID: string = '',
    ignoreAppointmentChecks: boolean = false,
  ) {
    const isEdit = checkIfEmpty(editAppointmentUID) ? false : true;
    return this.getPhysicianScheduleAndAppointment(
      startDate,
      endDate,
      physicianUID,
      locationUID
    ).pipe(
      switchMap(
        (
          v: [GetPhysiciansScheduleAndFreeDaysResponse, AppointmentResponse]
        ) => {
          let message = '';
          const startTime = new Date(startDate);
          const endTime = new Date(endDate);
          const flag = {
            isOutOfPhysicianSchedule: false,
            isPhysicianBreakTimeSchedule: false,
            isphysicianFreeDays: false,
            appointmentInConflict: false,
          };
          // Todo: filter freedays [done]
          const phyFreeDay = this.doesDataContainConflict(
            v[1]?.phyFreeDays.map((ps: any) => ({
              ...ps,
              endTime: new Date(ps.endDate),
              startTime: new Date(ps.startDate),
            })),
            startTime,
            endTime
          );
          if (phyFreeDay) {
            flag.isphysicianFreeDays = true;
            message = this.appointmentChecksErrorMessages.isphysicianFreeDays;
          } else {
            // Todo: physiciansSchedule mapping dates [done]
            const phySch = v[0]?.physiciansSchedule.map((ps: any) => ({
              ...ps,
              date: startOfDay(new Date(ps.date)),
              endTime: addHours(
                startOfDay(new Date(ps.date)),
                parseInt(ps.end, 10)
              ),
              startTime: addHours(
                startOfDay(new Date(ps.date)),
                parseInt(ps.start, 10)
              ),
            }));
            // TODO: refactor: appoinmentInConflict [done]
            const appointmentConflict = this.appointmentContainConflict(
              v[1]?.appointments,
              startTime,
              endTime,
              editAppointmentUID
            );
            flag.appointmentInConflict = appointmentConflict
              ? ignoreAppointmentChecks
                ? false
                : true
              : false;
            if (flag.appointmentInConflict) {
              message = this.appointmentChecksErrorMessages.appointmentConflict;
            }
            const isBreakTimeSchedule = this.doesDataContainConflict(
              phySch.filter((a: any) => a.isBreakTime),
              startTime,
              endTime
            );
            if (isBreakTimeSchedule) {
              flag.isPhysicianBreakTimeSchedule = true;
              // if no conflict update message
              if (!flag.appointmentInConflict) {
                message =
                  this.appointmentChecksErrorMessages.isBreakTimeSchedule;
              }
            } else {
              // Todo: capture all physician available hours [done]
              const physicianAvailableHours = [];

              phySch
                .filter((a: any) => a.isBreakTime === false)
                .filter((a: any) => isSameDay(a.date, startTime))
                .forEach((a: any) => {
                  for (
                    let i = parseInt(a.start, 10);
                    i < parseInt(a.end, 10);
                    i++
                  ) {
                    physicianAvailableHours.push(i);
                  }
                });
              // Todo: make arrray unique [done]
              const uniqPhysicianAvailableHours = uniq(
                physicianAvailableHours.sort((a, b) => a - b)
              );

              const userScheduleHours = [];
              for (let i = startTime.getHours(); i < endTime.getHours(); i++) {
                userScheduleHours.push(i);
              }
              if (
                addHours(startOfDay(startTime), endTime.getHours()) < endTime
              ) {
                // todo: check if not on physician scheldule
                userScheduleHours.push(endTime.getHours());
              }
              const userScheduleHoursSorted = userScheduleHours.sort(
                (a, b) => a - b
              );
              let isPhysicianTimeSchedule = false;
              for (const h of userScheduleHoursSorted) {
                if (!uniqPhysicianAvailableHours.includes(h)) {
                  isPhysicianTimeSchedule = false;
                  break;
                } else {
                  isPhysicianTimeSchedule = true;
                }
              }
              if (isPhysicianTimeSchedule) {
                flag.isOutOfPhysicianSchedule = false;
              } else {
                flag.isOutOfPhysicianSchedule = true;
                if (!flag.appointmentInConflict) {
                  message =
                    this.appointmentChecksErrorMessages
                      .isOutOfPhysicianSchedule;
                }
              }
            }
          }
          // save data in temp storage for use in other components
          this.appointmentChecksTempStore$.next({
            physiciansScheduleAndFreeDaysResponse: {
              ...v[0],
              physiciansSchedule: v[0]?.physiciansSchedule.map((ps: any) => ({
                ...ps,
                date: startOfDay(new Date(ps.date)),
                endTime: addHours(
                  startOfDay(new Date(ps.date)),
                  parseInt(ps.end, 10)
                ),
                startTime: addHours(
                  startOfDay(new Date(ps.date)),
                  parseInt(ps.start, 10)
                ),
              })),
            },
            appointmentResponse: v?.[1],
            appointmentCheckFlag: flag,
            isEdit,
          });
          this.message$.next(message);
          /*
           *When editing/saving an appointment (even new ones),
           * if they are outside the schedule of the doctor,
           * you just have to say a warning that the appointment is
           * *outside the regular schedule. If there is a conflict,
           * we still do not allow the user to add the appointment.
           * For Holidays, do not allow appointment to be added.
           */
          return of({
            flag,
            message,
            isEdit,
          });
        }
      )
    );
  }
  isDateInRange(
    startTime: Date,
    endTime: Date,
    compareStartTime: Date,
    compareEndTime: Date
  ) {
    return startTime <= compareStartTime && endTime >= compareEndTime;
  }
  doesDataContainConflict(data: any[], startTime: Date, endTime: Date) {
    if (data.length < 1) {
      return false;
    }
    const filterConflict = data.filter((a: Appointment) =>
      areIntervalsOverlapping(
        {
          start: new Date(a.startTime),
          end: new Date(a.endTime),
        },
        {
          start: new Date(startTime),
          end: new Date(endTime),
        }
      )
    );
    return filterConflict.length > 0 ? true : false;
  }
  appointmentContainConflict(
    data: Appointment[],
    startTime: Date,
    endTime: Date,
    editAppointmentUID = ''
  ) {
    if (data.length < 1) {
      return false;
    }
    let appointments = data
      .map((q: any) => ({
        ...q,
        startTime: new Date(q.startTime),
        endTime: new Date(q.endTime),
      }))
      .filter((q: any) =>
        isEqual(startOfDay(q.startTime), startOfDay(startTime))
      )
      // TODO: filter deleted appointment
      .filter((a: Appointment) => a?.isDeletedAppointment === false);
    // Todo: check if appointment Id exist then filter it out from appointment [done]
    if (!checkIfEmpty(editAppointmentUID)) {
      appointments = appointments.filter(
        (a: Appointment) => a?.uid !== editAppointmentUID
      );
    }
    let appConflict = false;
    const filterConflict = appointments.filter((a: Appointment) =>
      areIntervalsOverlapping(
        {
          start: new Date(a.startTime),
          end: new Date(a.endTime),
        },
        {
          start: new Date(startTime),
          end: new Date(endTime),
        }
      )
    );
    appConflict = filterConflict.length > 0 ? true : false;
    // TODO: filter if the single appointment is note appointment and it allows overlap
    if (filterConflict.length === 1) {
      appConflict = filterConflict[0]?.allowOverlap === true ? false : true;
    }
    return appConflict;
  }
  getPhysicianScheduleAndAppointment(
    startDate: string,
    endDate: string,
    physicianUID: string,
    locationUID: string,
    showDeletedAppointments: boolean = false
  ): Observable<any[]> {
    // set date for later use
    this.saveDataForUse({
      startTime: startDate,
      endTime: endDate,
      physicianUID,
      duration: differenceInMinutes(new Date(endDate), new Date(startDate)),
      locationUID,
    });
    return forkJoin([
      this.reqService.post(physicians.getPhysiciansScheduleAndFreeDays, {
        startDate: formatISO(startOfDay(startOfWeek(new Date(startDate)))),
        endDate: formatISO(endOfDay(endOfWeek(new Date(endDate)))),
        physicianUID,
        locationUID,
      }),
      this.reqService.post(appointmentEndpoints.getAppointment, {
        startDate: formatISO(startOfDay(startOfWeek(new Date(startDate)))),
        endDate: formatISO(endOfDay(endOfWeek(new Date(endDate)))),
        physicianUID,
        showDeletedAppointments,
      }),
    ]);
  }
}
