import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent,
} from 'angular-calendar';
import { formatRFC3339, addMinutes, formatISO } from 'date-fns';
import { Subject, Subscription } from 'rxjs';
import { unsubscriberHelper } from 'src/app/core/helpers/unsubscriber.helper';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { CustomDateFormatter } from '../../calendar/custom-date-formatter.provider';
import { CabinetNotifyComponent } from '../cabinet-notify/cabinet-notify.component';
import { ViewChild } from '@angular/core';
import { DatePickerModalComponent } from '../date-picker-modal/date-picker-modal.component';
import { ProgrammareService } from 'src/app/core/services/programmare/programmare.service';
import { BooleanValueAccessor, ModalController } from '@ionic/angular';
import { LoadingControllerService } from '@app/core/services/loadingController/loading-controller.service';
import { AppointmentChecksFlag } from '@app/core/models/appointmentChecksFlag.interface';
import { checkIfEmpty } from '@app/core/helpers/checkIfEmpty.helper';
import {
  Appointment,
  PhyFreeDay,
} from '@app/core/models/appointment.interface';
import { WeekViewHourSegment } from 'calendar-utils';

const colors: any = {
  bizPrimary: {
    primary: '#ffffff',
    secondary: '#1EA5C7',
  },
};

@Component({
  selector: 'app-cabinet',
  templateUrl: './cabinet.component.html',
  styleUrls: ['./cabinet.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CabinetComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('closeBtn', { static: false }) closeB: ElementRef;
  @Input() cabinetData: any;
  @Input() cabinetName: string;
  @Input() appointments: Appointment[];
  @Input() schedules: any[];
  @Input() viewDate: Date;
  @Input() cabinetUID: string;
  @Input() isPhysicianScheldule: boolean;
  @Input() phyFreeDays: PhyFreeDay[];
  @Input() isRecurentaScheldule: boolean;
  @Input() excludeDaysRange: number[]; // number[] = [0, 6]; => monday to friday
  @Input() isCabinet = true;
  view: CalendarView = CalendarView.Month;

  page = 'zile-lucratoare';
  refresh: Subject<any> = new Subject();
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  bgColor = [
    'green-bg-color-step-20',
    'orange-bg-color-step-20',
    'green-bg-color-step-20',
    'orange-bg-color-step-10',
  ];
  events: CalendarEvent[] = [];
  getAppointments$: Subscription;
  // excludeDays: number[] = [0, 6];
  get excludeDays(): number[] {
    return this.excludeDaysRange &&
      this.excludeDaysRange.length < 3
      ? this.excludeDaysRange
      : [0, 6];
  }

  get weekDate() {
    return this.viewDate || new Date();
  }
  get monthOfDate() {
    return (
      new Intl.DateTimeFormat('ro', { month: 'long' }).format(this.viewDate) ||
      new Intl.DateTimeFormat('ro', { month: 'long' }).format(new Date())
    );
  }
  startEndTime: any = {};
  constructor(
    private modalController: ModalController,
    private programmareS$: ProgrammareService,
    private toastService: ToastService,
    private loadingContollerS: LoadingControllerService
  ) {
    this.startEndTime = JSON.parse(localStorage.getItem('workHours'));
  }

  ngAfterViewInit() {}
  ngOnInit() {
    if (this.appointments && this.appointments.length > 0) {
      const eventFromAppointement = this.appointments.map((v: any) => ({
        ...v,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
        draggable: false,
        start: new Date(v.startTime),
        end: new Date(v.endTime),
        title: 'Rez.',
        color: colors.bizPrimary,
        actions: this.actions,
      }));
      this.events.push(...eventFromAppointement);
      this.refresh.next();
    }
  }
  handleEvent(action: string, event: CalendarEvent): void {}
  hourSegmentClicked(event: any) {
    this.presentCabinentNotify(event.date);
  }
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }
  closeModal(
    isCainetSelectedSucessfully: boolean = false,
    dateData: any = null
  ) {
    this.modalController.dismiss(
      {
        dismissed: true,
        d: 'none',
        isAbletoSelectCainet: isCainetSelectedSucessfully,
        dateData,
      },
      undefined,
      'cabinet-modal'
    );
  }
  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    if (this.schedules && this.schedules.length > 0) {
      const mapSchedules = this.schedules.map((v: any) => ({
        ...v,
        abbrvName: `${v.physicianFirstName.split('')[0]}.${
          v.physicianLastName.split('')[0]
        }`,
      }));
      const abbrvNameColor = [
        {
          abbrvName: 'IP',
          color: this.bgColor[0],
        },
      ];
      renderEvent.hourColumns.forEach((hourColumn) => {
        mapSchedules.forEach((schedule: any, index: number) => {
          hourColumn.hours.forEach((hour, hourIndex) => {
            hour.segments.forEach(
              (segment: WeekViewHourSegment, idx: number): void => {
                if (
                  segment.date >= schedule.startTime &&
                  segment.date < schedule.endTime
                ) {
                  if (this.isPhysicianScheldule || this.isRecurentaScheldule) {
                    if (
                      schedule &&
                      schedule.hasOwnProperty('isBreakTime') &&
                      schedule.hasOwnProperty('isPrivate')
                    ) {
                      if (!schedule?.isBreakTime && !schedule?.isPrivate) {
                        segment.cssClass = `schedule-normal no-border`;
                      }
                    }
                  } else {
                    let color =
                      abbrvNameColor.length > 0
                        ? abbrvNameColor.find(
                            (v: any) => v.abbrvName === schedule.abbrvName
                          )?.color || ''
                        : '';
                    if (checkIfEmpty(color)) {
                      color = this.bgColor[index % this.bgColor.length];
                      abbrvNameColor.push({
                        abbrvName: schedule.abbrvName,
                        color: this.bgColor[index % this.bgColor.length],
                      });
                    }
                    /* segment.cssClass = `${
                    this.bgColor[index % this.bgColor.length]
                  } ${schedule.abbrvName}`; */
                    if (segment?.isStart) {
                      segment.cssClass = `${color} ${schedule.abbrvName}`;
                    } else {
                      if (checkIfEmpty(hour.segments[0].cssClass)) {
                        segment.cssClass = `${color} ${schedule.abbrvName}`;
                      } else {
                        segment.cssClass = `${color}`;
                      }
                    }
                  }
                }
              }
            );
          });
        });
        mapSchedules.forEach((schedule: any, index: number) => {
          hourColumn.hours.forEach((hour) => {
            hour.segments.forEach((segment) => {
              if (
                segment.date >= schedule.startTime &&
                segment.date < schedule.endTime
              ) {
                if (schedule?.isBreakTime) {
                  segment.cssClass = `${'isBreakTime-bg-color'} ${
                    schedule.abbrvName
                  }`;
                }
                if (!schedule?.isBreakTime && schedule?.isPrivate) {
                  // schedule-private-bg-color
                  segment.cssClass = `schedule-private no-border`;
                }
              }
            });
          });
        });

        if (this.phyFreeDays && this.phyFreeDays.length > 0) {
          this.phyFreeDays
            .map((v: any) => ({
              ...v,
              abbrvName: 'Holiday',
              startTime: new Date(v.startDate),
              endTime: new Date(v.endDate),
            }))
            .forEach((v: any) => {
              hourColumn.hours.forEach((hour) => {
                hour.segments.forEach((segment) => {
                  if (segment.date >= v.startTime && segment.date < v.endTime) {
                    segment.cssClass = `holidays sg-day2`;
                  }
                });
              });
            });
        }
      });
    }
  }
  get showSegmentSplitName() {
    return this.isPhysicianScheldule || this.isRecurentaScheldule || false;
  }
  async presentCabinentNotify(date: Date = new Date()) {
    const modal = await this.modalController.create({
      component: CabinetNotifyComponent,
      cssClass: ' modal-wrapper-with-236px-height',
      backdropDismiss: false,
      componentProps: {
        notifyType: 'typeB',
        isCabinet: this.isCabinet,
        date,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const { dismissed, selecteaza, renita, dateData } = data;
      if (dismissed && !renita && !selecteaza) {
        this.presentDatePicker(data);
      }
      if (dismissed && selecteaza) {
        if (this.isPhysicianSchelduleSet || this.isRecurentaScheldule) {
          await this.loadingContollerS.presentLoadingWithNoDuration(
            'Vă rugăm să așteptați...',
            'getMedicalEquipment'
          );
          this.programmareS$
            .appointmentsChecks(
              formatISO(dateData),
              formatISO(
                new Date(
                  addMinutes(
                    new Date(dateData),
                    this.programmareS$.duration$.value
                  )
                )
              ),
              this.programmareS$.physicianUID$.value,
              this.programmareS$.locationUID$.value
            )
            .subscribe(
              (res: { flag: AppointmentChecksFlag; message: string }) => {
                this.loadingContollerS.dismiss();
                if (
                  res?.flag?.isphysicianFreeDays ||
                  res?.flag?.appointmentInConflict
                ) {
                  this.toastService.presentToastWithDurationDismiss(
                    res?.message
                  );
                } else {
                  if ( this.isPhysicianSchelduleSet ) {
                    this.programmareS$.updateProgrammareDateData(
                      dateData,
                      this.cabinetUID,
                      res?.flag?.isOutOfPhysicianSchedule ||
                        res?.flag?.isPhysicianBreakTimeSchedule
                        ? res?.message
                        : ''
                    );
                  }
                  this.closeModal(true, dateData);
                }
              },
              (_err: any) => {
                this.loadingContollerS.dismiss();
                this.toastService.presentToastWithDurationDismiss(
                  'A apărut o eroare. Va rugăm să reîncercați.'
                );
              }
            );
        } else {
          const check = this.programmareS$.runCheckCabinetAvailabilityProcess(
            dateData,
            new Date(
              addMinutes(new Date(dateData), this.programmareS$.duration$.value)
            )
          );
          if (check) {
            this.programmareS$.updateProgrammareDateData(
              dateData,
              this.cabinetUID
            );
            this.closeModal(true);
          } else {
            this.toastService.presentToastWithDurationDismiss(
              'Nu puteți rezerva cabinetul în acest interval.'
            );
          }
        }
      }
    }
  }
  async presentDatePicker({ isHoutMinutesPicker, dateData: date }) {
    const modal = await this.modalController.create({
      component: DatePickerModalComponent,
      cssClass: 'biz-modal-class-type-no-background width-md-100',
      backdropDismiss: false,
      componentProps: {
        pickerType: isHoutMinutesPicker ? 'hourMinutes' : 'dayMonth',
        date: formatRFC3339(date, { fractionDigits: 3 }),
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    const { dismissed, dateData } = data;
    if (dismissed) {
      this.presentCabinentNotify(new Date(dateData || date));
    }
  }
  get isPhysicianSchelduleSet() {
    return this.isPhysicianScheldule || false;
  }
  get appointmentPhyFreeDays() {
    return this.phyFreeDays || [];
  }
  ngOnDestroy() {
    unsubscriberHelper(this.getAppointments$);
  }
}
